'use strict';

/**
 * Services that persists and retrieves TODOs from localStorage.
 */

angular.module('tdl.services.storage').factory('ExportsManager', ['$resource', 'Conf', 'TDLAuth',
  function ($resource, Conf, TDLAuth) {
    return $resource(Conf.api + '/exportsManager/:exportId', {
      exportId: '@_id'
    }, {
      get: {
        method: 'GET',
        params: {
          exportId: ''
        },
        isArray: false,
        headers: TDLAuth.header(),
        cache: false
      },
      post: {
        method: 'POST',
        headers: TDLAuth.header()
      },
      update: {
        method: 'PUT',
        headers: TDLAuth.header()
      },
      query: {
        method: 'GET',
        isArray: true,
        headers: TDLAuth.header()
      },
      remove: {
        method: 'DELETE',
        headers: TDLAuth.header()
      }
    });
  }]);
