'use strict';

angular.module('tdl.directives').directive('tdlFormDataHandler', ['$rootScope', 'Form', 'FormData','FormPublic', 'FormDataPublic', 'FormDataHelper', 'TDLAuth', 'Team', 'Athlete', 'User', 'AlertsManager', '$q', 'FormMerged', 'Menu','StorageHelper','Conf','$http',
  function ($rootScope, Form, FormData, FormPublic, FormDataPublic, FormDataHelper, TDLAuth, Team, Athlete, User, AlertsManager, $q, FormMerged, Menu,StorageHelper,Conf,$http) {
    var throttleTime = 400;

    var directiveDefinitionObject = {
      restrict: 'E', // only activate on element attribute
      scope: {
        formDataId: '=',
        formId: '=',
        teamId: '=',
        inCalendar: '=?', // If true it doesn't show some input (date, time, team, athlete)
        inPopup: '=?', // If true it doesn't show button for submit, delete because they are in the popup
        hideBottomPanel: '=?',
        live: '=?',
        public: '=?',
        readOnlyMode: '=?',
        defaultValues: '=?', //Values to add to a newForm
        onCreate: '&',
        onUpdate: '&',
        onUpdateReload: '&',
        onDelete: '&',
        onDeleteReload: '&',
        onClose: '&',
        print: '&',
        control: '=?' // Object that allows to control this directive from the outside
      },
      link: link,
      templateUrl: 'views/formDataHandler.html'
    }

    return directiveDefinitionObject;

    function link(scope, elem, attrs, formController) {
      var throttledSubmitFormData = _.throttle(submitFormData(!scope.live), throttleTime, {
        leading: false
      });

      scope.control = scope.control || {};
      scope.control.deleteFormData = deleteFormData;
      scope.control.deleteAllRecursiveFormData = deleteAllRecursiveFormData;
      scope.control.deleteFromNowRecursiveFormData = deleteFromNowRecursiveFormData;
      scope.setToAllInFeature = setToAllInFeature
      scope.control.submitFormData = submitFormData(false);

      scope.live = scope.live || false;
      scope.inCalendar = scope.inCalendar || false;
      scope.inPopup = scope.inPopup || false;
      scope.defaultValues = scope.defaultValues || {};
      scope.readOnlyMode = scope.readOnlyMode || false;

      scope.TDLconf = $rootScope.TDLconf;

      scope.newForm = {
        id: null
      };

      scope.ui = {
        isReady: false
      };

      scope.debug = false;

      scope.formData = FormDataHelper.getDefault();
      if(scope.teamId) {
        scope.formData.teamId = scope.teamId;
      }
      scope.teams = [];
      scope.athletes = [];
      scope.attendees = [];
      scope.actualAttendees = [];

      scope.toggleDebug = toggleDebug;

      scope.form = null;

      scope.availableFormActions = []

      scope.userCreator = null;

      scope.submitFormData = submitFormData(false);
      scope.updateForm = updateForm;

      scope.executeMoveAction = executeMoveAction;
      scope.executeAction = executeAction;

      scope.invitationNotConfirmed = invitationNotConfirmed
      scope.invitationConfirmed = invitationConfirmed
      scope.acceptInvitation = acceptInvitation
      scope.discardInvitation = discardInvitation

      scope.openMenu = function($mdMenu, ev) {
        $mdMenu.open(ev);
      };

      scope.readOnly = {};

      scope.temp = {
        endDate: moment()
      }

      scope.lastModification = {
        user: "",
        date: ""
      }

      scope.menu = Menu;

      scope.time = {
        start: null,
        end:null
      };





      scope.timeChanged = function() {
        scope.uiForm.$valid = true;
        if(scope.time.start) {
          const currentDate = moment(scope.formData.start);
          scope.formData.start = moment.utc([currentDate.year(),currentDate.month(),currentDate.date(),scope.time.start.getHours(),scope.time.start.getMinutes()])
        }
        if(scope.time.end) {
          const currentDate = moment(scope.formData.end);
          scope.formData.end = moment.utc([currentDate.year(),currentDate.month(),currentDate.date(),scope.time.end.getHours(),scope.time.end.getMinutes()])
        }
        if(scope.formData.start.isBefore && !scope.formData.start.isBefore(scope.formData.end)) {
          scope.uiForm.$valid = false;
        }



        scope.updateForm(scope.uiForm)
      }

      function loadAthletes() {

        if(!scope.public) {
          if (Menu.teamScope()) {
            scope.showAthlete = true;
            if (Menu.multipleMode) {
              scope.athletes = _.flatten(_.map(Menu.selectedTeams, function (team) {
                return team.athletes
              }));
            } else {
              scope.athletes = Menu.team.athletes;
            }
          } else {
            if (Menu.multipleMode) {
              scope.athletes = Menu.selectedAthletes;
              scope.showAthlete = true;
            } else {
              scope.athletes = [Menu.athlete];
              scope.showAthlete = false;
              scope.formData.athleteId = Menu.athlete._id;
            }
          }
        }
      }

      scope.$watch('[menu.team, menu.selectedTeams, menu.athlete, menu.selectedAthletes, menu.athleteOnly]',loadAthletes,true);

      onStart().then(function () {
        scope.ui.isReady = true;
      });



      function onStart() {
        var formId = scope.formId;

        scope.newForm.id = angular.copy(scope.formId);

        prepareWatchers();

        var formFetch = Form.get;
        if(scope.public) {
          formFetch = FormPublic.get;
        }
        return formFetch({
          formId: formId
        }).$promise.then(
          function (form) {

            if(form.actions) {
              scope.availableFormActions = form.actions.filter( fa => scope.readOnlyMode ? fa.permissions.readOnlyUsers : fa.permissions.readWriteUsers)
            }

              scope.form = form;

            if (scope.formDataId) {
              return switchToEditMode();
            } else {

              fulfillFormData(scope.formData, scope.form);

              return $q.when(scope.formData);
            }

          },
          function (error) {
            console.log(error)
          }
        );
      }

      function submitFormData(onlyCheck) {
        return function () {
          if (scope.uiForm.$valid) {


            if (scope.formDataId) {
              return updateFormData(onlyCheck);
            } else {
              return createFormData(scope.formData, onlyCheck);
            }

          }

        }
      };

      scope.updateEndDate = function(form) {
        scope.formData.end = moment(scope.temp.endDate);
        if (scope.form.properties.datespan && !scope.form.properties.timespan) { //all day event
          scope.formData.end = moment(scope.temp.endDate).add(1, 'days');
        }
        updateForm(form)
      }

      function updateForm(form) {

        scope.uiForm = form;

        throttledSubmitFormData();
      }

      function switchToEditMode() {
        return FormData.get({
          formDataId: scope.formDataId
        }).$promise.then(
          function (fd) {
            scope.temp.endDate = fd.end ? fd.end.clone() : null;
            if (scope.form.properties.datespan && !scope.form.properties.timespan) { //all day event
              scope.temp.endDate = fd.end.clone().subtract(1, 'days');
            }
            scope.formData = fulfillFormData(fd, scope.form);

            checkLastChange(fd.changes);

            if (fd.readOnly || scope.readOnlyMode) {
              prepareReadOnlyMode(fd);
            }

            return scope.formData;

          },
          function (e) {
            console.log(e);
          }
        );
      }

      function toggleDebug() {
        scope.debug = !scope.debug;
      }

      function fulfillFormData(formData, form) {



        formData.formId = formData.formId || form._id;
        formData.data = _.extend(FormDataHelper.fromFormFieldsToData(form.fields), formData.data);

        if(formData.start) {
          scope.time.start = new Date(0,0,0,formData.start.hours(),formData.start.minutes())
        }
        if(formData.end) {
          scope.time.end = new Date(0,0,0,formData.end.hours(),formData.end.minutes())
        }


        if(scope.public) {
          formData.userId = "guest";
        } else {
          formData.userId = formData.userId || TDLAuth.userId;
        }

        if (scope.form.properties.team) {
          fetchRightTeam().then(function (teamId) {
            if (scope.form.properties.withAttendees && !scope.formData.athleteId) {
              scope.$watch('formData.teamId', function (newVal, oldVal) {
                if (newVal !== oldVal) {
                  scope.formData.attendees = [];
                  fetchRightTeam().then(function (teamId) {
                    prepareAttendeesForTeam(teamId);
                  });
                }
              });
              prepareAttendeesForTeam(teamId);
            }
          });
        }

        if (scope.form.properties.date || scope.form.properties.datespan || scope.form.properties.timespan) {
          // If none specified, take default values from directive
          formData.start = formData.start || scope.defaultValues.start;
          formData.end = formData.end || scope.defaultValues.end;

          // If it's still null add default values
          if (!formData.start) {
            // Set up only date (full day)
            if (form.properties.datespan || form.properties.date && !form.properties.timespan) {
              let now = moment()
              formData.start = moment.utc([now.year(),now.month(),now.date(),0,0,0,0]);
              // Set up both date and time (1 hours)
            } else if (form.properties.timespan) {
              let now = moment()
              formData.start = moment.utc([now.year(),now.month(),now.date(),now.hours(),0,0,0]);
            }
          }

          // If it's still null add default values
          if (!formData.end) {
            // Set up only date (full day)
            if (form.properties.datespan || form.properties.date && !form.properties.timespan) {
              let now = moment()
              formData.end = moment.utc([now.year(),now.month(),now.date(),0,0,0,0]).add(1, 'days');
              // Set up both date and time (1 hours)
            } else if (form.properties.timespan) {
              let now = moment()
              formData.end = moment.utc([now.year(),now.month(),now.date(),0,0,0,0]).add(1, 'hours');
            }
          }
        }

        if (!formData.permissions) {
          formData.permissions = form.defaultPermissions;

          if (!scope.public) {
            if (Menu.team?._id) {
              scope.formData.permissions['read']['teams'].push(Menu.team._id);
            }

            scope.formData.permissions['read']['users'].push(TDLAuth.user._id);
            scope.formData.permissions['write']['users'].push(TDLAuth.user._id);
            scope.formData.permissions['own']['users'].push(TDLAuth.user._id);
          }
        }

        return formData;

      }



      function updateFormData(onlyCheck) {

        // Update the form
        if (scope.formId != scope.newForm.id) {
          scope.formData.formId = scope.newForm.id
        }

        return FormData.update({
            formDataId: scope.formDataId,
            onlyCheck: onlyCheck ? onlyCheck : null
          }, scope.formData,
          function (fd) {
            //scope.formData = fd;

            checkLastChange(fd.changes);

            if (!scope.live && !onlyCheck) {
              AlertsManager.addAlert({
                type: 'success',
                message: 'The form is updated correctly.'
              });
            }

            if (onlyCheck) {
              scope.formData.alerts = fd.alerts;
            } else {
              //scope.formData = fd;
              checkLastChange(fd.changes);

              scope.onUpdate({
                'formData': fd
              });
            }
          },
          function () {
            AlertsManager.addAlert({
              type: 'danger',
              message: 'There an issue with submission.'
            });
          }
        );
      }

      function createFormData(dataToSend, onlyCheck) {
        var formDataSender = FormData.post
        if(scope.public) {
          if(!onlyCheck) {
            formDataSender = FormDataPublic.post
          } else {
            formDataSender = function() { return $q.when(null); }
          }
        }

        return formDataSender({
            onlyCheck: onlyCheck ? onlyCheck : null
          }, dataToSend,
          function (fd) {

            if (onlyCheck) {
              scope.formData.alerts = fd.alerts;
            } else {
              scope.formDataId = fd._id;
              angular.merge(scope.formData,fd) ;
              checkLastChange(fd.changes);

              scope.onCreate({
                'formData': fd
              });
            }

            if (!scope.live && !onlyCheck) {
              AlertsManager.addAlert({
                type: 'success',
                message: 'The form is created correctly.'
              });
            }
          },
          function () {
            AlertsManager.addAlert({
              type: 'danger',
              message: 'There an issue with submission.'
            });
          }
        );
      }

      function deleteFormData() {
        var result = confirm('Are you sure you want to delete it?');

        if (result === true) {
          FormData.remove({
            formDataId: scope.formDataId
          }, {}, function () {
            scope.onDelete({
              formDataId: scope.formDataId
            });
          });

          scope.onDelete({
            formData: scope.formData
          });
        }
      }

      function deleteAllRecursiveFormData() {
        if(confirm('Are you sure you want all the recurring events?')) {
          $http.delete(Conf.api + '/formData/recurring/' + scope.formData.recurringId, {
            headers: TDLAuth.header()
          }).then(function (data, status, headers, config) {
            scope.onDeleteReload({
              formData: scope.formData
            });
          }, function (err) {
            console.log(err);
          });

          scope.onDelete({
            formDataId: scope.formDataId
          });
        }
      }

      function deleteFromNowRecursiveFormData() {
        if(confirm('Are you sure you want all the recurring events from now?')) {

          $http.delete(Conf.api + '/formData/from/' + scope.formData._id + '/recurring/' + scope.formData.recurringId, {
            headers: TDLAuth.header()
          }).then(function (data, status, headers, config) {
            scope.onDeleteReload({
              formData: scope.formData
            });
          }, function (err) {
            console.log(err);
          });

          scope.onDelete({
            formDataId: scope.formDataId
          });
        }
      }

      function setToAllInFeature() {

        // Update the form
        if (scope.formId != scope.newForm.id) {
          scope.formData.formId = scope.newForm.id
        }

        const eventObject = angular.copy(scope.formData);



        if (eventObject.start !== undefined) {
          eventObject.start = eventObject.start.valueOf() //StorageHelper.dateToMillis(eventObject.start);
        }

        if (eventObject.end !== undefined) {
          eventObject.end = eventObject.end.valueOf() //StorageHelper.dateToMillis(eventObject.end);
        };


        $http.put(Conf.api + '/formData/recursive/' + scope.formData._id , eventObject,{
          headers: TDLAuth.header()
        }).then(function (data, status, headers, config) {
          AlertsManager.addAlert({
            type: 'success',
            message: 'The forms has been updated correctly.'
          });
        }, function (err) {
          console.log(err);
          AlertsManager.addAlert({
            type: 'danger',
            message: 'There an issue with submission.'
          });
        });


      }

      function fetchRightTeam() {
        if(!scope.public) {
          if (Menu.multipleMode && Menu.teamScope()) {
            return Team.query().$promise.then(
              function (teams) {

                scope.teams = _.filter(teams, function (team) {
                  return _.contains(Menu.selectedTeams, team._id);
                });

                if (scope.formData.teamId == null && Menu.selectedTeams.length > 0) {
                  scope.formData.teamId = Menu.selectedTeams[0];
                }

                return scope.formData.teamId;

              }
            );
          } else {
            if (scope.formData.teamId == null && Menu.team) {
              scope.teams = [Menu.team];
              scope.formData.teamId = Menu.team._id;
              return $q.when(scope.formData.teamId);
            } else {
              return $q.when(null)
            }
          }
        } else {
          return $q.when(null);
        }
      }



      function prepareAttendeesForTeam(teamId) {
        var teamAttendees = fetchAthletesFromTeam(teamId)
        scope.formData.attendees = scope.formData.attendees || _.pluck(teamAttendees, '_id');
        scope.attendees = teamAttendees;
        scope.actualAttendees = _.filter(scope.attendees,function(att) {

          return scope.formData.attendees.indexOf(att._id) >= 0;
        });

        function fetchAthletesFromTeam(teamId) {
          var teamFound = _.findWhere(scope.teams, {
            _id: teamId
          });

          addAthletesToTeam(teamFound);

          return teamFound ? teamFound.athletes : [];
        }

        function addAthletesToTeam(team) {
          if (!team.athletes) {
            team.athletes = _.filter(Menu.athletes, function (ath) {
              return _.contains(team.athletesIds, ath._id)
            });
          }
        }
      }

      function prepareReadOnlyMode(fd) {

        scope.readOnlyMode = true;


        // Form.fields({form: fd.formId}).$promise.then(function(fields) {
        //   console.log("fields")
        //   console.log(fields);
        //    scope.fields = _.filter(fields,function(f) {
        //      return !['athleteId','athleteFull','teamId','teamFull','userId','userFull','formFull',"submittedFormatted",'team','title','form','user','submitted'].includes(f.id) &&
        //             !['Id','form','title','color'].includes(f.typ)
        //    })
        //   console.log(scope.fields)
        // });

      }


      function fetchUser(userId) {
        if(scope.public) {
          return $.when({_id:"guest", username:"guest", realName:"Guest"});
        } else if (userId === TDLAuth.user._id) {
          return $q.when(TDLAuth.user);
        } else {
          return User.get({
            userId: userId
          }).$promise;
        }
      }

      function prepareWatchers() {
        scope.$watch('formData.userId', function (newValue, oldValue) {
          if (newValue && newValue !== oldValue) {
            fetchUser(scope.formData.userId).then(function (user) {
              if (!user.realName) user.realName = user.name;
              scope.userCreator = user;
            });
          }
        });
      }

      function checkLastChange(changes) {
        if (changes.length > 0) {
          var change = changes[changes.length - 1];
          scope.lastModification.date = moment(change.date).format('MMMM Do YYYY, h:mm:ss a');

          fetchUser(change.userId).then(function (user) {
            if (!user.realName) user.realName = user.name;
            scope.lastModification.user = user.realName;
          });
        }
      }

      function executeAction(action) {
        $http.get(Conf.api + '/form/action/'+action.actionId+ "/" + scope.formData._id, {
          headers: TDLAuth.header()
        }).then(function (data, status, headers, config) {
        }, function (err) {
          console.log(err);
        });
      }

      function executeMoveAction(action) {


        var fd = JSON.parse(JSON.stringify(scope.formData)); //deep clone hack
        fd.start = moment(fd.start) //fix start/end
        fd.end = moment(fd.end) //fix start/end
        var fdMerged =  angular.merge(fd,action.mask);

        var restAction = null;
        if(action.copy) {
          delete fdMerged._id;
          restAction = FormData.post
        } else {
          restAction = FormData.update
        }

        restAction({
            formDataId: fdMerged._id,
            onlyCheck: false
          }, fdMerged,
          function (fd) {

            AlertsManager.addAlert({
              type: 'success',
              message: 'The action "'+action.name+'" has been executed correctly.'
            });

            if(scope.onClose) {
              scope.onClose();
            }

            scope.onUpdateReload({
              'formData': fd
            });

          },
          function () {
            AlertsManager.addAlert({
              type: 'danger',
              message: 'There an issue with submission.'
            });
          }
        );

      }

      function _currentAthlete() {
        return Menu.userAthlete(TDLAuth.user)
      }

      const atl = _currentAthlete()

      function _isUserInvited() {
        return atl && scope.formData.invited && scope.formData.invited.includes(atl._id)
      }

      function _isUserAttending() {
        return atl && scope.formData.attendees && scope.formData.attendees.includes(atl._id)
      }

      function invitationNotConfirmed() {
        return _isUserInvited() && !_isUserAttending()
      }
      function invitationConfirmed() {
        return _isUserInvited() && _isUserAttending()
      }
      function acceptInvitation() {

        $http.get(Conf.api + '/invitation/accept/' + scope.formData._id, {
          headers: TDLAuth.header()
        }).then(function (data, status, headers, config) {
          scope.onClose()
        }, function (err) {
          console.log(err);
        });

      }
      function discardInvitation() {

        $http.get(Conf.api + '/invitation/discard/' + scope.formData._id, {
          headers: TDLAuth.header()
        }).then(function (data, status, headers, config) {
          scope.onClose()
        }, function (err) {
          console.log(err);
        });
      }

    }
  }
]);
