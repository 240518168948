/*global define*/
'use strict';

import $ from "jquery";
import draggable from "webpack-jquery-ui"
import "@andxor/jquery-ui-touch-punch-fix"

/**
 * Directive that places focus on the element it is applied to when the expression it binds to evaluates to true.
 */

angular.module('tdl.directives').directive('tdlDraggable',['$timeout', function ($timeout) {
  return {
    restrict: 'A',
    link: function (scope, elm, attrs) {
      // make the event draggable using jQuery UI
      $(elm).draggable({
        scroll: false, // prevents sideways scrolling in sidebar

        revert: true, // will cause the event to go back to its
        revertDuration: 0, // original position after the drag

        // Clone draggable and style it, so the element being dragged is always on top
        helper: function (event) {
          return $(event.target).clone().css({
            width: $(event.target).outerWidth(),
            boxShadow: '0 0 0 4px rgba(0,0,0,.2)'
          });
        },
        appendTo: 'body',
        opacity: .9

      });
    }
  };
}]);
