'use strict';
angular.module('tdl.controllers').controller('NoticesController', ['$scope','Menu','AlertsManager','Notice','TDLAuth',
  function NoticesController($scope,Menu,AlertsManager,Notice, TDLAuth) {
    TDLAuth.checkAdmin();
    Menu.section = 'backend';
    $scope.menu = Menu;

    $scope.notices = Notice.query();
    $scope.notice = {};

    $scope.setCurrent = function(notice) {
      $scope.notice = Notice.get({noticeId:notice._id});
    };

    $scope.newNotice = function() {
      $scope.notice = {};
    };

    $scope.saveNotice = function() {
      if ($scope.notice._id) {
        var obj = angular.copy($scope.notice);

        Notice.update({}, obj,
          // success
          function (result) {
            AlertsManager.addAlert({
              type: 'success',
              message: 'Notice has been changed successfully!'
            });
            $scope.notice = result;
            $scope.notices = Notice.query();
          },
          function (err) {
            console.log(err);
          });
      } else {
        Notice.post({}, $scope.notice,
          // success
          function (result) {
            AlertsManager.addAlert({
              type: 'success',
              message: 'User has been added successfully!'
            });
            $scope.notice = result;
            $scope.notices = Notice.query();
          },
          function (err) {
            console.log(err);
          });
      }
    }

    $scope.deleteNotice = function() {
      var r = confirm("Delete notice?");
      if(r) {
        Notice.remove({
          noticeId: $scope.notice._id
        }, function () {
          AlertsManager.addAlert({
            type: 'success',
            message: 'Notice removed!'
          });
          $scope.notice = {};
          $scope.notices = Notice.query();
        });
      }
    }




  }
]);
