'use strict';

angular.module('tdl.directives').directive('tdlFormPermissions', ['$rootScope','Menu',
  function ($rootScope,Menu) {

    var directiveDefinitionObject = {
      restrict: 'E', // only activate on element attribute
      scope: {
        formData: "=",
        closePopup: '&'
      },
      link: link,
      templateUrl: 'views/directives/tdlFormPermissions.html'
    }

    return directiveDefinitionObject;

    function link(scope, elem, attrs) {



    }
  }
]);
