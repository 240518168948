'use strict';

angular.module('tdl.controllers').controller('ContactController', ['$scope', 'Feedback',
  function ContactController($scope, Feedback) {

    $scope.mail = {
      obj: '',
      body: ''
    };

    $scope.sent = false;

    $scope.submit = function () {
      Feedback.post($scope.mail).$promise.then(
        function (v) {
          $scope.sent = true;
        },
        function (e) {
          console.log(e);
          $scope.sent = true;
        }
      )

    };

  }
]);