'use strict';

angular.module('tdl.directives').directive('tdlAnalisisCompare', ['FormAnalisis',
  function (FormAnalisis) {

    var directiveDefinitionObject = {
      restrict: 'E', // only activate on element attribute
      scope: {
        data: '='
      },
      link: link,
      templateUrl: 'views/analisis/compare.html'
    }

    return directiveDefinitionObject;

    function link(scope, elem, attrs, $compile) {

    }




  }
]);
