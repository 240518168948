'use strict';

/**
 * Services that persists and retrieves TODOs from localStorage.
 */

angular.module('tdl.services.storage').factory('Team', ['$resource', 'Conf', 'TDLAuth', 'StorageHelper',
 function ($resource, Conf, TDLAuth, StorageHelper) {
    var transRequestStack = StorageHelper.addTransRequest([StorageHelper.copyObjectTrans, StorageHelper.stripIdTrans]);

    return $resource(Conf.api + '/team/:teamId', {
      teamId: '@_id'
    }, {
      get: {
        method: 'GET',
        isArray: false,
        headers: TDLAuth.header()
      },
      query: {
        method: 'GET',
        isArray: true,
        headers: TDLAuth.header()
      },
      post: {
        method: 'POST',
        headers: TDLAuth.header(),
        transformRequest: transRequestStack
      },
      update: {
        method: 'PUT',
        headers: TDLAuth.header(),
        transformRequest: transRequestStack
      },
      remove: {
        method: 'DELETE',
        headers: TDLAuth.header()
      }
    });
}]);