/*global define*/
'use strict';

import Highcharts from 'highcharts'

angular.module('tdl.directives').directive('polarTab', ['Athlete','$rootScope','$http','TDLAuth','Conf',
  function (Athlete,$rootScope,$http,TDLAuth,Conf) {

    var directiveDefinitionObject = {
      restrict: 'E', // only activate on element attribute
      scope: {
        formId: '=',
        formDataId: '=',
        polar: '=',
        data: '=',
        athleteId: '='
      },
      link: link,
      templateUrl: 'views/addons/directives/polarTab.html',
    }

    return directiveDefinitionObject;

    function formatTime(x,withSeconds) {
      let time =  Math.floor(x / 60 / 60) + ":"
        + String( Math.floor(x / 60) % 60).padStart(2,'0')
      if(withSeconds) time += ":" + String( x % 60 % 60).padStart(2,'0')

      return time

    }

    function loadChart(polar,scope,athlete) {


      scope.polar = polar;

      function _conf() {

        if($rootScope.TDLconf.ui.addons && $rootScope.TDLconf.ui.addons.polar) {


          return $rootScope.TDLconf.ui.addons.polar.mapping.find(map => {
            //search in conf the addon with the same formId and, if exits the same tag
            return map.formId === scope.formId && (!map.formTagField || scope.data[map.formTagField].includes(map.formTag))
          })
        } else {
          return null
        }
      }
      const conf = _conf()

      function _speedUnit() {
        if(conf && conf.units && conf.units.speed) {
          return conf.units.speed
        } else {
          return "km/h"
        }
      }
      const speedUnit = _speedUnit()

      function convertSpeed(speed) {
        switch(speedUnit) {
          case "kn": return speed * 0.5399565
          case "min:sec/km": return 60 / speed
          case "min:sec/500m": return 30 / speed
          default: return speed
        }
      }



      function speed(v) {
        switch(speedUnit) {
          case "min:sec/km":
          case "min:sec/500m": return Math.floor(v) + ":" + Math.round( (v - Math.floor(v)) * 60 )
          default: return Math.round(v * 100) / 100
        }
      }

      function speedLabel() {
        switch(speedUnit) {
          case "min:sec/km": return "/ km"
          case "min:sec/500m": return "/ 500m"
          default: return speedUnit
        }
      }


      const serie = scope.polar.details //.sort((a,b) => a.timestamp - b.timestamp)

      if(serie.find(x => x.lon > 0 && x.lat > 0)) {
        scope.gps = serie.map((sample,i) => {
          let lon = sample.lon
          let lat = sample.lat
          if(!lon) {
            if(i > serie.length / 2) {
              var j = i
              while(!lon) {
                j--
                if(serie[j % serie.length].lon) lon = serie[j % serie.length].lon
              }
            } else {
              var j = i
              while(!lon) {
                j++
                if(serie[j % serie.length].lon) lon = serie[j % serie.length].lon
              }
            }
          }
          if(!lat) {
            if(i > serie.length / 2) {
              var j = i
              while(!lat) {
                j--
                if(serie[j % serie.length].lat) lat = serie[j % serie.length].lat
              }
            } else {
              var j = i
              while(!lat) {
                j++
                if(serie[j % serie.length].lat) lat = serie[j % serie.length].lat
              }
            }
          }
          return [lon, lat]
        }).filter(x => typeof x[0] === 'number')
      }

      let start = 0

      serie.forEach(s => {
        if(start == 0 || start > s.timestamp) {
          start = s.timestamp
        }
      })

      const hrSerie = serie.map(s => [Math.round((s.timestamp - start)/1000),s.hr])
      const speedSerie = serie.map(s => [Math.round((s.timestamp - start)/1000),convertSpeed(s.speed)])
      const powerSerie = serie.map(s => [Math.round((s.timestamp - start)/1000),s.power || s.powerPedaling || s.powerLR])


      const sport = (athlete.addon && athlete.addon.sports) ? athlete.addon.sports.find(x =>
        scope.polar.summary.sport.toLowerCase().includes(x.sport) ||
        (scope.data.tags ? scope.data.tags.includes(x.sport) : false) ||
        (scope.formId === "529631594000006001a8cbf3report" && x.sport === "sailing")

      ) : null;


      let bands = [];
      const innerColors = ['#c2caca80','#46c7ee80','#6acc2b80','#f9bf1c80','#de0f5b80']
      if(sport) {
        bands = sport.zones.map((z,i) => {
          return { // Light air
            from: z,
            to: sport.zones[i+1] ? sport.zones[i+1] : 200,
            color: innerColors[i],
            label: {
              text: 'Zone ' + (i+1),
              style: {
                color: '#606060'
              }
            }
          }
        })
      }

      const hrChart = {
        title: "HR",
        credits: {
          href: "http://teamdatalog.com",
          text: "teamdatalog.com"
        },
        legend: {
          enabled: false
        },
        xAxis: {
          crosshair: true,
          title: {
            enabled: true,
            text: 'Duration'
          },
          type: 'linear',
          labels: {
            formatter: function() {
              return formatTime(this.value)
            }
          }

        },
        yAxis: {
          title: {
            text: 'HR (bpm)'
          },
          minorGridLineWidth: 0,
          gridLineWidth: 0,
          alternateGridColor: null,
          plotBands: bands,
          min: 30,
          max: 200
        },
        tooltip: {
          formatter: function() {
            return '<b>HR: '+this.y+'</b><br>'  + 'Time: '+ formatTime(this.x,true);
          }
        },
        series: [{
          type: "line",
          lineWidth: 1,
          color: "red",
          data: hrSerie
        }]
      }

      const speedChart = {
        title: "Speed",
        credits: {
          href: "http://teamdatalog.com",
          text: "teamdatalog.com"
        },
        legend: {
          enabled: false
        },
        xAxis: {
          crosshair: true,
          title: {
            enabled: true,
            text: 'Duration'
          },
          type: 'linear',
          labels: {
            formatter: function() {
              return formatTime(this.value)
            }
          }

        },
        yAxis: {
          labels: {
            formatter: function () {
              return speed(this.value)
            }
          },
          title: {
            text: 'Speed [' + speedUnit + ']'
          }
        },
        tooltip: {
          formatter: function() {
            return '<b>Speed: '+speed(this.y)+' ' + speedLabel() + '</b><br>'  + 'Time: '+ formatTime(this.x,true);
          }
        },
        series: [{
          data: speedSerie
        }]
      }


      const powerChart = {
        title: "Power",
        credits: {
          href: "http://teamdatalog.com",
          text: "teamdatalog.com"
        },
        legend: {
          enabled: false
        },
        xAxis: {
          crosshair: true,
          title: {
            enabled: true,
            text: 'Duration'
          },
          type: 'linear',
          labels: {
            formatter: function() {
              return formatTime(this.value)
            }
          }

        },
        tooltip: {
          formatter: function() {
            return '<b>Power: '+(Math.round(this.y * 100) / 100)+' m/s</b><br>'  + 'Time: '+ formatTime(this.x,true);
          }
        },
        series: [{
          data: powerSerie
        }]
      }

      scope.charts = [hrChart]

      if(speedSerie.find(x => x[1] > 0)) {
        scope.charts.push(speedChart)
      }
      if(powerSerie.find(x => x[1] > 0)) {
        scope.charts.push(powerChart)
      }

    }

    function link(scope, elem, attrs, $compile) {

      scope.tss = null


      scope.gpx = function() {
        
        var headers = angular.copy(TDLAuth.header())

        $http({
          url: Conf.api + '/formData/polarGPX/' + scope.formDataId,
          headers: headers,
          responseType: 'arraybuffer'
        }).then(function (data, status, headers, config) {
          var file = new Blob([ data.data ], {
              type : 'application/gpx+xml'
          });
          //trick to download store a file having its URL
          var fileURL = URL.createObjectURL(file);
          var a         = document.createElement('a');
          a.href        = fileURL;
          a.target      = '_blank';
          a.download    = scope.formDataId + '.gpx';
          document.body.appendChild(a);
          a.click();
        },function (data, status, headers, config) {
          //upload failed
        });
  
      }

      function loadTss(athlete,data) {
        if(athlete.addon) {
          const ias = athlete.addon.ias;
          const hr = data.summary['heart-rate'].average;
          const t = Math.round((data.details[data.details.length-1].timestamp - data.details[0].timestamp) / 1000);
          scope.tss = Math.round(((t*hr*hr)/(ias*ias*3600.0))*100.0*100)/100
        }
      }

      let graphSetPoint = null
      scope.graphSetPoint = function(callback) {
        graphSetPoint = callback
      }

      let mapSetPoint = null
      scope.mapSetPoint = function(callback) {
        mapSetPoint = callback
      }

      scope.changedPoint = function(idx) {
        if(graphSetPoint) graphSetPoint(idx)
        if(mapSetPoint) mapSetPoint(idx)
      }


      Athlete.get({
        athleteId: scope.athleteId
      }).$promise.then(
        //success
        function (value) {
          $http.get(Conf.api + '/formData/polar/'+scope.formDataId, {
            headers: TDLAuth.header()
          }).then(function (response, status, headers, config) {
            response.data.details = response.data.details.filter(x => !isNaN(x.timestamp)) //.sort((a,b) => a.timestamp - b.timestamp)
            loadChart(response.data,scope,value)
            loadTss(value,response.data)
          }, function (err) {
            console.log(err);
          });
        },
        //error
        function (error) {
          console.log(error);
        }
      )



    }

  }]
);
