'use strict';

import {Remarkable} from "remarkable";
import Handsontable from "handsontable";
import Highcharts from "highcharts";

angular.module('tdl.controllers').controller('FormReportController', ['$scope', '$rootScope', '$compile', 'Form','FormData', 'User', '$routeParams', '$location', 'TDLAuth', 'Conf', 'slugifyFilter', '$q', '$timeout', 'Menu',
  function FormReportController($scope, $rootScope, $compile,Form, FormData, User, $routeParams, $location, TDLAuth, Conf, slugifyFilter, $q, $timeout, Menu) {
    Menu.section = "form";
    $scope.menu = Menu;

    var formIds;
    $scope.data = [];
    $scope.settings = [];
    $scope.name = name;


    $scope.newForm = newForm;

    onStart();

    function onStart() {
      formIds = $routeParams['formIds'].split(':');

      // If there is no team, the loadReport will be catch by the following watcher
      if (Menu.team) {
        loadReport();
      }

      $scope.$watch('[menu.team, menu.selectedTeams, menu.athlete, menu.selectedAthletes]', function () {
        if (Menu.team) {
          loadReport();
        }
      }, true);
    }


    function tdlRenderer(metadata) {

      switch (metadata.typ) {
        case 'DateTime': return function(instance, td, row, col, prop, value, cellProperties) {
          Handsontable.renderers.TextRenderer.apply(this, [instance, td, row, col, prop, moment(value).format('MMMM Do YYYY, h:mm:ss a'), cellProperties]);
        };
        case 'Date': return function(instance, td, row, col, prop, value, cellProperties) {
          Handsontable.renderers.TextRenderer.apply(this, [instance, td, row, col, prop, moment(value).format('MMMM Do YYYY'), cellProperties]);
        };
        case 'Time Span': return function(instance, td, row, col, prop, value, cellProperties) {
          Handsontable.renderers.TextRenderer.apply(this, [instance, td, row, col, prop, moment.utc(value).format('HH:mm:ss'), cellProperties]);
        };

        case 'Long Text': return function(instance, td, row, col, prop, value, cellProperties) {
          var el = document.createElement("div");

          var md = new Remarkable();

          if(value && (value.startsWith('<p>') || value.startsWith('<ul>'))) {
            el.innerHTML = value;
          } else {
            el.innerHTML = md.render(value)
          }

          Handsontable.dom.addEvent(el, 'mousedown', function (e){
            e.preventDefault(); // prevent selection quirk
          });
          Handsontable.dom.empty(td);
          td.appendChild(el);
        };
        case 'Image': return function(instance, td, row, col, prop, value, cellProperties) {
          var el = document.createElement("div");
          el.style = "width: 250px";
          var img = document.createElement("img");
          img.style = "width: 250px";
          img.src = Conf.api + '/media/' + value;
          el.appendChild(img);
          Handsontable.dom.addEvent(el, 'mousedown', function (e){
            e.preventDefault(); // prevent selection quirk
          });
          Handsontable.dom.empty(td);
          td.appendChild(el);
        };
        case 'Tags': return function(instance, td, row, col, prop, value, cellProperties) {
          var el = document.createElement("div");

          _.each(value ? value.split(",") : [], function(tag) {
            if(tag && tag.trim().length > 0) {
              var tagEl = document.createElement('span');
              tagEl.className = "tdl-field label label-info ng-binding";
              tagEl.innerText = tag;
              el.appendChild(tagEl);
            }
          });

          Handsontable.dom.addEvent(el, 'mousedown', function (e){
            e.preventDefault(); // prevent selection quirk
          });
          Handsontable.dom.empty(td);
          td.appendChild(el);
        };
        case 'Evaluation table': return function(instance, td, row, col, prop, value, cellProperties) {
          var el = document.createElement("div");
          if(!value) {
            el.innerHTML = "No Data";
          } else {

            function parseValue(x) {
              let v = 0
              try{
                const parsed = parseInt(x)
                if(x > 0) v = parsed
              } catch (e) {}
              return v
            }
            el.innerHTML = "Avg: " +  value.avg;
            var chart = document.createElement("div");
            chart.style = "width: 250px";


            const data = _.map(metadata.options, function (o) {
              return {y: parseValue(value.valuesById[o.id]), name: o.name};
            })


            Highcharts.chart(chart, {

              chart: {
                polar: true
              },

              title: {
                text: null
              },
              credits: {enabled: false},
              exporting: {enabled: false},
              legend: false,

              pane: {
                startAngle: 0,
                endAngle: 360
              },

              xAxis: {
                tickInterval: 1,
                type: "category",
                categories: _.map(metadata.options, function (v) {
                  return v.name
                })
              },

              yAxis: {
                min: 0,
                max: $rootScope.TDLconf.ui.form.evaluationMax
              },

              plotOptions: {
                series: {
                  pointStart: 0,
                  pointInterval: 1
                },
                column: {
                  pointPadding: 0,
                  groupPadding: 0
                }
              },

              series: [{
                type: 'area',
                name: '',
                data: data
              }]
            });
            el.appendChild(chart);
          }

          Handsontable.dom.addEvent(el, 'mousedown', function (e){
            e.preventDefault(); // prevent selection quirk
          });
          Handsontable.dom.empty(td);
          td.appendChild(el);
        };
        default:return function(instance, td, row, col, prop, value, cellProperties) {
          if(value && value.value){
            Handsontable.renderers.TextRenderer.apply(this, [instance, td, row, col, prop, value.value, cellProperties]);
          } else {
            Handsontable.renderers.TextRenderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
          }
        }
      }

    }

    function loadTable(allMetadata,hiddenColumns) {

      var metadata = _.filter(allMetadata, function(m) { return !_.find(hiddenColumns,function(hc) { return m.id == hc.id})});

      $scope.settings.length = 0;


      var contextItems = {
        "hide": {
          name: "Hide columns"
        }
      }
      if(hiddenColumns.length > 0)
        contextItems["hsep1"] = "---------";
      _.each(hiddenColumns, function (hc) {
        contextItems["show-"+hc.id] = {
          name: "Show " + hc.name
        }
      });

      var shownColumns = metadata.filter(x => !['userFull','formFull','submittedFormatted','teamFull','athleteFull','title','days'].includes(x.id));
      $scope.settings.push({
        colHeaders: _.map(shownColumns, function(m) {return m.name}),
        stretchH: 'all',
        height: window.innerHeight - 200,
        // contextMenu: {
        //     callback: function (key, options) {
        //       console.log(key)
        //       if(key === "hide") {
        //         var hc = hiddenColumns;
        //         for (var i = options.start.col; i <= options.end.col; i++) {
        //           hc.push(metadata[i - 1]);
        //         }
        //         loadTable(allMetadata, hc);
        //         $scope.$apply();
        //       } else if(key.startsWith("show-")) {
        //         var id = key.replace("show-","");
        //         loadTable(allMetadata,_.filter(hiddenColumns, function(hc) {
        //           return hc.id !== id;
        //         }));
        //         $scope.$apply();
        //       }
        //
        //   },
        //   items: contextItems
        // },
        manualColumnResize: true,
        columnSorting: true,
        sortIndicator: true,
        columns: _.map(shownColumns, function(m) {
          return {
            data: m.id,
            readOnly: true,
            renderer: tdlRenderer(m)
          }
        })
      });
    }

    function loadReport() {

      $q.all([Form.fields({form:formIds[0]}).$promise,FormData.query(getQuery(formIds[0])).$promise,Form.get({formId: formIds[0]}).$promise]).then(
        // Build master form
        function (result) {

            var metadata = _.filter(result[0],function(m) { return m.typ != "Free text" && m.typ != "Section" && m.id != "form" && m.typ != "Id"});
            $scope.data = result[1];


            loadTable(metadata,[]);



          $scope.name = result[2].name;




        },
        function (error) {
          console.log(error)
        }
      );
    }



    function newForm() {
      $location.path("/form/" + formIds[0]);
    }



    function getQuery(formId) {

      if (Menu.teamScope()) {
        var teamIds;

        if (Menu.multipleMode) {
          teamIds = Menu.selectedTeams.toString();
        } else {
          teamIds = Menu.team._id;
        }

        return {
          teamId: teamIds,
          formsId: formId
        }
      } else if (Menu.athleteScope()) {
        var athletesIds;
        if (Menu.multipleMode) {
          athletesIds = Menu.selectedAthletes.toString();
        } else {
          athletesIds = Menu.athlete._id;
        }

        return {
          athleteId: athletesIds,
          formsId: formId
        }
      }
    }





  }
]);
