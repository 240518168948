'use strict';

angular.module('tdl.controllers').controller('SignupController', ['$scope','$rootScope', '$http', 'Conf', 'AlertsManager', 'Menu','$routeParams',
  function SignupController($scope,$rootScope, $http, Conf, AlertsManager, Menu,$routeParams) {

    Menu.unload();

    $scope.signup = true;
    $scope.signupMessage = false;


    $scope.saveUser = function () {
      if($rootScope.TDLconf.ui.enableSignup) {
        var user = $scope.user;


        if ($routeParams['teamIds']) {
          user.teams = $routeParams['teamIds'].split(',');
        } else {
          user.teams = []
        }


        $http({
          url: Conf.api + '/signup',
          method: "POST",
          data: JSON.stringify(user)
        }).then(function (data, status, headers, config) {
          $scope.signupMessage = true;
        }, function (data, status, headers, config) {
          AlertsManager.addAlert({
            type: 'danger',
            message: 'Nome utente già esistente'
          });
        });
      } else {
        AlertsManager.addAlert({
          type: 'danger',
          message: 'Signup is not enabled in this system'
        });
      }

    }

  }
 ]);
