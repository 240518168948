'use strict';
import _ from "underscore";
import $ from "jquery";


angular.module('tdl.controllers').controller('MenuController', ['$scope', '$location', 'Conf', 'TDLAuth', 'Team', 'Athlete', 'Menu',
function MenuTeamController($scope, $location, Conf, TDLAuth, Team, Athlete, Menu) {

    $scope.logout = logout;
    $scope.menuLabel = menuLabel;
    $scope.menu = Menu;
    $scope.user = TDLAuth.user;

    $scope.selectTeam = selectTeam;
    $scope.selectAthlete = selectAthlete;

    //$scope.visibleAthletes = [];
    //$scope.visibleTeams = [];
    $scope.filterAtlTeams = '';

    onStart();




    function onStart() {
      $scope.$watch('menu.teamsOrAthletes', function () {

        if (Menu.athleteScope()) {
          Menu.athlete = null;
          Menu.selectedAthletes.length = 0;
          $scope.menu.visibleAthletes = _.filter(Menu.athletes, function (a) {
            return (a.firstName + " " + a.lastName).toLowerCase().indexOf($scope.filterAtlTeams.toLowerCase()) > -1;
          })

        } else {
          Menu.team = null;
          Menu.selectedTeams.length = 0;
          $scope.menu.visibleTeams = _.filter(Menu.teams, function (t) {
            return t.name.toLowerCase().indexOf($scope.filterAtlTeams.toLowerCase()) > -1;
          })
        }
      }, true);

      $scope.$watch("filterAtlTeams", function () {
        $scope.menu.visibleTeams = _.filter(Menu.teams, function (t) {
          return t.name.toLowerCase().indexOf($scope.filterAtlTeams.toLowerCase()) > -1;
        })

        $scope.menu.visibleAthletes = _.filter(Menu.athletes, function (a) {
          return (a.firstName + " " + a.lastName).toLowerCase().indexOf($scope.filterAtlTeams.toLowerCase()) > -1;
        })

      }, true);

    }

    function menuLabel() {
      var label = '';
      if (Menu.teamScope() && Menu.team) {
        label = Menu.team.name;
      } else if (Menu.athlete) {
        label = Menu.athlete.firstName + " " + Menu.athlete.lastName;
      }

      return label;
    }

    function logout() {
      TDLAuth.logout();
      $location.path("/");
    }

    function selectTeam(id) {
      if (!Menu.multipleMode) {
        $('.nav-dropdown-menu').slideUp('fast');
      }

      Menu.selectTeam(id);
    }

    function selectAthlete(id) {
      if (!Menu.multipleMode) {
        $('.nav-dropdown-menu').slideUp('fast');
      }

      Menu.selectAthlete(id);
    }

}
]);
