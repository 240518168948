'use strict';

/**
 * Services for model User
 */

angular.module('tdl.services.storage').factory('User', ['$resource', 'Conf', 'TDLAuth',
  function ($resource, Conf, TDLAuth) {
    return $resource(Conf.api + '/user/:userId', {
      userId: '@_id'
    }, {
      get: {
        method: 'GET',
        isArray: false,
        headers: TDLAuth.header()
      },
      query: {
        method: 'GET',
        isArray: true,
        headers: TDLAuth.header()
      },
      list: {
        method: 'GET',
        isArray: true,
        headers: TDLAuth.header(),
        url: Conf.api + '/users/info'
      }
    });
  }
]);

angular.module('tdl.services.storage').factory('UserProfile', ['$resource', 'Conf', 'TDLAuth', 'StorageHelper',
  function ($resource, Conf, TDLAuth, StorageHelper) {
    var transRequestStack = StorageHelper.addTransRequest([StorageHelper.copyObjectTrans, StorageHelper.stripIdTrans]);

    return $resource(Conf.api + '/user/profile/:userId', {
      userId: '@_id'
    }, {
      get: {
        method: 'GET',
        isArray: false,
        headers: TDLAuth.header()
      },
      update: {
        method: 'PUT',
        isArray: false,
        headers: TDLAuth.header(),
        transformRequest: transRequestStack
      }
    });
  }
]);

angular.module('tdl.services.storage').factory('UserAdmin', ['$resource', 'Conf', 'TDLAuth', 'StorageHelper',
  function ($resource, Conf, TDLAuth, StorageHelper) {
    var transRequestStack = StorageHelper.addTransRequest(StorageHelper.stripIdTrans);

    return $resource(Conf.api + '/admin/user/:userId', {
      userId: '@_id'
    }, {
      get: {
        method: 'GET',
        isArray: false,
        headers: TDLAuth.header()
      },
      post: {
        method: 'POST',
        isArray: false,
        headers: TDLAuth.header(),
        transformRequest: transRequestStack
      },
      update: {
        method: 'PUT',
        headers: TDLAuth.header(),
        transformRequest: transRequestStack
      },
      remove: {
        method: 'DELETE',
        headers: TDLAuth.header()
      }
    });
  }
]);