angular.module('tdl.services.helpers').factory('FormHelper', function () {
  return {
    getDefaultForm: getDefaultForm,
    getDefaultField: getDefaultField,
    getDefaultOption: getDefaultOption,
    getDefaultShowIf: getDefaultShowIf,
    getFieldTypes: getFieldTypes,
    hasRequiredFields: hasRequiredFields
  };

  function getDefaultForm() {
    return {
      name: 'New Form',
      description: '',
      calculatedFields: [],
      fields: [
          getDefaultField()
        ],
      userEnabled: [],
      teamEnabled: [],
      allEnabled: false,
      aliases: [],
      properties: {
        athlete: false,
        team: false,
        date: false,
        timespan: false,
        datespan: false,
        showInForm: false,
        showInCalendar: false,
        showInReport: false,
        withAttendees: false,
        overlaps: {
          warningGlobal: false,
          warning: false,
          forceNoOverlap: false
        },
        showOnlyBusy: false,
        labels: [],
        public: false,
        moves: {
          copyToTeam:false,
          moveToTeam:false,
          changeToForm:[], // lista di form id con il quale si vuole abilitare il cambio di tipo form
          copyToForm:[], // lista di form id con il quale si vuole abilitare il cambio di tipo form
          actions:[]
        }
      },
      defaultPermissions: _.reduce(['read', 'write', 'own'], function (result, item) {
        result[item] = {
          teams: [],
          users: [],
          superuser: false,
          relateduser: false,
          teammates: false,
          all: false
        }

        return result;
      }, {})
    };
  }

  function getDefaultField() {
    return {
      id: '',
      name: '',
      typ: 'Text',
      options: [],
      cols: 8,
      warningOverlap: false,
      required: false
    };
  }

  function getDefaultOption() {
    return {
      'name': '',
      'id': Math.random().toString(36).substr(2, 9)
    };
  }

  function getDefaultShowIf() {
    return {
      'fieldId': '',
      'fieldValue': ''
    };
  }

  function getFieldTypes() {
    return [
      'Text',
      'Date',
      'Long Text',
      'Number',
      'Radio',
      'Checkbox',
      'Select',
      'Athlete',
      'Evaluation table',
      'Section',
      'Free text',
      'Time Span',
      'Tags',
      'Youtube Video',
      'Image URL',
      'Location',
      'Image',
      'File'
    ];
  }

  function hasRequiredFields(form) {

    var result = _.find(form.fields, function (field) {
      return field.required;
    });

    return result !== undefined;
  }
});
