'use strict';

angular.module('tdl.controllers').controller('FormDataPublicController', ['$scope', '$routeParams', '$location', 'Menu','TDLAuth','$http','Conf',
  function FormController($scope, $routeParams, $location, Menu,TDLAuth,$http,Conf) {
    Menu.section = "form";


    
    $scope.data = {
      formId: $routeParams['formId'],
      teamId: $routeParams['teamId'],
      readOnlyMode: false,
      public: true
    }

  }
]);
