'use strict';

/**
 * Services that persists and retrieves TODOs from localStorage.
 */

angular.module('tdl.services.storage').factory('FormAnalisis', ['$resource', 'Conf', 'TDLAuth',
 function ($resource, Conf, TDLAuth) {
    return $resource(Conf.api + '/formAnalisis/data/:formAnalisisId', {}, {
      get: {
        method: 'GET',
        params: {
          formAnalisisId: ''
        },
        isArray: false,
        headers: TDLAuth.header(),
        cache: false
      }
    });
 }]);


angular.module('tdl.services.storage').factory('FormAnalisisStructure', ['$resource', 'Conf', 'TDLAuth',
 function ($resource, Conf, TDLAuth) {
    return $resource(Conf.api + '/formAnalisis/:formAnalisisId', {
      formAnalisisId: '@_id'
    }, {
      get: {
        method: 'GET',
        isArray: false,
        headers: TDLAuth.header()
      },
      query: {
        method: 'GET',
        isArray: true,
        headers: TDLAuth.header()
      },
      post: {
        method: 'POST',
        headers: TDLAuth.header()
      },
      update: {
        method: 'PUT',
        headers: TDLAuth.header()
      },
      remove: {
        method: 'DELETE',
        headers: TDLAuth.header()
      }
    });
 }]);
