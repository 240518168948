'use strict';
angular.module('tdl.controllers').controller('UsersInviteController', ['$scope', '$rootScope', 'TDLAuth', 'User', 'UserAdmin', 'Team', 'AlertsManager', 'StaticDataLoader', 'Menu','$http','Conf','$window',
  function UsersInviteController($scope, $rootScope, TDLAuth, User, UserAdmin, Team, AlertsManager, StaticDataLoader, Menu,$http,Conf,$window) {

    TDLAuth.checkAdmin();

    Menu.section = 'backend';
    $scope.menu = Menu;

    $scope.teams = [];

    $scope.forms = [];

    $scope.inviteUsers = inviteUsers;


    $scope.currentUser = {
      teamOwner: [],
      teamsIds: [],
      readOnlyTeams: [],
      calendarForms: [],
      mobile: { visibleForms: []},
      role: { title: "user"},
      athlete:true
    };

    onStart();




    function onStart() {
      $scope.teams = Team.query();
      StaticDataLoader.simpleForm(function (forms) {
        $scope.forms = _.filter(forms, function (f) {
          return f.properties && (f.properties.showInCalendar || f.properties.showInReport);
        })

        $scope.allforms = forms;



      });
    }





    $scope.renderFormName = function(id) {
      var form = _.find($scope.forms,function(f) { return f._id === id})
      if(form) {
        return form.name;
      } else {
        return "";
      }
    };


    function inviteUsers() {
      var emails = _.filter(_.flatten(_.map($scope.emails.split(","),function(e) { return e.split("\n")})), function(e) { return e !== ""});
      _.each(emails,function(email) {
        var user = $.extend(true, {}, $scope.currentUser);
        user.email = email;
        inviteUser(user);
      })
    }

    function inviteUser(userInvite) {
      $http.post(Conf.api + '/admin/user/invite', userInvite, {
        headers: TDLAuth.header()
      }).
      then(function (data, status, headers, config) {
        AlertsManager.addAlert({
          type: 'success',
          message: 'User ' + userInvite.email + ' has been invited successfully!'
        });
      },function (err) {
        console.err(err);
      });
    }





  }


]);
