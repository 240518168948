'use strict';

import Highcharts from 'highcharts';
//https://gist.github.com/jon-a-nygaard/f22ade93209277eea5b57c0f6ca51ea7
import addMore from "highcharts/highcharts-more";
import addDrilldown from "highcharts/modules/drilldown";
import addExporting from 'highcharts/modules/exporting'
import addOfflineExporting from 'highcharts/modules/offline-exporting'
import addParallelCoordinates from 'highcharts/modules/parallel-coordinates'
import { isArray } from 'underscore';

addMore(Highcharts)
addDrilldown(Highcharts)
addExporting(Highcharts)
addOfflineExporting(Highcharts)
addParallelCoordinates(Highcharts)

angular.module('tdl.directives').directive('hcChart', [
  function () {

    return {
      restrict: 'E',
      template: '<div style="width: 100%"><div></div></div>',
      scope: {
        options: '=',
        chartWidth: '=?',
        onPointChange: '&',
        setPoint:'&'
      },
      link: function (scope, element) {

        function getOptions() {
          if(scope.options && isArray(scope.options))
            return scope.options.filter(x => x != undefined && x != null)
          else
            return []
        }

        const container = element[0].children[0]

        function singleChart() {
          const chart = Highcharts.chart(container, scope.options);
          setTimeout(() => {
            try {
              chart.reflow();
            } catch {}
          }, 10);
        }

        function highlightSelected(time) {
          for (let i = 0; i < Highcharts.charts.length; i = i + 1) {
            const chart = Highcharts.charts[i];

            if (chart && container.contains(chart.container)) {

              if (chart.series[0] && chart.series[0].data && chart.series[0].data.length > 0) {


                let timeDiff = 1000000000
                let searching = true
                let i = 0
                while (searching && i < chart.series[0].data.length) { 
                  let diff = Math.abs(time - chart.series[0].data[i].x)
                  if (diff < timeDiff) {
                    timeDiff = diff
                    i++
                  } else {
                    searching = false
                  }
                }

                const point = chart.series[0].data[i]


                if (point) {
                  point.select(true,false)
                  point.onMouseOver()
                  chart.tooltip.refresh(point)
                }
              }
            }
          }
        }

        let handlerLoaded = false;
        function loadMultiHandler() {
          if(!handlerLoaded) {
            handlerLoaded = true;
            ['mousemove', 'touchmove', 'touchstart'].forEach(function (eventType) {
              container.addEventListener(
                eventType,
                function (e) {
                  var chart,
                    time,
                    i;


                  for (i = 0; i < Highcharts.charts.length; i = i + 1) {
                    chart = Highcharts.charts[i];

                    if (chart && container.contains(chart.container)) {
                      
                      time = chart.axes[0].toValue(e.chartX)

                    }
                  }

                  if (time && time >= 0) {
                    scope.onPointChange({time: time})
                  }

                }
              );
            });

            /**
             * Override the reset function, we don't need to hide the tooltips and
             * crosshairs.
             */
            Highcharts.Pointer.prototype.reset = function () {
              return undefined;
            };

            /**
             * Highlight a point by showing tooltip, setting hover state and draw crosshair
             */
            Highcharts.Point.prototype.highlight = function (event) {
              event = this.series.chart.pointer.normalize(event);
              this.onMouseOver(); // Show the hover marker
              this.series.chart.tooltip.refresh(this); // Show the tooltip
              this.series.chart.xAxis[0].drawCrosshair(event, this); // Show the crosshair
            };
          }
        }

        function synchronizedChart() {

          //clear existing charts
          while (container.firstChild) { container.removeChild(container.firstChild); }


          loadMultiHandler()


          getOptions().forEach(opt => {
            

            container.style = "display: flex; flex-direction: row; justify-content: space-evenly; flex-wrap: wrap"

            var chartDiv = document.createElement('div');
            chartDiv.className = 'chart';

            const width = scope.chartWidth ? scope.chartWidth : "600px"

            chartDiv.style = "width: " + width
            container.appendChild(chartDiv);


            const chart = Highcharts.chart(chartDiv, opt);

            setTimeout(() => {
              try {
                chart.reflow();
              } catch {}
            }, 10);
          })


        }

        scope.$watch('options',function() {
          if(Array.isArray(scope.options)) {
            synchronizedChart()
          } else {
            singleChart()
          }

        },true);

        scope.setPoint({
          callback: function (time) {
            highlightSelected(time)
          }
        })


      }
    };
  }
]);
