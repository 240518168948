import Map from 'ol/Map';
import View from 'ol/View';
import {OSM, Vector as VectorSource} from 'ol/source';
import {Tile as TileLayer, Vector as VectorLayer} from 'ol/layer';
import {Circle as CircleStyle, Fill, Stroke, Style,RegularShape} from 'ol/style';
import LineString from 'ol/geom/LineString';
import Feature from 'ol/Feature';
import {Circle, Point} from "ol/geom";
import { circular } from 'ol/geom/Polygon';
import proj4 from 'proj4';



angular.module('tdl.directives').directive('tdlMap', [
  function () {

    return {
      restrict: 'E',
      template: '<div><div style="width: 100%; height:800px; display: block;\n' +
        '    position: relative;"></div></div>',
      scope: {
        line: '=',
        onPointChange: '&',
        setPoint: '&'
      },
      link: function (scope, element) {

        console.log("Loading map...")

        const container = element[0].children[0].children[0]

        let futureAdded = false

        function loadMap() {


          const view = new View({
            projection: 'EPSG:3857',
            center: scope.line[0],
            zoom: 12
          })

          const projection = view.getProjection();

          const line = new LineString(scope.line).transform('EPSG:4326',projection)

          const point = new Point([0,0])
          const pointFeature = new Feature({ geometry: point })
          // pointFeature.setStyle(new Style({
          //   image: new RegularShape({
          //     fill: new Fill({color: 'red'}),
          //     stroke: new Stroke({color: 'black', width: 2}),
          //     points: 4,
          //     radius: 10,
          //     angle: Math.PI / 4,
          //   }),
          // }))

          const source = new VectorSource();
          const vector = new VectorLayer({
            source: source,
          });

          const sourceCA = new VectorSource();
          const vectorCA = new VectorLayer({
            source: sourceCA,
          });

          //course areas
          sourceCA.addFeature(new Feature({ geometry: circular([5.330333,43.266833], 926,128).transform('EPSG:4326',projection) })); // Corniche
          sourceCA.addFeature(new Feature({ geometry: circular([5.354833,43.259500], 926,128).transform('EPSG:4326',projection) })); // marseille
          sourceCA.addFeature(new Feature({ geometry: circular([5.336500, 43.244500], 1111, 128).transform('EPSG:4326',projection) })); // Calanques
          sourceCA.addFeature(new Feature({ geometry: circular([5.307667,43.254667], 1111,128).transform('EPSG:4326',projection) })); // Frioul
          sourceCA.addFeature(new Feature({ geometry: circular([5.312833, 43.235167], 1111, 128).transform('EPSG:4326', projection) })); // Maire
          

          sourceCA.addFeature(new Feature({ geometry: circular([4.263333, 52.132833], 1389, 128).transform('EPSG:4326', projection) })); // The Hague
          sourceCA.addFeature(new Feature({ geometry: circular([4.298667, 52.157000], 1389, 128).transform('EPSG:4326', projection) })); // Pier North
          sourceCA.addFeature(new Feature({ geometry: circular([4.281850, 52.187750], 1389, 128).transform('EPSG:4326', projection) })); // Windmill
          sourceCA.addFeature(new Feature({ geometry: circular([4.248333, 52.164933], 1389, 128).transform('EPSG:4326', projection) })); // Tulip
          sourceCA.addFeature(new Feature({ geometry: circular([4.210667, 52.106667], 1389, 128).transform('EPSG:4326', projection) })); // Pier South
          sourceCA.addFeature(new Feature({ geometry: circular([4.168667, 52.092500], 1389, 128).transform('EPSG:4326', projection) })); // Cheese
          sourceCA.addFeature(new Feature({ geometry: circular([4.136033, 52.117167], 1389, 128).transform('EPSG:4326', projection) })); // Cloggy
          sourceCA.addFeature(new Feature({ geometry: circular([4.183250, 52.128033], 1389, 128).transform('EPSG:4326', projection) })); // Pancake

          source.addFeature(new Feature({ geometry: line }));

          



          const map = new Map({
            target: container,
            layers: [
              new TileLayer({
                source: new OSM()
              }),
              vectorCA,
              vector
            ],
            view: view
          });

          view.fit(line,{padding: [25, 25, 25, 25]})

          function pixelDistance(p1, p2) {
            let [x1, y1] = p1;
            let [x2, y2] = p2;
            return Math.sqrt(
              Math.pow(x2 - x1, 2) +
              Math.pow(y2 - y1, 2)
            );
          }



          const displayFeatureInfo = function (evt) {
            const pixel = map.getEventPixel(evt.originalEvent);
            const mouseCoordinates = map.getEventCoordinate(evt.originalEvent);

            const closestPoint = line.getClosestPoint(mouseCoordinates);

            const distance = pixelDistance(pixel,map.getPixelFromCoordinate(closestPoint))

            if(distance < 20) {
              const distances = line.getCoordinates().map(c => Math.abs(c[0] - closestPoint[0]) + Math.abs(c[1] - closestPoint[1]))
              const minDistance = Math.min(...distances)
              const idx = distances.indexOf(minDistance)
              scope.onPointChange({time: scope.line[idx][2]})

              map.getTarget().style.cursor = 'pointer';
            } else {
              if(futureAdded) {
                source.removeFeature(pointFeature)
                futureAdded = false;
              }
              map.getTarget().style.cursor = '';
            }
          };

          map.on('pointermove', function (evt) {
            if (evt.dragging) {
              return;
            }
            evt.scope = scope
            displayFeatureInfo(evt);
          });

          const startTime = scope.line[0][2]
          const endTime = scope.line[scope.line.length - 1][2]

          scope.setPoint({
            callback: function (time) {
              if (time && time > startTime && time < endTime) {
                let timeDiff = 1000000000
                let searching = true
                let i = 0
                while (searching && i < scope.line.length) { 
                  let diff = Math.abs(time - scope.line[i][2])
                  if (diff < timeDiff) {
                    timeDiff = diff
                    i++
                  } else {
                    searching = false
                  }
                }

                const selected = scope.line[i]
                if (selected && selected[0]) {
                  const coords = proj4("EPSG:4326", "EPSG:3857", [parseFloat(selected[0].toFixed(10)), parseFloat(selected[1].toFixed(10))])
                  point.setCoordinates(coords)
                }
              }
              if(!futureAdded) {
                source.addFeature(pointFeature)
                futureAdded = true;
              }
            }
          })


        }


        setTimeout(() => {
          loadMap()
        }, 10);



      }
    };
  }
]);
