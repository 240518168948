'use strict';
angular.module('tdl.controllers').controller('AthletesController', ['$scope', '$rootScope', 'Athlete', 'TDLAuth', 'User', '$http', 'AlertsManager', 'Menu','Team','Conf',
  function AthletesController($scope, $rootScope, Athlete, TDLAuth, User, $http, AlertsManager, Menu, Team,Conf) {

    TDLAuth.checkAdmin();

    Menu.section = 'backend';
    $scope.menu = Menu;

    $scope.users = [];
    $scope.athletes = [];
    $scope.teams = [];
    $scope.searchAthlete = '';

    $scope.user = {
      email: "",
      readOnlyTeams: [],
      teamsIds: []
    }

    $scope.newCurrentAthlete = newCurrentAthlete;
    $scope.setCurrentAthlete = setCurrentAthlete;

    $scope.saveAthlete = saveAthlete;
    $scope.deleteAthlete = deleteAthlete;

    $scope.addUser = addUser;



    onStart();




    function onStart() {
      $scope.teams = Team.query();
      $scope.users = User.query();
      $scope.athletes = Athlete.query();
      newCurrentAthlete()
    }

    function reloadAthleteList() {
      $scope.athletes = Athlete.query();
    }

    function addUser(email) {


      //default forms
      var calendarForms = $rootScope.TDLconf.ui.defaults.calendarForms;

      var mobileForms = $rootScope.TDLconf.ui.defaults.mobileForms;



    var user = {
      "athleteId": $scope.currentAthlete._id,
      "athlete": true,
      "email":email,
      "teamsIds":$scope.user.teamsIds,
      "readOnlyTeams": $scope.user.readOnlyTeams,
      "calendarForms":calendarForms,
      "teamOwner":[],
      "mobile": {
        "visibleForms": mobileForms
      },
      "role":{"title":"user"}
    }

      $http.post(Conf.api + '/admin/user/invite', user, {
        headers: TDLAuth.header()
      }).
      then(function (data, status, headers, config) {
        AlertsManager.addAlert({
          type: 'success',
          message: 'User has been invited successfully!'
        });



      },
      function (err) {
        console.log(err);
      });




    }

    function saveAthlete() {
      if ($scope.currentAthlete._id) {
        Athlete.update($scope.currentAthlete,
          // success
          function () {
            AlertsManager.addAlert({
              type: 'success',
              message: 'Athlete modified successfully!'
            });
            reloadAthleteList();
          },
          function (err) {
            console.log(err);
          });
      } else {
        Athlete.post($scope.currentAthlete,
          // success
          function () {
            AlertsManager.addAlert({
              type: 'success',
              message: 'Athlete added successfully!'
            });
            $scope.newCurrentAthlete();
            reloadAthleteList();
          },
          function (err) {
            console.log(err);
          });
      }
    }

    function setCurrentAthlete(athlete) {
      $scope.currentAthlete = angular.copy(athlete);
    }

    function deleteAthlete(athlete) {
      var r = confirm("Delete athlete?");
      if(r) {
        Athlete.remove({
          athleteId: athlete._id
        }, function () {
          AlertsManager.addAlert({
            type: 'success',
            message: 'Athlete Removed!'
          });
          reloadAthleteList();
          $scope.newCurrentAthlete();
        });
      }
    }

    function newCurrentAthlete() {
      $scope.currentAthlete = {
        firstName: '',
        lastName: '',
        federationId: ''
      };
    }

  }
]);
