'use strict';

angular.module('tdl.directives').directive('tdlEventHandler', ['AlertsManager', 'Menu',
  function (AlertsManager, Menu) {
    var throttleTime = 400;

    var directiveDefinitionObject = {
      restrict: 'E', // only activate on element attribute
      scope: {
        event: '=',
        onUpdate: '&',
        onDelete: '&',
        onClose: '&'
      },
      link: link,
      templateUrl: 'views/eventHandler.html'
    }

    return directiveDefinitionObject;

    function link(scope, elem, attrs) {
      scope.popupEvent = null;
      scope.popupData = {
        type: "event"
      };

	  scope.menu = Menu;

	  scope.getAthleteId = function(a) {
		return a._id;
	  }
		
      scope.event.model.get({
        eventId: scope.event._id
      }).$promise.then( //can't use $get here.. $watch get crazy...
        function (event) {


          scope.popupEvent = event;
        },
        function (error) {
          AlertsManager.addAlert({
            type: 'danger',
            message: error
          });
        }
      )

      scope.close = function () {
        scope.onClose()();
      }

      scope.delete = function () {
        var event = angular.copy(scope.popupEvent);
        scope.onDelete()(event);
      }

      scope.$watch('popupEvent', function (newNames, oldNames) {
        if (scope.popupEvent && scope.popupEvent.model && Menu.teamScope()) {
          var event = angular.copy(scope.popupEvent);
          scope.onUpdate()(event);

        }
      },true);

    }
  }
]);