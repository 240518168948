/*global define*/
'use strict';

angular.module('tdl.services').factory('ExportService', ['$http', 'TDLAuth', 'Conf','StorageHelper',
  function ($http, TDLAuth,Conf,StorageHelper) {
    var objectFactory = {
      load: load,
      initialRange: initialRange
    };


    return objectFactory;



    function load(id,dateRange,menu,analisisFilter) {



      var start = dateRange.startDate.valueOf()
      var end = dateRange.endDate.valueOf()

      var get = angular.extend({
        start: start,
        end: end
      },analisisFilter ? analisisFilter : {});


      if (menu.teamScope()) {
        get['resourceType'] = 'teamId'
        if (menu.multipleMode) {
          get['resourceId'] = menu.selectedTeams.join(',');
        } else {
          get['resourceId'] = menu.team._id;
        }
      } else {
        get['resourceType'] = 'athleteId'
        if (menu.multipleMode) {
          get['resourceId'] = menu.selectedAthletes.join(',');
        } else {
          get['resourceId'] = menu.athlete._id;
        }

      }

      var url = Conf.api + '/exports/'+id
      return $http.get(url,{
        headers: TDLAuth.header(),
        params: get
      })

    }

    function initialRange() {
      let start = moment({day: 1 }).subtract(6, 'months')
      let end = moment({day: 1 }).add(6, 'months')

      return {
        startDate: moment.utc([start.year(),start.month(),start.date(),0,0,0,0]),
        endDate: moment.utc([end.year(),end.month(),end.date(),0,0,0,0])
      }

    }



  }
]);
