'use strict';


import {Remarkable} from "remarkable";

angular.module('tdl.directives').directive('toastViewer', [
  function () {

    return {
      restrict: 'E',
      template: '<div class="remarkable-view"><div ng-bind-html="htmlText"></div></div>',
      scope: {
        ngModel: '='
      },
      link: function (scope, element) {

        var md = new Remarkable();

        if(scope.ngModel.startsWith('<')) {
          scope.htmlText = scope.ngModel;
        } else {
          scope.htmlText = md.render(scope.ngModel)
        }



      }
    };
  }
]);
