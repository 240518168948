/*global define*/
'use strict';

angular.module('tdl.services').factory('ModalManager', ['$uibModal', '$rootScope',
  function ($uibModal, $rootScope) {
    var objectFactory = {
      newFormData: newFormData,
      editFormData: editFormData,
      closeModal: closeModal,
      newRecurringEvent: newRecurringEvent
    };

    var openModal = null;

    return objectFactory;

    function closeModal() {
      if (openModal) {
        openModal.close();
      }
    }

    /**
     * Open a modal with a new formData.
     * @param  {String} formId    The formId
     * @param  {Object} popupData All properties to send to popup
     *                            callbacks: onCreate(formData), onUpdate(formData), onDelete(formDataId)
     * @return {Modal}  The modal just opened
     */
    function newFormData(formId, popupData) {
      var template = 'views/popupForm.html';

      var defaultPopupData = {
        formId: formId,
        type: "form",
        live: false,
        inCalendar: false,
        inPopup: true,
        readOnlyMode: false,
        defaultValues: {}
      };

      var childScope = $rootScope.$new();

      childScope.popupData = _.extend(defaultPopupData, popupData);

      childScope.modal = $uibModal.open({
        templateUrl: 'views/popupForm.html',
        scope: childScope,
        size: 'lg'
      })

      openModal = childScope.modal;

      childScope.modal.result.catch(function(res) { }); //avoid useless errors



      return childScope.modal;

    }

    /**
     * Open a modal in order to edit an existing formData
     * @param  {FormData} formData  The FormData to edit
     * @param  {Object} popupData   All properties to send to popup
     *                              callbacks: onCreate(formData), onUpdate(formData), onDelete(formDataId)
     * @return {Modal}    The modal just opened
     */
    function editFormData(formData, popupData) {
      var template = 'views/popupForm.html';

      var defaultPopupData = {
        formId: formData.formId,
        userId: formData.userId,
        type: "form",
        inCalendar: true,
        live: true,
        readOnlyMode: false,
        formDataId: formData._id,
        inPopup: true
      };

      var childScope = $rootScope.$new();


      childScope.callbacks = popupData
      childScope.popupData = _.extend(defaultPopupData, popupData);


      childScope.modal = $uibModal.open({
        templateUrl: 'views/popupForm.html',
        size: 'lg',
        scope: childScope,
        backdrop: true
      })

      openModal = childScope.modal;

      childScope.modal.result.catch(function(res) { }); //avoid useless errors

      return childScope.modal;

    }

    function newRecurringEvent(forms,onCreate) {
      var childScope = $rootScope.$new();

      childScope.forms = forms;
      childScope.callbacks = {
        onCreate: onCreate
      };


      childScope.modal = $uibModal.open({
        templateUrl: 'views/popupRecurring.html',
        size: 'lg',
        scope: childScope
      })

      openModal = childScope.modal;

      childScope.modal.result.catch(function(res) { }); //avoid useless errors



      return childScope.modal;
    }

  }
]);
