/*global define*/
'use strict';
import $ from "jquery";
import _ from "underscore";
import notify from "bootstrap-notify"

angular.module('tdl.services').constant('AlertsManager', function () {
  var defaultOptions = {
    element: 'body',
    type: "info",
    allow_dismiss: true,
    placement: {
      from: "top",
      align: "right"
    },
    offset: 20,
    spacing: 10,
    z_index: 2000,
    delay: 5000,
    timer: 1000,
    url_target: '_blank',
    mouse_over: false,
    animate: {
      enter: 'animated fadeInDown',
      exit: 'animated fadeOutUp'
    },
  };

  return {
    alerts: [],
    addAlert: addAlert,
    clearAlerts: clearAlerts,
    showError: showError
  };

  function addAlert(alert) {
    $.notify(alert, _.extend({}, defaultOptions, alert));
  }

  function clearAlerts() {
    $.notify(false, {
      command: 'closeAll'
    });
  }

  function showError(error) {
    addAlert({
      type: 'danger',
      message: error
    });
  }

}());
