'use strict';

angular.module('tdl.directives').directive('tdlAnalisis', ['FormAnalisis','StorageHelper','$httpParamSerializer','$http','Conf','TDLAuth',
  function (FormAnalisis,StorageHelper,$httpParamSerializer,$http,Conf,TDLAuth) {

    var directiveDefinitionObject = {
      restrict: 'E', // only activate on element attribute
      scope: {
        data: '=',
        type: '='
      },
      link: link,
      templateUrl: 'views/tdlAnalisis.html'
    }

    return directiveDefinitionObject;

    function link(scope, elem, attrs, $compile) {


      // scope.$watch('[export]', loadAnalisis, true);
      //
      //
      // function loadAnalisis() {
      //   if (scope.formAnalisis) {
      //
      //
      //     if (scope.formAnalisis.dataProcessing) {
      //       try {
      //         var dataProcessor = new Function("data", "log", scope.formAnalisis.dataProcessing);
      //         var dataProcessed = dataProcessor(scope.export, console.log);
      //         scope.data = dataProcessed;
      //       } catch (err) {
      //         console.log("Error processing" + err.message);
      //         scope.data = scope.export;
      //       }
      //     } else {
      //       scope.data = scope.export;
      //     }
      //
      //
      //   }
      // }
    }

  }]);
