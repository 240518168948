'use strict';

angular.module('tdl.directives').directive('tdlRadar', ['FormAnalisis',
  function (FormAnalisis) {

    var directiveDefinitionObject = {
      restrict: 'E', // only activate on element attribute
      scope: {
        data: '='
      },
      link: link,
      templateUrl: 'views/charts/radar.html'
    }

    return directiveDefinitionObject;

    function link(scope, elem, attrs, $compile) {

       scope.options = {
          // Chart.js options can go here.
          animation: false,
          showTooltips: true,
          pointDot : false,

        };
      
      var width = elem.context.parentElement.clientWidth;
        
        scope.radarSize = 100;
        
        if(width > 500) {
          scope.radarSize = 500;
        } else if(width > 400) {
          scope.radarSize = 400;
        } else if(width > 300) {
          scope.radarSize = 300;
        } else if(width > 200) {
          scope.radarSize = 200;
        }

    }
    
    
    
    
  }
]);