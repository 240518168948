/*global define*/
'use strict';

/**
 * Services that persists and retrieves TODOs from localStorage.
 */

angular.module('tdl.services').factory('LoginStorage', [
  function () {

    return {
      get: function(key) {
        return JSON.parse(sessionStorage.getItem(key))
      },
      set: function(key,obj) {
        return sessionStorage.setItem(key, JSON.stringify(obj));
      },
      remove: function (key) {
        return sessionStorage.removeItem(key);
      }
    };

  }
]);
