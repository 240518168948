'use strict';

angular.module('tdl.controllers').controller('SignupRequestsController', ['$scope', '$http', 'Conf', 'AlertsManager', 'TDLAuth', 'Menu',
  function SignupController($scope, $http, Conf, AlertsManager, TDLAuth, Menu) {

    TDLAuth.checkAdmin();

    Menu.unload();
    $scope.requests = [];

    $scope.delete = deleteFunction;
    $scope.activate = activate;

    onStart();


    function onStart() {
      $http({
        url: Conf.api + '/user/pending',
        method: "GET",
        headers: TDLAuth.header()
      }).then(function (data, status, headers, config) {
        $scope.requests = data
      },function (data, status, headers, config) {
      });
    }

    function deleteFunction(request) {
      $http({
        url: Conf.api + '/user/delete-signup-request/' + request.username,
        method: "GET",
        headers: TDLAuth.header()
      }).then(function (data, status, headers, config) {
        request.message = "deleted";
      },function (data, status, headers, config) {
        AlertsManager.addAlert({
          type: 'danger',
          message: data
        });
      });
    }

    function activate(request) {
      $http({
        url: Conf.api + '/user/confirm-signup/' + request.username,
        method: "GET",
        headers: TDLAuth.header()
      }).then(function (data, status, headers, config) {
        request.message = "activated";
      },function (data, status, headers, config) {
        AlertsManager.addAlert({
          type: 'danger',
          message: data
        });
      });

    };


  }
 ]);
