'use strict';

angular.module('tdl.controllers').controller('PresencesController', ['$scope', '$rootScope', 'Menu', 'Conf','TDLAuth', 'FormData','AlertsManager','CalendarHelper','StaticDataLoader',
  function PresencesController($scope, $rootScope, Menu, Conf, TDLAuth, FormData,AlertsManager,CalendarHelper,StaticDataLoader) {
    Menu.section = 'presences';

    $scope.menu = Menu;

    $scope.mode = 'events';


    var start = moment({day: 1 }).subtract(3, 'months');
    var end = moment({day: 1 }).add(9, 'months');


    $scope.myDateRange = {
      startDate:start,
      endDate: end
    }

    $scope.settings = null;


    $scope.formVisibles = {};
    $scope.forms = [];





    function onLoad() {

      var isTeamOwner = _.contains(TDLAuth.user.teamOwner,Menu.team._id);


      $scope.settings = null;
      var athletes = _.filter($scope.menu.athletes,function(a) { return $scope.menu.team.athletesIds.indexOf(a._id) >= 0});
      var athletesNames = _.map(athletes, function(atl) { return atl.firstName + " " + atl.lastName});
      var athletesColumns = _.map(athletes, function(atl) { return {data:atl._id, type:'checkbox', renderer:athleteRenderer, readOnly: !isTeamOwner}});


      var method = StaticDataLoader.form;
      if($scope.mode == "activities") {
        var method = StaticDataLoader.formReport;
      }

      method($scope.formVisibles, function (forms, innerForms) {
        $scope.forms = _.filter(innerForms,function(f) { return f.properties.withAttendees && ($scope.mode == 'activities' && !f.properties.datespan || $scope.mode == 'events')});
      });

      var query = CalendarHelper.getQuery($scope.myDateRange.startDate.valueOf(), $scope.myDateRange.endDate.valueOf())

      // if ($scope.mode == 'report') {
      //   query['reportCalendar'] = true;
      // } else {
      //   query['calendar'] = true;
      // }

      query['formsId'] = _.map($scope.forms, function (f) {
        return f._id;
      }).join(",");
      FormData.query(query).$promise.then(
        //success
        function (events) {

          events.forEach(e => {
            if(e.allDay) {
              e.end = moment(e.end).subtract({ days:1}) 
            }
          });

          var data = _.map(events,function (event) {

            var form = _.find($scope.forms, function (f) { return f._id == event.formId});

            var start = "";
            var end = "";
            if(form.properties.datespan) {
              start = moment(event.start).format('DD.MM.YYYY');
              end = moment(event.end).format('DD.MM.YYYY');
            } else if(form.properties.timespan) {
              start = moment(event.start).format('DD.MM.YYYY, H:mm');
              end = moment(event.end).format('H:mm');
            }


            var result = {
              eventType: {
                text: event.form.name,
                className: event.className,
                textColor: event.textColor,
                color:event.color
              },
              eventName: event.title,
              _id:event._id,
              start:start,
              end:end,
              startTime: moment(event.start).valueOf(),
              endTime: moment(event.end).valueOf()
            };


            _.each(athletes, function(a) {

              if(event.attendees) {
                result[a._id] = event.attendees.indexOf(a._id) >= 0
              }
            });
            return result;
          });


          data = _.sortBy(data, function (d) { return d.startTime});

          data = [athletesStat(athletes,data),athletesStatDays(athletes,data)].concat(data);


          $scope.data = data;




          $scope.settings = {
            colHeaders: ['Type','Event','Start','End'].concat(athletesNames),
            stretchH: 'all',
            height: window.innerHeight - 200,
            fixedRowsTop: 2,
            columns: [
              {
                data: 'eventType',
                readOnly: true,
                renderer: eventTypeRenderer
              },
              {
                data: 'eventName',
                readOnly: true
              },
              {
                data: 'start',
                readOnly: true
              },
              {
                data: 'end',
                readOnly: true
              }
            ].concat(athletesColumns),
            afterChange: function(changes,source) {
              if(source == 'edit') { // check if is a user input
                $scope.data[0] = athletesStat(athletes, $scope.data.slice(2));
                $scope.data[1] = athletesStatDays(athletes, $scope.data.slice(2));
              }
              _.each(changes,function(change) {
                var row  = $scope.data[change[0]];
                var presences = _.map(_.filter(_.pairs(row), function(p) { return p[1] && p[0] != 'eventName'  && p[0] != 'eventType' && p[0] != '_id' && p[0] != 'start'  && p[0] != 'end' }), function (p) { return p[0]});
                FormData.get({
                  formDataId: row._id
                }).$promise.then(function (original) {
                  original.attendees = presences;
                  FormData.update({
                    formDataId: row._id
                  },original,function(result) {
                  })
                })
              })
            }
          }
        },
        //error
        function (error) {
          AlertsManager.showError(error);
          console.log(error)
        }
      )




    }

    var dayInMillis = 24*60*60*1000;

    function splitDays(event) {
      if(event.endTime < event.startTime) { //2 millisecond of tollerance
        return []
      } else {
        var newEvent = angular.copy(event);
        newEvent.startTime = event.startTime + dayInMillis;
        return [event].concat(splitDays(newEvent));
      }
    }

    function athletesStatDays(athletes,data) {

      var splitDataByDay = _.flatten(_.map(data,splitDays));
      var dataGroupedByDay = _.values(_.groupBy(splitDataByDay,function(e) {
        return moment(e.startTime).format('DD.MM.YYYY')
      }));

      var dataForDays = _.map(dataGroupedByDay, function(day) {
        var presencies = {};
        _.each(athletes,function(a) {
          presencies[a._id] = _.some(day,function (e) { return e[a._id]})
        });
        return presencies;
      });


      var base = {
        eventName: "Total days: " + dataForDays.length
      };
      _.each(athletes, function(a) {
        base[a._id] = athleteStat(a._id,dataForDays,"days");
      });
      return base;
    }

    function athletesStat(athletes,data) {
      var base = {
        eventType: { text: "Attendencies", textColor:"#333"},
        eventName: "Total " + $scope.mode + ": " + data.length
      };
      _.each(athletes, function(a) {
        base[a._id] = athleteStat(a._id,data,$scope.mode);
      });
      return base;
    }

    function athleteStat(athleteId,data,label) {
      var totalEvents = data.length;
      var presentEvent = _.filter(data,function(e) {
        return e[athleteId];
      });
      var totalPresenceEvent = presentEvent.length;
      var percentage = (totalPresenceEvent / totalEvents  * 100).toFixed(2);
      return totalPresenceEvent + " " + label + " - " + percentage + "%";
    }

    function eventTypeRenderer(instance, td, row, col, prop, value, cellProperties) {
      if(value) {
        Handsontable.renderers.TextRenderer.apply(this, [instance, td, row, col, prop, value.text, cellProperties]);
        td.style.fontWeight = 'bold';
        td.style.color = "#fff";
        if (value.className) td.className = value.className;
        if (value.textColor) td.style.color = value.textColor;
        if (value.color) {
          td.style.background = value.color;
        }
      } else Handsontable.renderers.TextRenderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
    }

    function athleteRenderer(instance, td, row, col, prop, value, cellProperties) {
      if(typeof(value) === "boolean") {
        Handsontable.renderers.CheckboxRenderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
      } else Handsontable.renderers.TextRenderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
    }



    $scope.$watch('[mode,myDateRange, menu.team, menu.selectedTeams, menu.athlete, menu.selectedAthletes, analize, menu.athleteOnly]', onLoad, true);


  }
]);
