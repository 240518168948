'use strict';

angular.module('tdl.directives').directive('tdlRecurringEvent', ['Form', '$rootScope', 'StorageHelper','Menu','$http','TDLAuth','Conf','AlertsManager','ModalManager',
  function (Form,$rootScope,StorageHelper,Menu,$http,TDLAuth,Conf,AlertsManager,ModalManager) {

    var directiveDefinitionObject = {
      restrict: 'E', // only activate on element attribute
      scope: {
        forms: '=',
        onCreate: '=',
      },
      link: link,
      templateUrl: 'views/directives/tdlRecurringEvent.html'
    }

    return directiveDefinitionObject;

    function link(scope, elem, attrs, $compile) {

      scope.TDLconf = $rootScope.TDLconf;


      scope.recurringTypes = [
        {name: "By week day", id: "weekly"},
        {name: "By month day", id: "monthly"},
      ]

      scope.model = {
        formId: null,
        recurring: {
          mode: "weekly",
          months: {},
          weekMode: "all",
          weeksOfMonth: {"0": true},
          daysOfWeek: {"0": true},
          daysOfMonth: {"0": true},
        },

        formData: {
          data: {}
        },
        dateRange: {
          startDate:moment().startOf('day'),
          endDate: moment().add(5, 'months').startOf('day')
        },
        startTime: moment(0).add(7,'hours').toDate(),
        endTime: moment(0).add(8,'hours').toDate()
      }


      scope.form = null;

      scope.weekdays = moment.weekdays()




      scope.months = [];


      scope.updateMonth = function() {
        scope.model.months = {};
        var months = [];
        for(var i = 0; moment(scope.model.dateRange.endDate).startOf('month').diff(moment(scope.model.dateRange.startDate).startOf('month').add(i,'months')) >= 0; i++) {
          var current = moment(scope.model.dateRange.startDate).startOf('month').add(i,'months');
          if(!months.includes(current.month())) {
            months.push(current.month())
          }
        }
        scope.months = months.map( function(x) {
          scope.model.recurring.months[x] = true;
          return {id: x, name: moment.months()[x]}
        });
      }

      scope.updateMonth();

      scope.onSelectForm = function() {
        Form.get({formId: scope.model.formId}).$promise.then(function (f) {
          scope.form = f;
        })
      }

      function objToList(t) {
        return _.reduce(_.keys(t), function(memo,x) { if(t[x]) { memo.push(parseInt(x))}; return memo},[]);
      }

      scope.save = function() {
        var result = {
          data: scope.model.formData.data,
          recurring: scope.model.recurring,
          formId: scope.model.formId
        }

        if(Menu.teamsOrAthletes == "teams") {
          result['teamId'] = Menu.team._id;
        } else {
          result['athleteId'] = Menu.athlete._id;
        }


        result.recurring['from'] = moment.utc([scope.model.dateRange.startDate.year(),scope.model.dateRange.startDate.month(),scope.model.dateRange.startDate.date(),0,0]).valueOf();
        result.recurring['to'] = moment.utc([scope.model.dateRange.endDate.year(),scope.model.dateRange.endDate.month(),scope.model.dateRange.endDate.date(),0,0]).valueOf();
        result.recurring['startTime'] = moment.utc([1970,0,1,scope.model.startTime.getHours(),scope.model.startTime.getMinutes()]).valueOf();
        result.recurring['endTime'] = moment.utc([1970,0,1,scope.model.endTime.getHours(),scope.model.endTime.getMinutes()]).valueOf();

        result.recurring.months       = objToList(result.recurring.months);
        result.recurring.daysOfMonth  = objToList(result.recurring.daysOfMonth);
        result.recurring.daysOfWeek   = objToList(result.recurring.daysOfWeek);
        result.recurring.weeksOfMonth = objToList(result.recurring.weeksOfMonth);



        $http.post(Conf.api + '/formData/recurring', result, {
          headers: TDLAuth.header()
        }).then(function (data, status, headers, config) {
            AlertsManager.addAlert({
              type: 'success',
              message: 'Forms are been created correctly!'
            });
            scope.onCreate();
            ModalManager.closeModal();
          },
          function (err) {
            console.log(err);
          });


      }




    }
  }]);
