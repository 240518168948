/*global define*/
'use strict';

import LoadingBar from "./loadingBar";
import _ from "underscore";


angular.module('tdl.services').config(['$provide', '$httpProvider', function ($provide, $httpProvider) {

  $provide.factory('httpLoadingInterceptor', ['$q', 'AlertsManager',
    function ($q, AlertsManager) {
      function showError(error) {
        if (_.find(AlertsManager.alerts, function (alert) {
            return alert.status === error.status;
          }) === undefined) {
          switch (error.status) {
          case 403:
            AlertsManager.addAlert({
              'type': 'danger',
              'message': 'You don\'t have permissions to fetch data... Are you sure you are logged in correctly?',
              'status': error.status
            });
            break;
          case 404:
            AlertsManager.addAlert({
              'type': 'danger',
              'message': 'The request you sent is invalid. Page not found.',
              'status': error.status
            });
            break;
          }
        }
      }

      return {
        'request': function (config) {
          LoadingBar.add();
          return config;
        },

        'requestError': function (rejection) {
          LoadingBar.add();
          $('#showConnection').show();
          $('#connectionError').show();
          return $q.reject(rejection);
        },

        'response': function (response) {
          LoadingBar.remove();
          return response;
        },

        'responseError': function (rejection) {
          LoadingBar.remove();
          showError(rejection);
          return $q.reject(rejection);
        }
      };
    }
  ]);

  $httpProvider.interceptors.push('httpLoadingInterceptor');
}]);
