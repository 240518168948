'use strict';

/**
 * Services that persists and retrieves TODOs from localStorage.
 */

angular.module('tdl.services.storage').factory('Notice', ['$resource', 'Conf', 'TDLAuth', 'StorageHelper',
 function ($resource, Conf, TDLAuth, StorageHelper) {
    var transResponseStack = StorageHelper.addTransResponse(transResponse);

    return $resource(Conf.api + '/notices/:noticeId', {}, {
      last: {
        method: 'GET',
        params: {
          noticeId: 'last'
        },
        isArray: true,
        headers: TDLAuth.header(),
        transformResponse: transResponseStack
      },
      get: {
        method: 'GET',
        params: {
          noticeId: ''
        },
        isArray: false,
        headers: TDLAuth.header(),
        cache: false
      },
      query: {
        method: 'GET',
        isArray: true,
        headers: TDLAuth.header()
      },
      post: {
        method: 'POST',
        isArray: false,
        headers: TDLAuth.header()
      },
      update: {
        method: 'PUT',
        headers: TDLAuth.header()
      },
      remove: {
        method: 'DELETE',
        headers: TDLAuth.header()
      }
    });

    function transResponse(data) {
      data.date = moment(data.date).format('YYYY-MM-DD');
      return data;
    }

 }]);
