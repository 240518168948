/*global define*/
'use strict';


/**
 * Services that persists and retrieves TODOs from localStorage.
 */



// By default, AngularJS will catch errors and log them to
// the Console. We want to keep that behavior; however, we
// want to intercept it so that we can also log the errors
// to the server for later analysis.


angular.module('tdl.services').factory('$exceptionHandler', ['$log', 'errorLogService', function($log, errorLogService) {
  return function myExceptionHandler(exception, cause) {
    errorLogService(exception, cause);
    $log.warn(exception, cause);
  };
}]);


// -------------------------------------------------- //
// -------------------------------------------------- //


// The error log service is our wrapper around the core error
// handling ability of AngularJS. Notice that we pass off to
// the native '$log' method and then handle our additional
// server-side logging.
angular.module('tdl.services').factory('errorLogService',['Conf',
  function (Conf) {



    // I log the given error to the remote server.
    function log(exception, cause) {

      function logToServer() {
        let user = JSON.parse(sessionStorage.getItem("Auth.user"))
        let username = user ? user.username : null;

        fetch(Conf.api + "/ui/runtime-error", {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            exception: exception.stack || exception.message,
            cause: cause,
            userAgent: navigator.userAgent,
            tdlUiVersion: VERSION,
            api: Conf.api,
            username: username,
            location: window.location.href,
            timestamp: moment().utc().valueOf()
          })
        })

      }

      let logToServerDebounced = _.debounce(logToServer, 1000, true);

      // Pass off the error to the default error handler
      // on the AngualrJS logger. This will output the
      // error to the console (and let the application
      // keep running normally for the user).

      // Now, we need to try and log the error the server.
      // --
      // NOTE: In production, I have some debouncing
      // logic here to prevent the same client from
      // logging the same error over and over again! All
      // that would do is add noise to the log.
      try {

        logToServerDebounced()

      } catch (loggingError) {



      }

    }


    // Return the logging function.
    return (log);

  }]
);
