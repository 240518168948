/*global define*/
'use strict';

import _ from "underscore";

angular.module('tdl.services.helpers').factory('FormDataHelper', ['$rootScope', 'TDLAuth', function ($rootScope, TDLAuth) {
  return {
    fetchAlerts: fetchAlerts,
    fromFormFieldsToData: fromFormFieldsToData,
    getDefault: getDefault,
    labelFromForm: labelFromForm,
    labelTokenFromForm: labelTokenFromForm,
    ui: {
      color: formColor,
      visible: visible,
      visibleForUsers: visibleForUsers,
      background: formBackground
    }
  };

  function visible(form, controller, weekOrMonth, athleteOrTeam) {
    //console.log('visible')
    return visibleForUsers(form) && visibleInController(form, controller) && visibleForAthleteOrTeam(form, athleteOrTeam) && visibleForWeekOrMonth(form, weekOrMonth);
  }

  function visibleForUsers(form) {
    return _.contains(TDLAuth.user.calendarForms, form._id);
  }

  function visibleInController(form, controller) {
    if (controller == 'planning') {
      return form.properties.showInCalendar;
    } else if (controller == 'report') {
      return form.properties.showInReport;
    } else {
      return true;
    }
  }

  function visibleForAthleteOrTeam(form, athleteOrTeam) {
    if (athleteOrTeam == 'athletes') {
      return form.properties.athlete;
    } else if (athleteOrTeam == 'teams') {
      return form.properties.team;
    } else {
      return true;
    }
  }

  function visibleForWeekOrMonth(form, weekOrMonth) {
    if ($rootScope.TDLconf.ui.showBothWeekMonth) {
      if($rootScope.TDLconf.ui.hideFromWeek && weekOrMonth == 'week') {
        return form.properties.timespan;
      } else {
        return true;
      }
    } else if (weekOrMonth == 'week') {
      return form.properties.timespan;
    } else if (weekOrMonth == 'month') {
      return form.properties.datespan;
    } else {
      //console.log(weekOrMonth)
      return true;
    }
  }

  function labelFromForm(formData, form) {
    return labelTokenFromForm(formData,form).join(" - ")
  }

  function labelTokenFromForm(formData, form){

    var title = _.map(form.properties.labels, function (label) {
      var value = formData.data[label];
      var field = _.find(form.fields, function (f) {
        return f.id == label
      });
      if (field && field.options) {
        var option = _.find(field.options, function (o) {
          return o.id == value
        });
      }

      if(field && field.typ == 'Multiselect' && Array.isArray(value)) {
        return value.map(x => x.name).join(", ")
      }

      if (option) {
        return option.name;
      } else {
        return value;
      }
    })
    if(title.length == 0) {
      return [form.name]
    } else {
      return title
    }
  }

  function formColor(form) {
    return {
      color: form.properties.color,
    }
  }

  function formBackground(form) {
    return {
      "background-color": form.properties.color,
      color: form.properties.textColor
    }
  }

  /**
   * It creates the FormData's fields that are present in 'FormData.data'.
   * @param   {Array.<field>} fields the Form's fields
   * @returns {Array.<dataField>} The array places in FormData.data.
   *                              Properties are empty and they have to be populated by the user.
   */
  function fromFormFieldsToData(fields) {
    return _.reduce(fields,
      function (fields, field) {
        if (field.typ === 'Checkbox' || field.typ === 'Evaluation table') {
          fields[field.id] = _.reduce(field.options, function (result, option) {
            result[option.id] = false;
            return result;
          }, {});
        } else if (!(field.typ === 'Section' || field.typ === 'Free text')) {
          fields[field.id] = '';
        }
        return fields;
      }, {});
  }

  function getDefault() {
    return {
      //_id: '',
      //athleteId: '',
      changes: [],
      data: {},
      //start: null,
      //end: null,
      formId: null,
      submitted: null,
      userId: null,
      permissions: null
    };
  }

  function fetchAlerts(form, formData) {
    return _.map(formData.alerts, translateSingleAlert);

    function translateSingleAlert(alert) {
      var result;

      switch (alert.typ) {
      case 'overlapGlobal':
        result = _.extend({
          message: 'Ci sono altri eventi (' + alert.amount + ') alla stessa ora'
        }, alert);
        break;

      case 'overlapSameType':
        result = _.extend({
          message: 'Ci sono altri eventi (' + alert.amount + ') dello stesso tipo alla stessa ora'
        }, alert);
        break;

      case 'overlapSameField':
        result = _.extend({
          message: 'Ci sono altri eventi (' + alert.amount + ') alla stessa ora con lo stesso ' + fetchNameField(fromFieldToFieldId(alert.field))
        }, alert);
        break;
      }

      result.style = getAlertStyle(alert.priority);

      return result;
    }

    function fetchNameField(fieldId) {
      var originalField = _.find(form.fields, function (field)  {
        return field.id === fieldId;
      });

      if (originalField) {
        return originalField.name;
      } else {
        return fieldId;
      }
    }

    function fromFieldToFieldId(field) {
      var arrayField = field.split('.');

      return arrayField[arrayField.length - 1];
    }

    function getAlertStyle(priority) {
      return 'has-' + priority;
    }
  }

}]);
