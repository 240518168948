/*global define*/



import '../styles/libraries.scss';
import '../styles/angular-csp.css';
import '../../node_modules/ng-tags-input/build/ng-tags-input.css';
import '../../node_modules/bootstrap-daterangepicker/daterangepicker.css';
import '../styles/daterangepicker.css';
import '../../node_modules/animate.css/animate.css';
import '../styles/pace.css';
import '../../node_modules/ng-table/dist/ng-table.css';
import '../../node_modules/ui-select/dist/select.css';
import '../../node_modules/ol/ol.css';



import '../styles/main.scss'

import "expose-loader?exposes=jQuery!jquery";
import "expose-loader?exposes=$!jquery";
import "expose-loader?exposes=moment!moment";
import "expose-loader?exposes=Handsontable!handsontable";
import $ from 'jquery';
import angular from "angular";
import 'fullcalendar'
import 'handsontable'
import _ from "underscore/underscore-min";
import confModule from "./config";
import controllerModule from "./controllers/controllers";
import directiveModule from "./directives/directives";
import filterModule from "./filters/filters";
import serivceModule from "./services/services";
import ngResources from "angular-resource";
import ngCookies from "angular-cookies";
import ngRoute from "angular-route";
import uiCalendar from "angular-ui-calendar"
import uiBootstrap from 'angular-ui-bootstrap';
import ngTagsInput from "ng-tags-input/build/ng-tags-input.js"
import angularMoment from "angular-moment"
import ngMessages from "angular-messages"
import ngFileUpload from "ng-file-upload"
import download from "angular-download"
import ngTable from "ng-table"
import uiSelect from "ui-select"
import ngSantize from "angular-sanitize"
import ngFileSaver from "angular-file-saver"
import ngHandsontable from "ng-handsontable/dist/ngHandsontable";
import ngAria from 'angular-aria'
import ngAnimate from 'angular-animate'
import ngMaterial from 'angular-material'
import ngCsv from 'ng-csv'
import 'bootstrap-daterangepicker/daterangepicker'
import 'bootstrap-daterangepicker/daterangepicker.css'
import userConstants from "./services/userConstants";
import TDLAuthProvider from "./services/auth";
import angularNotification from 'angular-notification'
import ngWs from 'ng-ws'
import "./services/menu"
import "./services/calendar"
import "./services/eventLoader"
import "./services/httpLoadingInterceptor"
import "./services/loadingBar"
import "./services/logging"
import "./services/modalManager"
import "./services/starter"
import "./services/staticDataLoader"
import "./services/loginStorage"
import "./services/alertsManager"
import "./services/exportService"
import "./services/dataProcessor"
import "./services/helpers/activityHelper"
import "./services/helpers/calendarHelper"
import "./services/helpers/formDataHelper"
import "./services/helpers/formHelper"
import "./services/storage/analisis"
import "./services/storage/athlete"
import "./services/storage/configService"
import "./services/storage/feedback"
import "./services/storage/form"
import "./services/storage/formAnalisis"
import "./services/storage/formData"
import "./services/storage/formDataPublic"
import "./services/storage/formGroup"
import "./services/storage/formPublic"
import "./services/storage/labels"
import "./services/storage/mail"
import "./services/storage/notice"
import "./services/storage/stats"
import "./services/storage/storageHelper"
import "./services/storage/team"
import "./services/storage/user"
import "./services/storage/wizard"
import "./services/storage/workflows"
import "./services/storage/exportsManager"
import "./directives/autofocus"
import "./directives/checkBox"
import "./directives/datepickerPopup"
import "./directives/filterCreator"
import "./directives/hcChart"
import "./directives/recurringTab"
import "./directives/openlayers"
import "./directives/tdlActivityHandler"
import "./directives/tdlActivityReportHandler"
import "./directives/tdlAnalisis"
import "./directives/tdlCalendarVertical"
import "./directives/tdlCalendarYear"
import "./directives/tdlContactMail"
import "./directives/tdlDate"
import "./directives/tdlDraggable"
import "./directives/tdlEquals"
import "./directives/tdlEventHandler"
import "./directives/tdlFile"
import "./directives/tdlForm"
import "./directives/tdlFormDataAlerts"
import "./directives/tdlFormDataHandler"
import "./directives/tdlFormPermissions"
import "./directives/tdlInputTime"
import "./directives/tdlMainPanel"
import "./directives/tdlMiniCalendars"
import "./directives/tdlPredefinedTimeSelections"
import "./directives/tdlRadar"
import "./directives/tdlDateRange"
import "./directives/tdlRecurringEvent"
import "./directives/tdlSidebarTab"
import "./directives/tdlFormReport"
import "./directives/tdlImage"
import "./directives/tdlInputPermissions"
import "./directives/tdlTabs"
import "./directives/toastEditor"
import "./directives/toastViewer"
import "./directives/uiMonacoeditor"
import "./directives/variableCreator"
import "./directives/analisis/tdlAnalisisChart"
import "./directives/analisis/tdlAnalisisCompare"
import "./directives/analisis/tdlAnalisisFieldSelctor"
import "./directives/analisis/tdlAnalisisNumber"
import "./directives/addons/garminTab"
import "./directives/addons/polarTab"
import "./controllers/formDataPublic"
import "./controllers/formData"
import "./controllers/import"
import "./controllers/menu"
import "./controllers/analytics"
import "./controllers/calendarYear"
import "./controllers/contact"
import "./controllers/dashboard"
import "./controllers/formList"
import "./controllers/formReport"
import "./controllers/legal"
import "./controllers/planning"
import "./controllers/presences"
import "./controllers/report"
import "./controllers/signup"
import "./controllers/userConfirm"
import "./controllers/userEdit"
import "./controllers/userRecovery"
import "./controllers/welcome"
import "./controllers/wizards"
import "./controllers/addons/garminCallback"
import "./controllers/addons/hr"
import "./controllers/addons/polarCallback"
import "./controllers/admin/formAnalisis"
import "./controllers/admin/athletes"
import "./controllers/admin/exports"
import "./controllers/admin/analisisCreator"
import "./controllers/admin/formCreator"
import "./controllers/admin/formGroups"
import "./controllers/admin/formList"
import "./controllers/admin/notices"
import "./controllers/admin/signupRequests"
import "./controllers/admin/teams"
import "./controllers/admin/userList"
import "./controllers/admin/users"
import "./controllers/admin/usersInvite"
import "./controllers/menu"
import "./filters/filters"
import "./filters/formatDate"
import "./filters/safeName"
import "./filters/slugify"
import "./filters/timezoneFix"


//force https
if (location.protocol !== "https:") {
  location.protocol = "https:";
}

$(document).ready(function () {
  /* Nav/Team Dropdown basics
  -----------------------------------------------------------------*/
  $('.nav-dropdown-button').click(function (e) {
    e.stopPropagation();
    $(this).parent().find('.nav-dropdown-menu').slideToggle('fast');
  });

  $('.nav-dropdown-container').click(function (e) {
    e.stopPropagation();
  });

  $('html').click(function () {
    $('.nav-dropdown-menu').slideUp('fast');
  });

  $('#user-menu a').click(function () {
    $('#user-menu .nav-dropdown-menu').slideUp('fast');
  });

  $(document).on('click', '.collapse-sidebar', function () {
    $('.collapse-sidebar i').toggleClass('fa-toggle-right fa-toggle-left');
    $('#main-sidebar').toggleClass('collapsed');
    $('#main-panel').toggleClass('full');
    $('#sectionbar').toggleClass('full');

    $('#sectionbar').on('transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd', function () {
      var rite = ($(window).width() - ($('#table-fixed-body').offset().left + $('#table-fixed-body').innerWidth()));
      $('#table-fixed-header').css('right', rite);
    });
  });
});

angular.module('tdl', [
  'tdl.config',
  'tdl.controllers',
  'tdl.directives',
  'tdl.filters',
  'tdl.services',
  'ngRoute',
  'ngCookies',
  'ngResource',
  'ui.calendar',
  'ui.bootstrap',
  'ngTagsInput',
  'angularMoment',
  'ngMessages',
  'ngFileUpload',
  'download',
  'ngTable',
  'ui.select',
  'ngSanitize',
  'ngFileSaver',
  'ngHandsontable',
  'ngCsv',
  'ngMaterial',
  'notification',
  'ng-ws'
])
  .config(['$routeProvider', 'UserConstants', '$locationProvider', '$compileProvider', function ($routeProvider, UserConstants, $locationProvider, $compileProvider) {

    var access = UserConstants.accessLevels;

    $locationProvider.hashPrefix('');
    //$compileProvider.preAssignBindingsEnabled(true);

    $routeProvider.when('/', {
      access: access.public,
      controller: 'WelcomeController',
      templateUrl: 'views/welcome.html',
      resolve: {
        Starter: 'Starter'
      }
    });
    $routeProvider.when('/legal/privacy-policy', {
      access: access.public,
      controller: 'LegalController',
      templateUrl: 'views/legal/privacy-policy.html',
      resolve: {
        Starter: 'Starter'
      }
    });
    $routeProvider.when('/legal/cookie-policy', {
      access: access.public,
      controller: 'LegalController',
      templateUrl: 'views/legal/cookie-policy.html',
      resolve: {
        Starter: 'Starter'
      }
    });
    $routeProvider.when('/legal/terms-of-service', {
      access: access.public,
      controller: 'LegalController',
      templateUrl: 'views/legal/terms-of-service.html',
      resolve: {
        Starter: 'Starter'
      }
    });
    $routeProvider.when('/signup', {
      access: access.public,
      controller: 'SignupController',
      templateUrl: 'views/signup.html',
      resolve: {
        Starter: 'Starter'
      }
    });
    $routeProvider.when('/planning', {
      access: access.user,
      controller: 'PlanningController',
      templateUrl: 'views/planning.html',
      resolve: {
        Starter: 'Starter'
      }
    });
    $routeProvider.when('/dashboard', {
      access: access.user,
      controller: 'DashboardController',
      templateUrl: 'views/dashboard.html',
      resolve: {
        Starter: 'Starter'
      }
    });
    $routeProvider.when('/report', {
      access: access.user,
      controller: 'ReportController',
      templateUrl: 'views/report.html',
      resolve: {
        Starter: 'Starter'
      }
    });
    $routeProvider.when('/analyze/:teamId/:start/:end/:page', {
      access: access.user,
      controller: 'AnalyticsController',
      templateUrl: 'views/analyze.html',
      resolve: {
        Starter: 'Starter'
      }
    });
    $routeProvider.when('/analyze', {
      access: access.user,
      controller: 'AnalyticsController',
      templateUrl: 'views/analyze.html',
      resolve: {
        Starter: 'Starter'
      }
    });
    $routeProvider.when('/contact', {
      access: access.user,
      controller: 'ContactController',
      templateUrl: 'views/contact.html',
      resolve: {
        Starter: 'Starter'
      }
    });
    $routeProvider.when('/help', {
      access: access.user,
      controller: 'ReportController',
      templateUrl: 'views/help.html',
      resolve: {
        Starter: 'Starter'
      }
    });
    $routeProvider.when('/profile', {
      access: access.user,
      controller: 'ReportController',
      templateUrl: 'views/profile.html',
      resolve: {
        Starter: 'Starter'
      }
    });

    $routeProvider.when('/form', {
      access: access.user,
      controller: 'FormListController',
      templateUrl: 'views/formList.html',
      resolve: {
        Starter: 'Starter'
      }
    });
    $routeProvider.when('/form/team/:teamId', {
      access: access.user,
      controller: 'FormListController',
      templateUrl: 'views/formList.html',
      resolve: {
        Starter: 'Starter'
      }
    });
    $routeProvider.when('/formData/:formId', {
      access: access.user,
      controller: 'FormDataController',
      templateUrl: 'views/formData.html',
      resolve: {
        Starter: 'Starter'
      }
    });
    $routeProvider.when('/public/form/:formId', {
      access: access.public,
      controller: 'FormDataPublicController',
      templateUrl: 'views/formData.html',
      resolve: {
        Starter: 'Starter'
      }
    });
    $routeProvider.when('/formData/:formId/:id', {
      access: access.user,
      controller: 'FormDataController',
      templateUrl: 'views/formData.html',
      resolve: {
        Starter: 'Starter'
      }
    });
    $routeProvider.when('/formReport/:formIds', {
      access: access.user,
      controller: 'FormReportController',
      templateUrl: 'views/formReport.html',
      resolve: {
        Starter: 'Starter'
      }
    });
    $routeProvider.when('/formReport/:formIds/:teamId', {
      access: access.user,
      controller: 'FormReportController',
      templateUrl: 'views/formReport.html',
      resolve: {
        Starter: 'Starter'
      }
    });
    $routeProvider.when('/formDataView/:formId/:id', {
      access: access.user,
      controller: 'FormDataController',
      templateUrl: 'views/formData.html',
      resolve: {
        Starter: 'Starter'
      }
    });

    $routeProvider.when('/user/edit', {
      access: access.user,
      controller: 'UserEditController',
      templateUrl: 'views/userEdit.html',
      resolve: {
        Starter: 'Starter'
      }
    });
    $routeProvider.when('/year', {
      access: access.user,
      controller: 'CalendarYearController',
      templateUrl: 'views/calendarYear.html',
      resolve: {
        Starter: 'Starter'
      }
    });
    $routeProvider.when('/presences', {
      access: access.user,
      controller: 'PresencesController',
      templateUrl: 'views/presences.html',
      resolve: {
        Starter: 'Starter'
      }
    });

    $routeProvider.when('/import/:formId', {
      access: access.user,
      controller: 'ImportController',
      templateUrl: 'views/import.html',
      resolve: {
        Starter: 'Starter'
      }
    });

    $routeProvider.when('/wizard/:wizardId', {
      access: access.user,
      controller: 'WizardsController',
      templateUrl: 'views/wizards.html',
      resolve: {
        Starter: 'Starter'
      }
    });

    $routeProvider.when('/addons/polar/callback/:code', {
      access: access.user,
      controller: 'PolarCallbackController',
      templateUrl: 'views/addons/polarCallback.html',
      resolve: {
        Starter: 'Starter'
      }
    });

    $routeProvider.when('/addons/garmin/callback/:token/:verifier', {
      access: access.user,
      controller: 'GarminCallbackController',
      templateUrl: 'views/addons/garminCallback.html',
      resolve: {
        Starter: 'Starter'
      }
    });

    $routeProvider.when('/addons/hr', {
      access: access.user,
      controller: 'HrController',
      templateUrl: 'views/addons/hr.html',
      resolve: {
        Starter: 'Starter'
      }
    });


    ///////////////// ADMIN ///////

    $routeProvider.when('/admin', {
      access: access.admin,
      controller: 'AdminController',
      templateUrl: 'views/admin/admin.html',
      resolve: {
        Starter: 'Starter'
      }
    });
    $routeProvider.when('/admin/athletes', {
      access: access.admin,
      controller: 'AthletesController',
      templateUrl: 'views/admin/athletes.html',
      resolve: {
        Starter: 'Starter'
      }
    });
    $routeProvider.when('/admin/exports', {
      access: access.admin,
      controller: 'ExportsController',
      templateUrl: 'views/admin/exports.html',
      resolve: {
        Starter: 'Starter'
      }
    });
    $routeProvider.when('/admin/formCreator/:id', {
      access: access.admin,
      controller: 'FormCreatorController',
      templateUrl: 'views/admin/formCreator.html',
      resolve: {
        Starter: 'Starter'
      }
    });
    $routeProvider.when('/admin/forms', {
      access: access.admin,
      controller: 'AdminFormListController',
      templateUrl: 'views/admin/formList.html',
      resolve: {
        Starter: 'Starter'
      }
    });
    $routeProvider.when('/admin/formAnalisis', {
      access: access.admin,
      controller: 'FormAnalisisController',
      templateUrl: 'views/admin/formAnalisis.html',
      resolve: {
        Starter: 'Starter'
      }
    });
    $routeProvider.when('/admin/analisisCreator', {
      access: access.admin,
      controller: 'AnalisisCreatorController',
      templateUrl: 'views/admin/analisisCreator.html',
      resolve: {
        Starter: 'Starter'
      }
    });
    $routeProvider.when('/admin/users', {
      access: access.admin,
      controller: 'UsersController',
      templateUrl: 'views/admin/users.html',
      resolve: {
        Starter: 'Starter'
      }
    });
    $routeProvider.when('/admin/invite/users', {
      access: access.admin,
      controller: 'UsersInviteController',
      templateUrl: 'views/admin/usersInvite.html',
      resolve: {
        Starter: 'Starter'
      }
    });
    $routeProvider.when('/user/invite/confirm/:token', {
      access: access.public,
      controller: 'UserConfirmController',
      templateUrl: 'views/userConfirm.html',
      resolve: {
        Starter: 'Starter'
      }
    });
    $routeProvider.when('/user/password/reset/confirm/:token', {
      access: access.public,
      controller: 'UserRecoveryController',
      templateUrl: 'views/userRecovery.html',
      resolve: {
        Starter: 'Starter'
      }
    });
    $routeProvider.when('/admin/users/list', {
      access: access.admin,
      controller: 'UsersListController',
      templateUrl: 'views/admin/usersList.html',
      resolve: {
        Starter: 'Starter'
      }
    });
    $routeProvider.when('/admin/teams', {
      access: access.admin,
      controller: 'TeamsController',
      templateUrl: 'views/admin/teams.html',
      resolve: {
        Starter: 'Starter'
      }
    });
    $routeProvider.when('/admin/formGroups', {
      access: access.admin,
      controller: 'FormGroupController',
      templateUrl: 'views/admin/formGroups.html',
      resolve: {
        Starter: 'Starter'
      }
    });
    $routeProvider.when('/admin/signup-requests', {
      access: access.admin,
      controller: 'SignupRequestsController',
      templateUrl: 'views/admin/signup-requests.html',
      resolve: {
        Starter: 'Starter'
      }
    });
    $routeProvider.when('/admin/notices', {
      access: access.admin,
      controller: 'NoticesController',
      templateUrl: 'views/admin/notices.html',
      resolve: {
        Starter: 'Starter'
      }
    });


    // $routeProvider.otherwise({
    //   redirectTo: '/'
    // });
  }])
  .config(['$sceDelegateProvider','$mdDateLocaleProvider',
    function ($sceDelegateProvider,$mdDateLocaleProvider) {
      $sceDelegateProvider.resourceUrlWhitelist(['self', 'http://api.teamdatalog.com/**']);

      $mdDateLocaleProvider.firstDayOfWeek = 1;

      $mdDateLocaleProvider.parseDate = function(dateString) {
        var m = moment(dateString, 'D.M.Y', true);
        return m.isValid() ? m.toDate() : new Date(NaN);
      };

      $mdDateLocaleProvider.formatDate = function(date) {
        var m = moment(date);
        return m.isValid() ? m.format('D.M.Y') : '';
      };

    }
  ])
  //See more at: http://codingsmackdown.tv/blog/2013/01/02/using-response-interceptors-to-show-and-hide-a-loading-widget/#sthash.lIkYIGTV.dpuf
  .run(['$rootScope', '$location', '$http', 'TDLAuth', '$cookieStore', 'AlertsManager', 'UserConstants', '$uibModal', 'Conf',
    function ($rootScope, $location, $http, TDLAuth, $cookieStore, AlertsManager, UserConstants, $uibModal, Conf) {
      $rootScope.accessors = {
        getId: function (row) {
          return row._id;
        }
      };

      $rootScope.VERSION = VERSION;
      console.log('VERSION' + VERSION);

      $rootScope.$on('$routeChangeStart', function (event, next, current) {
        $rootScope.error = null;
        if (!TDLAuth.isLoggedIn()) {
          if (!next.$$route.access || !next.$$route.access.includes(UserConstants.accessLevels.public)) {
            if (!$cookieStore.get('path') && window.location.hash.substring(1) != "/") {
              $cookieStore.put('path', window.location.hash.substring(1));
            }
            $location.path('/');
            // AlertsManager.addAlert({
            //   type: 'danger',
            //   message: 'Forbidden'
            // });
          }
          //everything ok
        } else { //logged in
          if (!TDLAuth.user.privacyAccepted && next.originalPath.search(/legal/) === -1) {
            $uibModal.open({
              backdrop: false,
              templateUrl: "views/legal/popup.html",
              controller: ['$scope', '$uibModalInstance', 'user', function ($scope, $uibModalInstance, user) {
                $scope.accept = function () {

                  $http.get(Conf.api + '/user/accept/privacy', {
                    headers: TDLAuth.header()
                  }).then(function (data, status, headers, config) {
                    TDLAuth.reloadUser().then(function () {
                      $uibModalInstance.dismiss('cancel');
                    })
                  }, function (err) {
                    console.err(err);
                  });

                };
              }],
              resolve: {
                user: function () {
                  return TDLAuth.user;
                }
              }
            });
          }
          if (!TDLAuth.access(next.$$route)) { // don't have access to the location
            $location.path(current.$$route.originalPath);
            // AlertsManager.addAlert({ //to often visibile
            //   type: 'danger',
            //   message: 'Forbidden'
            // });
          }
        }
      });
    }
  ]);


$(document).ready(function () {
  angular.bootstrap(document, ['tdl']);
  $("#version").css("z-index",99999)
})
