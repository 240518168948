'use strict';

import _ from "underscore";
/**
 * Services that persists and retrieves TODOs from localStorage.
 */

angular.module('tdl.services.storage').factory('Form', ['$resource', 'Conf', 'TDLAuth', '$http', 'StorageHelper',
 function ($resource, Conf, TDLAuth, $http, StorageHelper) {
    var forms = null,
      transResponseStack = StorageHelper.addTransResponse(transResponse),
      transRequestStack = StorageHelper.addTransRequest([StorageHelper.copyObjectTrans, StorageHelper.stripIdTrans]);

    var resource = prepareResource();

    onStart();

    return resource;

    function prepareResource() {
      return $resource(Conf.api + '/form/:formId', {
        formId: '@_id'
      }, {
        get: {
          method: 'GET',
          isArray: false,
          headers: TDLAuth.header(),
          transformResponse: transResponseStack
        },
        query: {
          method: 'GET',
          isArray: true,
          headers: TDLAuth.header(),
          transformResponse: transResponseStack
        },
        calendar: {
          method: 'GET',
          params: {
            formId: 'calendar'
          },
          isArray: true,
          headers: TDLAuth.header()
        },
        fields: {
          method: 'GET',
          params: {
            formId: 'fields'
          },
          isArray: true,
          headers: TDLAuth.header()
        },
        metadata: {
          method: 'GET',
          params: {
            formId: 'metadata'
          },
          isArray: false,
          headers: TDLAuth.header()
        },
        calendarReport: {
          method: 'GET',
          params: {
            formId: 'calendarReport'
          },
          isArray: true,
          headers: TDLAuth.header()
        },
        post: {
          method: 'POST',
          headers: TDLAuth.header(),
          transformResponse: transResponseStack,
          transformRequest: transRequestStack
        },
        update: {
          method: 'PUT',
          headers: TDLAuth.header(),
          transformResponse: transResponseStack,
          transformRequest: transRequestStack
        },
        remove: {
          method: 'DELETE',
          headers: TDLAuth.header()
        }
      });
    }

    function onStart() {
      if (TDLAuth.getUsername()) {
        resource.query().$promise.then(
          function (success) {
            forms = success;
          },
          function (error) {
            console.log(error)
          }
        );
      }
    }

    function transResponse(data) {

      if (data.properties.moves && data.properties.moves.changeToForm) {
        data.properties.moves.changeToFormObj = _.map(data.properties.moves.changeToForm, function (id) {
          var form = _.find(forms, function (f) {
            return f._id == id
          })
          if (form) {
            return {
              id: id,
              name: form.name
            }
          } else {
            return {
              id: id,
              name: 'no name'
            }
          }
        })
      }

      if (data.properties.moves &&  data.properties.moves.copyToForm) {
        data.properties.moves.copyToFormObj = _.map(data.properties.moves.copyToForm, function (id) {
          var form = _.find(forms, function (f) {
            return f._id == id
          })
          if (form) {
            return {
              id: id,
              name: form.name
            }
          } else {
            return {
              id: id,
              name: 'no name'
            }
          }
        })
      }

      return data;
    }


 }]);
