'use strict';

angular.module('tdl.filters')
  .filter('safeName', function () {

    return function (input) {
      if (input) {
        var result = input.toString().toLowerCase()
          .replace(/\s+/g, 'jjsp') // Replace spaces with -
          .replace(/[^\w\-]+/g, 'jjw') // Remove all non-word chars
          .replace(/-/g, 'jjd') // Trim - from start of text
          .replace(/0/g, 'jjz')
          .replace(/1/g, 'jjo')
          .replace(/2/g, 'jjtw')
          .replace(/3/g, 'jjth')
          .replace(/4/g, 'jjfo')
          .replace(/5/g, 'jjfi')
          .replace(/6/g, 'jjsi')
          .replace(/7/g, 'jjse')
          .replace(/8/g, 'jje')
          .replace(/9/g, 'jjn');
        return result;
      } else {
        return 'undefined';
      }
    };
  })