import _ from "underscore"

angular.module('tdl.services.storage').factory('StorageHelper', ['$http',
 function ($http) {
    var factoryObj = {
      addTransRequest: addTransRequest,
      addTransResponse: addTransResponse,
      dateToMillis: dateToMillis,
      millisToDate: millisToDate,
      stripIdTrans: getStripIdTrans(),
      copyObjectTrans: getCopyObjectTrans()
    };

    return factoryObj;


    /**
     * Add one or more request transformations to the default stack
     * @param   {function(objToTrans)|Array.<function(objToTrans)>} newTrans The transformation(s) for one single object.
     *                                                                       If the request is an array it's automatically handled.
     * @returns {Array.<function(objToTrans)>} The stack of transformation request
     */
    function addTransRequest(newTrans) {
      var defaultTransform = $http.defaults.transformRequest;
      defaultTransform = angular.isArray(defaultTransform) ? defaultTransform : [defaultTransform];
      return createTransArray(newTrans).concat(defaultTransform); //concatenate the default angular behaviur
    }

    /**
     * Add one or more response transformations to the default stack
     * @param   {function(objToTrans)|Array.<function(objToTrans)>} newTrans The transformation(s) for one single object.
     *                                                                       If the response is an array it's automatically handled.
     * @returns {Array.<function(objToTrans)>} The stack of transformation response
     */
    function addTransResponse(newTrans) {
      var defaultTransform = $http.defaults.transformResponse;
      defaultTransform = angular.isArray(defaultTransform) ? defaultTransform : [defaultTransform];
      return defaultTransform.concat(createTransArray(newTrans));
    }


    function getStripIdTrans() {
      return function (objToTrans) {
        delete objToTrans._id;
        return objToTrans;
      }
    }

    function getCopyObjectTrans() {
      return function (objToTrans) {
        return angular.copy(objToTrans);
      }
    }

   function dateToMillis(date) {
     // if(moment().isDST()) {
     //   return moment(date).valueOf() - (new Date().getTimezoneOffset() + 60) * 60 * 1000; //DAYLIGHT
     // } else {
       return moment(date).valueOf() - (new Date().getTimezoneOffset()) * 60 * 1000; //NO DAYLIGHT
     // }
   }

   function millisToDate(millis) {
     // if(moment().isDST()) {
     //   return new Date(millis + (new Date().getTimezoneOffset() + 60) * 60 * 1000); //DAYLIGHT
     // } else {
       return new Date(millis + (new Date().getTimezoneOffset()) * 60 * 1000); //NO DAYLIGHT
     // }
   }

    function createTransArray(transformations) {
      transformations = angular.isArray(transformations) ? transformations : [transformations];
      return _.map(transformations, handleSingleOrMultipleObjTrans);
    }

    function handleSingleOrMultipleObjTrans(transformation) {
      return function (objectToTransform) {
        return (angular.isArray(objectToTransform)) ?
          _.map(objectToTransform, transformation) :
          transformation(objectToTransform);
      }
    }

}]);
