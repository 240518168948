angular.module('tdl.controllers').controller('ImportController', ['$scope','$routeParams','$http','Form','Conf','TDLAuth','Upload','download','$filter',
  function ImportController($scope,$routeParams,$http,Form,Conf,TDLAuth,Upload,download,$filter) {
    Form.get({
      formId: $routeParams['formId']
    }).$promise.then(function (form) {
      $scope.title = form.name;
    });


    $scope.message = "Ready to import";



    $scope.applySolution = function(solution) {
      $http.post(Conf.api + solution.action,solution.obj,{headers: TDLAuth.header()}).then( function() {
        importFile($scope.file);
      })
    };

    $scope.import = importFile;
    $scope.template = template;

    function template() {
      var schemaLink = Conf.api + '/export/'+$routeParams['formId']+'/schema'
      $http.get(schemaLink,{headers: TDLAuth.header()}).then(function (data) {
        var name = $filter('slugify')($scope.title) + ".csv";
        download.fromData( data.data,"text/csv", name);
      });

    }

    function importFile(file){

      $scope.message = "";
      $scope.errors = [];

      Upload.upload({
            url: Conf.api + '/import/' + $routeParams['formId'],
            data: {file: file, 'username': $scope.username},
            headers: TDLAuth.header()
        }).then(function (resp) {

          if(resp.data.errors) {
            $scope.errors = resp.data.errors;
          } else {
            $scope.message = resp.config.data.file.name + ' uploaded. Response: \n' + resp.data;
          }
        }, function (resp) {
            $scope.message = 'Error status: ' + resp.status;
        }, function (evt) {
            var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
            $scope.message = 'progress: ' + progressPercentage + '% ' + evt.config.data.file.name;
        });

    }
  }
]);
