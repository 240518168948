'use strict';

angular.module('tdl.controllers').controller('FormDataController', ['$scope', '$routeParams', '$location', 'Menu','TDLAuth','$http','Conf',
  function FormController($scope, $routeParams, $location, Menu,TDLAuth,$http,Conf) {
    Menu.section = "form";
    $scope.menu = Menu;

    $scope.data = {
      formId: $routeParams['formId'],
      formDataId: $routeParams['id'],
      readOnlyMode: /^\/formDataView/.test($location.path()),
      public: false
    }

    $scope.back = function (l) {
      $location.path("/form");
    }

    $scope.print = function() {
      var title =  Menu.form ? Menu.form.name : '-  ' ;
      var team =  Menu.team ? Menu.team.name : '';

      var headers = angular.copy(TDLAuth.header())
      headers['Content-type'] = 'text/plain';

      $http({
        url: Conf.api + '/pdf/' + title + '/' + team + '?portrait=true&z=0.8',
        method: "POST",
        data: $(".printArea").html(),
        headers: headers,
        responseType: 'arraybuffer'
      }).then(function (data, status, headers, config) {
        var file = new Blob([ data.data ], {
            type : 'application/pdf'
        });
        //trick to download store a file having its URL
        var fileURL = URL.createObjectURL(file);
        var a         = document.createElement('a');
        a.href        = fileURL;
        a.target      = '_blank';
        a.download    = 'yourfilename.pdf';
        document.body.appendChild(a);
        a.click();
      },function (data, status, headers, config) {
        //upload failed
      });

    }


  }
]);
