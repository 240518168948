/*global define*/
'use strict';
import $ from "jquery"


var LoadingBar = {

	counter: 0,

	update: function() {
		if(this.counter <= 0) {
			$("#loading").hide();
			$("#showConnection").hide();
		} else {
			$("#showConnection").show();
			$("#loading").show();
		}
	},
	add: function() {
		this.counter++;
		this.update();
	},
	remove: function() {
		this.counter--;
		this.update();
	}

};

export default LoadingBar;
