'use strict';

angular.module('tdl.controllers').controller('UserRecoveryController',   ['$scope','$rootScope', 'TDLAuth', 'Conf', 'AlertsManager', 'Menu','$routeParams','$http',
  function ($scope, $rootScope, TDLAuth, Conf, AlertsManager, Menu, $routeParams, $http) {
    Menu.section = 'backend';
    $scope.menu = Menu;

    $scope.currentUser = {};

    $scope.userRights = {};

    $scope.usernameBusy = false;

    $scope.checkPassword = checkPassword;
    $scope.matchPassword = matchPassword;
    $scope.recoveryPassword = recoveryPassword;
    $scope.valid = valid;

    $scope.validToken = true;

    $http.get(Conf.api + "/user/password/reset/check/" + $routeParams['token']).then(function (data) {
      $scope.loaded = true;
      $scope.validToken = data.data;
    }, function (err) {
      console.log("invalid token");
      $scope.validToken = false;
    });




    $scope.passwordValidate = {
      lower: true,
      upper: true,
      numbers: true,
      length: true,
      confirmed: true
    };

    $scope.allValid = true;

    function checkPassword() {
      if($scope.currentUser.password.length > 0) {
        $scope.passwordValidate.lower = /[a-z]+/.test($scope.currentUser.password);
        $scope.passwordValidate.upper = /[A-Z]+/.test($scope.currentUser.password);
        $scope.passwordValidate.numbers = /[0-9]+/.test($scope.currentUser.password);
        $scope.passwordValidate.length = $scope.currentUser.password.length > 7;
        matchPassword();
      }

    }

    function matchPassword() {
      $scope.passwordValidate.confirmed = $scope.currentUser.password === $scope.validationPassword;
      $scope.allValid = $scope.passwordValidate.lower && $scope.passwordValidate.upper && $scope.passwordValidate.numbers && $scope.passwordValidate.length && $scope.passwordValidate.confirmed;
    }




    function valid() {
      return !$scope.formUser.$invalid  && $scope.allValid;
    }

    function recoveryPassword() {
      if(valid()) {
        var password = {password: $scope.currentUser.password};
        $http.post(Conf.api + "/user/password/reset/confirm/" + $routeParams['token'], password).then(function (data) {
          AlertsManager.addAlert({
            type: 'success',
            message: 'User password has been updated successfully!'
          });
          TDLAuth.loginWithToken(data.data.token).then(function() {
            var dest = "/planning";
            if($rootScope.TDLconf.ui.sections.dashboard) {
              dest = "/dashboard";
            }
            window.location = window.location.origin  + window.location.pathname + "?r=" +(+new Date()) + "#" + dest;
          });
        }, function (err) {
          console.log(err)
        });
      }
    }


  }
]);
