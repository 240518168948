angular.module('tdl.services.helpers').factory('ActivityHelper', ['ActivityType',
function (ActivityType) {
    var factObj = {
      getClass: getClass
    };

    var activityTypes;
    var idToClassMap = {};

    onStart();

    return factObj;

    function onStart() {
      activityTypes = ActivityType.query().$promise.then(function (eventTypes) {
        idToClassMap = _.reduce(eventTypes, function (memo, eventType) {
          memo[eventType._id] = eventType.tdlClass + ' activity';
          return memo;
        }, {});

        return eventTypes;
      });
    }

    function getClass(eventTypeId) {
      return idToClassMap[eventTypeId] || 'activity';
    }

}]);