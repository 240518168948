'use strict';

/**
 * Services that persists and retrieves TODOs from localStorage.
 */

angular.module('tdl.services.storage').factory('Labels', ['$resource', 'Conf',
 function ($resource, Conf) {

    return $resource(Conf.api + '/labels', {}, {
      get: {
        method: 'GET',
        isArray: false
      }
    });
 }]);