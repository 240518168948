/*global define*/
'use strict';

import Highcharts from 'highcharts'

angular.module('tdl.directives').directive('garminTab', ['Athlete','$rootScope','$http','TDLAuth','Conf','Menu',
  function (Athlete,$rootScope,$http,TDLAuth,Conf,Menu) {

    var directiveDefinitionObject = {
      restrict: 'E', // only activate on element attribute
      scope: {
        formId: '=',
        data: '=',
        garmin: '=',
        athleteId: '=',
        formDataId: '=',
        formData: '='
      },
      link: link,
      templateUrl: 'views/addons/directives/garminTab.html',
    }

    return directiveDefinitionObject;

    function formatTime(x,withSeconds) {
      let time =  Math.floor(x / 60 / 60) + ":"
              + String( Math.floor(x / 60) % 60).padStart(2,'0')
      if(withSeconds) time += ":" + String( x % 60 % 60).padStart(2,'0')

      return time
    }



    function loadChart(garmin,wind,scope,athlete) {

      function _conf() {
        if($rootScope.TDLconf.ui.addons && $rootScope.TDLconf.ui.addons.garmin) {


          return $rootScope.TDLconf.ui.addons.garmin.mapping.find(map => {
            //search in conf the addon with the same formId and, if exits the same tag
            return map.formId === scope.formId && (!map.formTagField || scope.data[map.formTagField].includes(map.formTag))
          })
        } else {
          return null
        }
      }
      const conf = _conf()

      function _speedUnit() {
        if(conf && conf.units && conf.units.speed) {
          return conf.units.speed
        } else {
          return "m/s"
        }
      }
      const speedUnit = _speedUnit()


      function convertSpeed(speed) {
        switch(speedUnit) {
          case "km/h": return speed * 3.6
          case "kn": return speed * 1.943844
          case "min:sec/km": return 16.66666666666 / speed
          case "min:sec/500m": return 8.33333333333 / speed
          default: return speed
        }
      }



      function speed(v) {
        switch(speedUnit) {
          case "min:sec/km":
          case "min:sec/500m": return Math.floor(v) + ":" + Math.round( (v - Math.floor(v)) * 60 )
          default: return Math.round(v * 100) / 100
        }
      }

      function speedLabel() {
        switch(speedUnit) {
          case "min:sec/km": return "/ km"
          case "min:sec/500m": return "/ 500m"
          default: return speedUnit
        }
      }



      const start = garmin.samples[0] ? garmin.samples[0].startTimeInSeconds : 0
      const hrSerie = garmin.samples.map(s => [s.startTimeInSeconds - start,s.heartRate])
      const speedSerie = garmin.samples.map(s => [s.startTimeInSeconds - start, convertSpeed(s.speedMetersPerSecond)])
      scope.garmin = garmin
      scope.wind = wind
      scope.gps = garmin.samples.map(sample => [sample.longitudeInDegree,sample.latitudeInDegree,sample.startTimeInSeconds - start] ).filter(x =>  typeof x[0] === 'number')


      const sport = (athlete.addon && athlete.addon.sports) ? athlete.addon.sports.find(x =>
        garmin.summary.activityType.toLowerCase().includes(x.sport) ||
        (scope.data.tags ? scope.data.tags.includes(x.sport) : false) ||
        (scope.formId === "529631594000006001a8cbf3report" && x.sport === "sailing")

      ) : null;

      let bands = [];
      const innerColors = ['#c2caca80','#46c7ee80','#6acc2b80','#f9bf1c80','#de0f5b80']
      if(sport) {
        bands = sport.zones.map((z,i) => {
          return { // Light air
            from: z,
            to: sport.zones[i+1] ? sport.zones[i+1] : 200,
            color: innerColors[i],
            label: {
              text: 'Zone ' + (i+1),
                style: {
                  color: '#606060'
                }
            }
          }
        })
      }

      scope.hrChart = {
        title: "HR",
        credits: {
          href: "http://teamdatalog.com",
          text: "teamdatalog.com"
        },
        legend: {
          enabled: false
        },
        xAxis: {
          crosshair: true,
          title: {
            enabled: true,
            text: 'Duration'
          },
          type: 'linear',
          labels: {
            formatter: function() {
              return formatTime(this.value)
            }
          }

        },
        yAxis: {
          title: {
            text: 'HR (bpm)'
          },
          minorGridLineWidth: 0,
          gridLineWidth: 0,
          alternateGridColor: null,
          plotBands: bands,
          min: 30,
          max: 200
        },
        tooltip: {
          formatter: function() {
            return '<b>HR: '+this.y+'</b><br>'  + 'Time: '+ formatTime(this.x,true);
          }
        },
        series: [{
          type: "line",
          lineWidth: 1,
          color: "red",
          data: hrSerie
        }]
      }

      scope.speedChart = {
        title: "Speed",
        credits: {
          href: "http://teamdatalog.com",
          text: "teamdatalog.com"
        },
        legend: {
          enabled: false
        },
        xAxis: {
          crosshair: true,
          title: {
            enabled: true,
            text: 'Duration'
          },
          type: 'linear',
          labels: {
            formatter: function() {
              return formatTime(this.value)
            }
          }

        },
        yAxis: {
          labels: {
            formatter: function () {
              return speed(this.value)
            }
          },
          title: {
            text: 'Speed [' + speedUnit + ']'
          }
        },
        tooltip: {
          formatter: function() {
            return '<b>Speed: '+speed(this.y)+' ' + speedLabel() + '</b><br>'  + 'Time: '+ formatTime(this.x,true);
          }
        },
        series: [{
          data: speedSerie
        }]
      }


      if (wind) {

        scope.windChart = {
          title: "TWS",
          credits: {
            href: "http://teamdatalog.com",
            text: "teamdatalog.com"
          },
          legend: {
            enabled: false
          },
          xAxis: {
            crosshair: true,
            title: {
              enabled: true,
              text: 'Duration'
            },
            type: 'linear',
            labels: {
              formatter: function () {
                return formatTime(this.value)
              }
            }

          },
          yAxis: {
            labels: {
              formatter: function () {
                return speed(this.value)
              }
            },
            title: {
              text: 'Speed [kn]'
            }
          },
          tooltip: {
            formatter: function () {
              return '<b>Speed: ' + speed(this.y) + ' kn</b><br>' + 'Time: ' + formatTime(this.x, true);
            }
          },
          series: [{
            data: [[0, 0]].concat(wind.tws).concat([[hrSerie[hrSerie.length - 1][0], 0]])
          }]
        }

        scope.windDirChart = {
          title: "TWD",
          credits: {
            href: "http://teamdatalog.com",
            text: "teamdatalog.com"
          },
          legend: {
            enabled: false
          },
          xAxis: {
            crosshair: true,
            title: {
              enabled: true,
              text: 'Duration'
            },
            type: 'linear',
            labels: {
              formatter: function () {
                return formatTime(this.value)
              }
            }

          },
          yAxis: {
            labels: {
              formatter: function () {
                return speed(this.value)
              }
            },
            title: {
              text: 'Direction °'
            }
          },
          tooltip: {
            formatter: function () {
              return '<b>Direction: ' + speed(this.y) + '° </b><br>' + 'Time: ' + formatTime(this.x, true);
            }
          },
          series: [{
            data: [[0, 0]].concat(wind.twd).concat([[hrSerie[hrSerie.length - 1][0], 0]])
          }]
        }
      } else {
        scope.windChart = null;
        scope.windDirChart = null
      }

    }

    function link(scope) {

      scope.tss = null;


      scope.gpx = function() {
        
        var headers = angular.copy(TDLAuth.header())
        

        $http({
          url: Conf.api + '/formData/garminGPX/' + scope.formDataId,
          headers: headers,
          responseType: 'arraybuffer'
        }).then(function (data, status, headers, config) {
          var file = new Blob([ data.data ], {
              type : 'application/gpx+xml'
          });
          //trick to download store a file having its URL
          var fileURL = URL.createObjectURL(file);
          var a         = document.createElement('a');
          a.href        = fileURL;
          a.target      = '_blank';
          a.download    = scope.formDataId + '.gpx';
          document.body.appendChild(a);
          a.click();
        },function (data, status, headers, config) {
          //upload failed
        });
  
      }

      function loadTss(athlete,data) {
        if(athlete.addon) {
          const ias = athlete.addon.ias;
          const hr = data.summary.averageHeartRateInBeatsPerMinute;
          const t = data.summary.durationInSeconds
          scope.tss = Math.round(((t*hr*hr)/(ias*ias*3600.0))*100.0*100)/100
        }
      }

      let graphSetPoint = null
      scope.graphSetPoint = function(callback) {
        graphSetPoint = callback
      }

      let mapSetPoint = null
      scope.mapSetPoint = function(callback) {
        mapSetPoint = callback
      }

      scope.changedPoint = function(time) {
        if(graphSetPoint) graphSetPoint(time)
        if(mapSetPoint) mapSetPoint(time)
      }

      Athlete.get({
        athleteId: scope.athleteId
      }).$promise.then(
        //success
        (value) => {
          $http.get(Conf.api + '/formData/garmin/'+scope.formDataId, {
            headers: TDLAuth.header()
          }).then(function (response, status, headers, config) {
            console.log(value)
            const teams = Menu.teams.filter(t => t.athletesIds.includes(scope.athleteId))
            const all = teams.map(async e => {
                const res = await $http.get(Conf.api + '/windger/get/' + (scope.formData.start.valueOf()) + '/' + (scope.formData.end.valueOf()) + "/" + e._id, {
                  headers: TDLAuth.header()
                })
                return res.data
            });
            Promise.all(all).then(allWindData => {
              loadTss(value,response.data)
              loadChart(response.data,allWindData.find(x => x != null),scope,value)
            });
            
            
            
          }, function (err) {
            console.log(err);
          });


        },
        //error
        function (error) {
          console.log(error);
        }
      )



    }

  }]
);
