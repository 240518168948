'use strict';

import _ from "underscore";


angular.module('tdl.controllers').controller('AnalyticsController', ['$scope', '$rootScope', 'Menu', 'Conf','TDLAuth', 'FormAnalisisStructure', 'FormData', 'Analisis', 'Athlete', '$http', 'FileSaver','$routeParams','$q','$cookies','ExportService','DataProcessor',
  function AnalyticsController($scope, $rootScope, Menu, Conf, TDLAuth, FormAnalisisStructure, FormData, Analisis,Athlete,$http,FileSaver,$routeParams,$q,$cookies,ExportService,DataProcessor) {
    Menu.section = 'analyze';

    $scope.menu = Menu;





    function buildMenus() {
      if($scope.analize && $scope.analize.multiAthlete) {
        if(Menu.teamScope()) {
          if(Menu.multipleMode) {
            ///TODO
          } else {
            $scope.menus = buildMultipleAthleteMenu(Menu.team.athletesIds)
            _.each($scope.menus,getAthlete);
          }
        } else {
          if(Menu.multipleMode) {
            $scope.menus = buildMultipleAthleteMenu(Menu.selectedAthletes);
            _.each($scope.menus,getAthlete);
          } else {
            $scope.menus = buildMultipleAthleteMenu([Menu.athlete._id]);
            _.each($scope.menus,getAthlete);
          }
        }
      } else {

        $scope.menus = [{
          teamScope: Menu.teamScope(),
          team: Menu.team,
          selectedTeams: Menu.selectedTeams,
          athlete: Menu.athlete,
          athleteOnly: Menu.athleteOnly,
          selectedAthletes: Menu.selectedAthletes,
          multipleMode: Menu.multipleMode
        }];


      }

      $scope.loadPage($scope.page)

    }

    $scope.changeRange = function(start,end) {
      $scope.myDateRange.startDate = start;
      $scope.myDateRange.endDate = end;
      buildMenus();
    }

    Menu.onChange = function() {
      if(Menu.section === 'analyze')
        buildMenus()
    }

    $scope.downloadCSV = function() {

      var teamOrAthlete = ""
        if(Menu.teamScope) {
          if(Menu.multipleMode)
            teamOrAthlete = "&teamId=" + Menu.selectedTeams.join(",");
          else
            teamOrAthlete = "&teamId=" + Menu.team._id;
        } else {
          if(Menu.multipleMode)
            teamOrAthlete = "&athleteId=" + Menu.selectedAthletes.join(",");
          else
            teamOrAthlete = "&athleteId=" + Menu.athlete._id;
        }
        var exportCsvUrl = Conf.api + "/export/analisis/" + $scope.analize._id + "?start=" + $scope.myDateRange.startDate.valueOf() + "&end=" + $scope.myDateRange.endDate.valueOf()+teamOrAthlete;

      var headers = angular.copy(TDLAuth.header())
      headers['Content-type'] = 'text/plain';

      $http({
        url: exportCsvUrl,
        method: "GET",
        headers: headers,
        responseType: 'blob'
      }).then(function (response) {
        var blob = new Blob([response.data], {
          type: "text/csv"
        });
        var name = "export.csv";
        if(response.headers("Content-Disposition")) {
          name = response.headers("Content-Disposition").split("=")[1];
        }
        FileSaver.saveAs(blob, name);
      }, function (data, status, headers, config) {
        //upload failed
      });
    }

    function getAthlete(menu) {
       Athlete.get({athleteId: menu.athlete._id}).$promise.then(function (ok) {
          menu.athlete = ok;
        });
    }

    function buildMultipleAthleteMenu(athletes) {
       return _.map(athletes, function(athlete) { return {
          teamScope: false,
          athlete: {_id: athlete},
          multipleMode: false,
          athleteOnly: Menu.athleteOnly
        }
      });
    }


    $scope.analisis = [];

    $scope.analize = null
    $scope.page = null


    $scope.loadPage = function(page) {
      if(page) {
        $scope.page = page;


        ExportService.load(page.export, $scope.myDateRange, $scope.menu, $scope.analisisFilters).then(function (exp) {
          ExportService.load(page.export, $scope.myDateRange, $scope.menu, {}).then(function (notFiltered) {
            page.rawData = notFiltered.data;

            $q.all(_.map(_.filter(page.elements, function (e) {
              return e != null
            }), function (element) {
              return FormAnalisisStructure.get({formAnalisisId: element.formAnalisis}).$promise.then(function (fa) {
                element.formAnalisisMetadata = fa;
                element.data = DataProcessor.eval(exp.data, fa.dataProcessing)
                return element
              });
            })).then(function (elements) {
              page.elements = elements;
              $scope.analize = page;
            })

          })

        })
      }

    }


    var start = moment.utc({day: 1 }).subtract(3, 'months');
    var end = moment.utc({day: 1 }).add(9, 'months');
    if($routeParams['start']) start = moment.utc(parseInt($routeParams['start']));
    if($routeParams['end']) end = moment.utc(parseInt($routeParams['end']));



    $scope.myDateRange = {
      startDate:start,
      endDate: end
    }



    function onLoad() {
      Analisis.query().$promise.then(function (ok) {

        var page = 0;
        if($routeParams['page']) page = $routeParams['page'];
        $scope.analisis = _.sortBy(ok, 'order');
        $scope.page = $scope.analisis[page];

        buildMenus();
      });
    }



    $scope.tags = {};

    $scope.fromTagsToData = function (id) {
      $scope.analisisFilters[id] = _.uniq(_.map($scope.tags[id], function (v) {
        return v
      })).join(',')

    }

    $scope.addTag = function(id,tag) {
      if($scope.analisisFilters[id] && $scope.analisisFilters[id].length > 0) {
        $scope.analisisFilters[id] = $scope.analisisFilters[id] + "," + tag;
      } else {
        $scope.analisisFilters[id] = tag;
      }
      $scope.fromDataToTags(id)($scope.analisisFilters[id]);
      $scope.fromTagsToData(id);
    }

    $scope.tagSuggestions = function (field,analyze) {
      let result = []
      if(field.extractor && field.extractor.trim().length > 0) {
        result = DataProcessor.eval(analyze.rawData, field.extractor)
      } else if (field.values) {
        result =  field.values;
      }
      return result;
    }

    $scope.fromDataToTags = function (id) {
      return (tags) => {
        if(!$scope.tags[id]) $scope.tags[id] = []
        $scope.tags[id].length = 0;
        if (tags) {
          _.each(_.uniq(tags.split(",")), function (element) {
            $scope.tags[id].push(element.trim());
          });
        }
      }
    }

    function afterMenuselection() {
      $scope.analisisFilters = {};
      $scope.$watch('analisisFilters',buildMenus,true)
      onLoad();
    }

    //:teamId/:start/:end/:page
    if($routeParams['teamId']) {
      Menu.selectTeam($routeParams['teamId']).then(x => afterMenuselection());
    } else if($routeParams['athleteId']) {
      Menu.selectAthlete($routeParams['athleteId']).then(x => afterMenuselection());
    }
    else afterMenuselection()




  }
 ]);
