angular.module('tdl.directives').directive('autoFocus', ['$timeout', function ($timeout) {
  return {
    restrict: 'A',
    link: function (_scope, _element) {
      $timeout(function () {
        _element[0].focus();
      }, 0);
    }
  };
}]);
