/*global define*/
'use strict';
/**
 * Directive that places focus on the element it is applied to when the expression it binds to evaluates to true.
 */

angular.module('tdl.directives').directive('tdlMainPanel', ['$window', '$rootScope', '$timeout',
  function ($window, $rootScope, $timeout) {
    return {
      restrict: 'A',
      link: function (scope, element, attrs) {
        angular.element($window).bind('keydown', function (e) {
          $rootScope.keyCode = e.keyCode;
        });

        angular.element($window).bind('keyup', function (e) {
          $rootScope.keyCode = 0;
        });
      }
    };
  }
]);