'use strict';

angular.module('tdl.directives').directive('tdlImage', ['$uibModal','Upload','Conf','TDLAuth','$http',
  function ($uibModal,Upload,Conf,TDLAuth,$http) {

    var directiveDefinitionObject = {
      restrict: 'E', // only activate on element attribute
      scope: {
        ngModel: '=',
        ngChange: '&',
        readOnly: '=',
        small: '='
      },
      link: link,
      templateUrl: 'views/directives/tdlImage.html'
    }

    return directiveDefinitionObject;

    function link(scope, elem, attrs, $compile) {



      scope.openModal = function() {

        $uibModal.open({
          templateUrl: 'views/directives/tdlImagePopup.html',
          scope: scope,
          size: 'lg'
        });
      };

      scope.readOnlyMode = scope.readOnly || false;
      scope.smallMode = scope.small || false;

      scope.img = ""

      if(scope.ngModel && scope.ngModel.length > 0) {
        const url = Conf.api + '/media/' + scope.ngModel;
        $http.get(url, {
          headers: TDLAuth.header(),
          responseType: 'blob'
        }).then(function (response, status, headers, config) {
          var reader = new FileReader();
          reader.onloadend = () => {
            scope.img = reader.result
            console.log(scope.img)
            scope.$apply()
          }

          reader.readAsDataURL(response.data)

        }, function (err) {
          console.log(err);

        });
      }

      scope.import = function(file) {

        Upload.upload({
          url: Conf.api + '/media',
          data: {file: file},
          headers: TDLAuth.header()
        }).then(function (resp) {
          var response = resp.data
          if(resp.data.errors) {
            response = _.map(resp.data.errors,function (error) {
              return "Error: " + error.errorMessage + "\nin line: " + error.errorLine + "\n\n";
            }).join("\n");
          }
          scope.ngModel = response;
          if(scope.ngChange) {
            scope.ngChange();
          }
          scope.img = Conf.api + '/media/' + response;
        }, function (resp) {
          scope.message = 'Error status: ' + resp.status;
        }, function (evt) {
          var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
          scope.message = 'progress: ' + progressPercentage + '% ' + evt.config.data.file.name;
        });
      }

    }
  }]);
