'use strict';

angular.module('tdl.controllers').controller('WizardsController', ['$scope', '$routeParams','Menu','Wizard',
  function WizardsController($scope, $routeParams, Menu, Wizard) {
    Menu.section = "form";
    $scope.menu = Menu;

    var wizardId = $routeParams['wizardId'];


    $scope.currentStep = {};

    $scope.control = {};
    $scope.ended = false;

    $scope.loading = false;

    $scope.show = function(step) {
      return step == $scope.currentStep
    };

    $scope.same = function() {

      var test = $scope.control.submitFormData()
      test.$promise.then(function () {
        $scope.loading = false;
      });
      $scope.loading = true;
    };

    Wizard.get({
      wizardId: wizardId
    }).$promise.then( function(w) {
      $scope.wizard = w;
      if(w.steps) { $scope.currentStep = w.steps[0]; }
    });

    $scope.next = function(step,save) {
      if(save) {
        $scope.control.submitFormData();
      }
      var index = $scope.wizard.steps.indexOf(step);
      if($scope.wizard.steps[index+1]) {
        $scope.currentStep = $scope.wizard.steps[index+1];
      } else {
        $scope.currentStep = {};
        $scope.ended = true;
      }
    }


  }
]);
