/*global define*/
'use strict';

import _ from "underscore";

/**
 * Services that persists and retrieves TODOs from localStorage.
 */

const TDLAuthProvider = angular.module('tdl.services').factory('TDLAuth', ['$http', 'Conf', 'UserConstants','$location','LoginStorage','$route',
  function ($http, Conf, UserConstants,$location,LoginStorage,$route) {
    var localStorageKey = 'Auth.user';
    var localStorageKeyToken = 'Auth.token';

    var accessLevels = UserConstants.accessLevels,
      userRoles = UserConstants.userRoles;

    var currentUser = {
      username: '',
      password: '',
      role: userRoles.public
    };
    if(LoginStorage.get(localStorageKey)) {
      currentUser = LoginStorage.get(localStorageKey);
    }



    function changeUser(user) {
      LoginStorage.remove(localStorageKey);
      _.each(Object.keys(currentUser), function(prop) {
        delete currentUser[prop];
      });

      currentUser = _.extend(currentUser, user);

      LoginStorage.set(localStorageKey,currentUser);
    };

    function header() {
      var token = LoginStorage.get(localStorageKeyToken);
      var header = {
        "X-Auth-Token": token
      };

      return header;
    }

    function loginWithToken(token) {
      LoginStorage.remove(localStorageKeyToken);
      LoginStorage.set(localStorageKeyToken, token);
      return loadUser();
    }

    function loadUser() {
      return $http.get(Conf.api + '/me',{headers: header()}).then(function (user) {
        changeUser(user.data);
        return user.data;
      });
    }

    function checkAdmin() {
      if(!access($route.current.$$route)) {
        $location.path("/planning");
      }
    }

    function access(route) {
      return route.access.includes(currentUser.role.title) || route.access.includes(accessLevels.public);
    }


    return {
      isLoggedIn: function (user) {
        if (user === undefined) {
          user = currentUser;
        }

        return user.role.title == userRoles.user.title || user.role.title == userRoles.admin.title;
      },
      checkAdmin:checkAdmin,
      loginWithToken: loginWithToken,
      reloadUser: loadUser,
      login: function (user) {
        var loginRequest = {
          identifier: user.username,
          password: user.password
        };
        return $http.post(Conf.api + '/login', loginRequest).then(function (data) {
          return loginWithToken(data.data.token)
        });
      },
      logout: function () {
        $http.get(Conf.api + '/logout',{headers: header()});
        LoginStorage.remove(localStorageKeyToken);
        LoginStorage.remove(localStorageKey);
        _.extend(currentUser, {
          _id: '',
          username: '',
          role: userRoles.public,
          password: '',
          realName: '',
          teamsIds: []
        });
      },
      access: access,
      actAs: function(username) {
        if(this.isAdmin()) {
          return $http.get(Conf.api + '/actAs/'+username, {
            headers: header()
          }).then(function (user) {
            var result = user.data.token;
            loginWithToken(result).then(function() {
              $location.path("/planning");
              window.location.reload(true);
            });
          });
        }
      },
      accessLevels: accessLevels,
      userRoles: userRoles,
      user: currentUser,
      getUsername: getUsername,
      header: header,
      isAdmin:function() {
        return currentUser.role.title === "admin"
      }
    };

    function getUsername() {
      return currentUser.username;
    }


  }
]);

export default TDLAuthProvider
