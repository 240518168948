'use strict';

angular.module('tdl.controllers').controller('FormCreatorController', ['$scope',
  '$rootScope', 'Form', 'FormHelper', '$location', 'AlertsManager', 'User', 'Team', 'Menu','TDLAuth','$routeParams',
  function FormsController($scope, $rootScope, Form, FormHelper, $location, AlertsManager, User, Team, Menu,TDLAuth,$routeParams) {
    TDLAuth.checkAdmin();
    Menu.section = 'form';
    $scope.menu = Menu;

    var setListener = false,
      eventLoaded = false;

    $scope.list = {
      'forms': [],
      'teams': [],
      'users': []
    };

    $scope.fieldTypes = FormHelper.getFieldTypes();
    $scope.form = FormHelper.getDefaultForm();

    $scope.draft = false;

    $scope.setType = setType;
    $scope.addField = addField;
    $scope.removeField = removeField;
    $scope.addOption = addOption;
    $scope.removeOption = removeOption;
    $scope.addShowIf = addShowIf;
    $scope.removeShowIf = removeShowIf;

    $scope.saveForm = saveForm;
    $scope.deleteForm = deleteForm;


    $scope.goLive = goLive;
    $scope.goPublicLive = goPublicLive;
    $scope.report = report;

    $scope.TDLconf = $rootScope.TDLconf;
    $scope.debug = false;

    onStart();


    $scope.toggleDebug = function () {
      $scope.debug = !$scope.debug;
    }

    $scope.swap = function (theArray, indexA, indexB) {
      var temp = theArray[indexA];
      theArray[indexA] = theArray[indexB];
      theArray[indexB] = temp;
    }


    function onStart() {
      if($routeParams['id'] != 'new') {
        $scope.form = Form.get({'formId': $routeParams['id']});
      }
      $scope.list.teams = Team.query();
      $scope.list.users = User.query();

    }

    function setType(field, t) {
      field.typ = t;
    }

    function addField() {
      $scope.form.fields.push(FormHelper.getDefaultField());
    }

    function removeField(field) {
      $scope.form.fields.splice($scope.form.fields.indexOf(
        field), 1);
    }

    function addOption(field) {
      field.options.push(FormHelper.getDefaultOption());
    }

    function removeOption(field, option) {
      field.options.splice(field.options.indexOf(option), 1);
    }

    function addShowIf(field) {
      if(!field.showIf) {
        field.showIf = [];
      }
      field.showIf.push(FormHelper.getDefaultShowIf());
    }

    function removeShowIf(field, showIf) {
      field.showIf.splice(field.showIf.indexOf(showIf), 1);
    }

    function saveForm() {

      var formObject = angular.copy($scope.form);

      formObject.fields = formObject.fields.filter(f => f.id !== 'date-index-field')

      if (formObject.properties.date) {
        formObject.fields.unshift(_.extend(FormHelper.getDefaultField(), {
          'id': 'date-index-field',
          'name': 'Date',
          'typ': 'Date',
          'options': []
        }));
      }


      if ($scope.form._id) {
        Form.update(formObject).$promise.then(
          function (v) {
            AlertsManager.addAlert({
              type: 'success',
              message: 'Form is updated correctly.'
            });
            $location.path("/admin/forms");
          },
          function (e) {
            AlertsManager.addAlert({
              type: 'danger',
              message: 'There an issue with the update.'
            });
            console.log(e)
          }
        )
      } else {
        Form.post(formObject).$promise.then(
          function (v) {
            AlertsManager.addAlert({
              type: 'success',
              message: 'Form is saved correctly.'
            });
            $location.path("/admin/forms");
          },
          function (e) {
            AlertsManager.addAlert({
              type: 'danger',
              message: 'There an issue with submission.'
            });
            console.log(e)
          }
        )
      }
    }



    function goLive() {
      $location.path('/formData/' + $scope.form._id);
    }

    function goPublicLive() {
      $location.path('/public/form/' + $scope.form._id);
    }

    function report() {
      $location.path('/formReport/' + $scope.form._id);
    }

    function deleteForm() {
      var confirmDelete = confirm(
        'Are you sure? This operation is not reversible');
      if (confirmDelete) {
        Form.remove({
          formId: $scope.form._id
        }).$promise.then(
          //success
          function (value) {
            AlertsManager.addAlert({
              type: 'success',
              message: 'Form is removed correctly.'
            });
            $scope.form = FormHelper.getDefaultForm();

            loadForms();
          },
          //error
          function (error) {
            AlertsManager.addAlert({
              type: 'danger',
              message: 'I can\'t remove the form...'
            });
            console.log(error);
          }

        )
      }
    }



        }
        ]);
