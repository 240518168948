'use strict';

angular.module('tdl.directives').directive('tdlCalendarVertical', ['Menu',
  function (Menu) {
    var throttleTime = 400;

    var directiveDefinitionObject = {
      restrict: 'E', // only activate on element attribute
      scope: {
        events: '='
      },
      link: link,
      templateUrl: 'views/elements/calendarVertical.html'
    }

    return directiveDefinitionObject;

    function link(scope, element, attrs) {


      scope.opts = { table: null};

      scope.$watch('events.eventSource',load,true);

      //load();

      function load() {
        scope.opts.table =  null;
        setTimeout(() => _load(),0);
      }

      function _load() {
        const table = {
          data: [],
          settings: null
        }


        var interval = [moment(scope.events.period.startDate), moment(scope.events.period.endDate)];
        var currentDay = angular.copy(interval[0]);
        var dayCounter = 1;
        var sundays = [];
        var monthCounter = 1;
        var mergeCells = [];
        var teamBorders = [];
        var days = [];

        while (currentDay.isSameOrBefore(interval[1])) {
          if (currentDay.isoWeekday() == 7) {
            sundays.push(dayCounter);
          }

          var day = {
            "dayOfMonth": currentDay.date(),
            "dayOfWeek": currentDay.format("dd")
          };

          if (dayCounter == 1 || currentDay.date() == 1) {
            mergeCells.push({row: dayCounter - monthCounter, rowspan: monthCounter, col: 0, colspan: 1});
            monthCounter = 0;
            day["month"] = {rotated: currentDay.format("MMM YYYY")};
          }

          days.push(day);

          dayCounter++;
          monthCounter++;
          currentDay.add(1, 'days');
        }

        mergeCells.push({row: dayCounter - monthCounter, rowspan: monthCounter, col: 0, colspan: 1});


        var base = [
          {
            "month": {text: "Date", color: "#c2ffff", textColor:"#333"}
          }
        ];

        table.data = base.concat(days);

        var colCounter = 3;
        var teamEventsColumns = [];
        _.each(scope.events.eventSource,function (teamEvents) {


          function teamColumn() {
            return {
              "events": []
            }
          }

          function eventObjFactory(e) {


            return {
              range: moment().range(e.start,e.end),
              start:moment(e.start),
              text: e.title,
              color: e.color,
              textColor: e.textColor,
              className: e.className,
              formId: e.form._id
            }
          }

          var teamEventsColumn = [teamColumn()];

          _.each(teamEvents.events,function(event) {
            var eventObj = eventObjFactory(event);
            if(eventObj.formId != "55240dfb1500002b054abb0c") {
              var added = false;
              _.each(teamEventsColumn, function (col) {
                if (!added && _.every(col.events, function (e) {
                    return !eventObj.range.overlaps(e.range)
                  })) {
                  col.events.push(eventObj);
                  added = true;
                }
              });
              if (!added) {
                var column = teamColumn();
                column.events.push(eventObj);
                teamEventsColumn.push(column);
              }
            }
          });

          teamEventsColumns = teamEventsColumns.concat(_.map(teamEventsColumn,function(c,i) { return {
            data: teamEvents.team._id + "-" + i,
            editor: false,
          }}));

          //header
          var teamHeader = {};
          teamHeader[teamEvents.team._id+"-0"] = {text: teamEvents.team.name, color: "#fffd98", textColor:"#333"};

          table.data[0] = _.extend(table.data[0],teamHeader);

          mergeCells.push({row:0,rowspan:1,col:colCounter,colspan:teamEventsColumn.length});

          teamBorders.push({
            range: {
              from: { row: 0, col: colCounter+teamEventsColumn.length-1},
              to: { row: table.data.length + 1, col: colCounter+teamEventsColumn.length-1}
            },
            right: { width: 2, color: '#333' }
          });

          //events
          _.each(teamEventsColumn,function(col,i) {
            _.each(col.events,function(e) {
                var eventObj = {};
                eventObj[teamEvents.team._id + "-" + i] = e;


                var row = Math.floor(moment.duration(e.start.valueOf() - interval[0].valueOf()).asDays()) + 1;
                if (row < 1) row = 1;

                table.data[row] = _.extend(table.data[row], eventObj);
                mergeCells.push({
                  col: colCounter + i,
                  colspan: 1,
                  row: row,
                  rowspan: Math.round(e.range.valueOf() / 24 / 60 / 60 / 1000)
                })

            });
          });


          colCounter = colCounter+teamEventsColumn.length;

        });



        var columns = [
          {
            data: "month",
            editor: false
          },
          {
            data: "dayOfMonth",
            editor: false,
          },
          {
            data: "dayOfWeek",
            editor: false,
          }
        ].concat(teamEventsColumns);

        var borders = teamBorders.concat([
          {
            range: {
              from: {
                row: 0,
                col: 2
              },
              to: {
                row: table.data.length + 1,
                col: 2
              }
            },
            right: {
              width: 2,
              color: '#333'
            }
          },
          {
            range: {
              from: {
                row: 0,
                col: 0
              },
              to: {
                row: 0,
                col: columns.length-1
              }
            },
            bottom: {
              width: 2,
              color: '#333'
            }
          }
        ]);


          table.settings = {
            colHeaders: false,
            fixedColumnsLeft: 3,
            height: window.innerHeight - 200,
            fixedRowsTop: 1,
            mergeCells: mergeCells.concat([
              {row: 0, col: 0, rowspan: 1, colspan: 3}
            ]),
            columns: angular.copy(columns),
            //customBorders: angular.copy(borders),
            renderer: function (instance, td, row, col, prop, value, cellProperties) {
              td.style.textAlign = 'center';
              if (sundays.includes(row)) {
                td.style.background = '#eee';
              }
              if (value && value.text) {
                Handsontable.renderers.TextRenderer.apply(this, [instance, td, row, col, prop, value.text, cellProperties]);
                td.className = value.className;
                if(value.textColor) {
                  td.style.color = value.textColor;
                } else td.style.color = 'white';
                if(value.color) {
                  td.style.background = value.color;
                }
              } else if (value && value.rotated) {
                td.style.background = '#eee';
                td.innerHTML = "<div style='padding-top: 50px;'><div class='vertical-text'>" + value.rotated + "</div></div>";
                return td;
              } else Handsontable.renderers.TextRenderer.apply(this, arguments)
            }
          };


        scope.opts.table = table
        setTimeout(() => scope.$apply(), 0)

      }



    }




  }
]);
