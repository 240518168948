'use strict';

angular.module('tdl.directives').directive('filterCreator', ['Form','FormGroup','$q',
  function (Form,FormGroup,$q) {


    var directiveDefinitionObject = {
      restrict: 'E', // only activate on element attribute
      scope: {
        model: '='
      },
      link: link,
      templateUrl: 'views/directives/filterCreator.html'
    }

    return directiveDefinitionObject;

    function link(scope, elem) {

      scope.show = false;

      scope.forms = [];
      scope.fields = [];

      scope.showValue = true;

      $q.all([Form.query().$promise,FormGroup.query().$promise]).then(function(f) {
        scope.forms =  f[0].concat(f[1]);
      });

      scope.variable = {
        form:{},
        field:{},
        subfield:{},
        operator:"none",
        value:"",
      };

      scope.options = [];

      scope.operators = function() {
        var typ = "";
        if(scope.variable.field.typ) typ = scope.variable.field.typ;
        switch (typ)  {
          default: return ["none", "$gt", "$gte", "$lt", "$lte", "$in", "$regex", "$and", "$or"];
        }
      };



      function hasSecondaryValue() {
        if(scope.variable.field) {
          if(scope.variable.field.options && scope.variable.field.typ !== "Tags") {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      }

      scope.$watch('variable.form',function(){
        fieldsWithDefault();
      }, true);

      scope.$watch('variable.field',function(){

        scope.variable.subfield={};

        if(scope.variable.field.typ === "Tags" && scope.variable.operator === "none") {
          scope.variable.operator = "$regex"
        }

        if(hasSecondaryValue()) {
          scope.showValue = false;
        } else {
          scope.showValue = true;
        }

        scope.options =  [{name: 'none'}].concat(scope.variable.field.options)

      }, true);



      scope.$watch('variable',function() {

        var isTwoLevel = scope.variable.field.typ === "Evaluation table";
        var isTags = scope.variable.field.typ === "Tags";

        var field = "";
        if(scope.variable.field.base) {
          field = scope.variable.field.id
        } else {
          field = "data."+scope.variable.field.id
        }

        var base = "{\""+field;

        if(isTwoLevel && scope.variable.subfield.id) {
          base = base + "." + scope.variable.subfield.id
        }

        base = base + "\": ";



        if(scope.variable.operator !== "none") {
          base = base + "{\"" + scope.variable.operator + "\": ";
        }

        if(scope.variable.subfield.id && !isTwoLevel) {
          if(isTags) {
            base = base + "\"" + scope.variable.subfield.name + "\"";
          } else {
            base = base + "\"" + scope.variable.subfield.id + "\"";
          }
        } else {
          if(isNaN(+scope.variable.value) && scope.variable.value !== "true" && scope.variable.value !== "false" ) {
            base = base + " \"" + scope.variable.value + "\"";
          } else {
            base = base + " " + scope.variable.value
          }
        }
        base = base + "}"

        if(scope.variable.operator !== "none") {
          base = base + "}"
        }

        scope.model = base;

      }, true);

      function resetFields() {
        scope.fields = [];
        scope.variable.field = {};
        scope.variable.subfield={};
        scope.variable.operator="none";
      }

      function fieldsWithDefault() {
        resetFields();

        var form = scope.variable.form;


        if(form && form._id) {
          Form.fields({form: form._id}).$promise.then(function (fields) {
            scope.fields = _.filter(fields,function(f) { return !f.calculated});
          });
        }
      }



    }




  }
]);
