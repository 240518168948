'use strict';



angular.module('tdl.services.storage').factory('Wizard', ['$resource', 'Conf', 'TDLAuth',
  function ($resource, Conf, TDLAuth) {
    return $resource(Conf.api + '/wizards/:wizardId', {}, {
      get: {
        method: 'GET',
        params: {
          wizardId: ''
        },
        isArray: false,
        headers: TDLAuth.header(),
        cache: false
      },
      query: {
        method: 'GET',
        isArray: true,
        headers: TDLAuth.header()
      }
    });
  }]);
