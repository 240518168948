/*global define*/
'use strict';
/**
 * Directive that places focus on the element it is applied to when the expression it binds to evaluates to true.
 */

angular.module('tdl.directives').directive('tdlFormReport', ['$compile', '$sce', '$http', 'Athlete', '$filter','FormData','$rootScope',
  function ($compile, $sce, $http, Athlete, $filter, FormData,$rootScope) {




    var linker = function (scope, element, attrs) {


      scope.getContentUrl = function () {
        return 'views/forms/' + $filter('slugify')(scope.field.typ) + '-report.html';
      }




      switch (scope.field.typ) {

      case 'Evaluation table':
        var data = _.map(scope.field.options, function (opt) {

          let y = 0;
          try{
            const parsed = parseInt(scope.data[opt.id])
            if(parsed > 0) y = parsed
          } catch (e) {
            console.warn("Unable to parse " + scope.data[opt.id])
          }

          return {
            y:  y,
            name: opt.name
          }
        });


        scope.chartOptions = {
          title: "",
          chart: {
            polar: true
          },
          credits: {
            href: "http://teamdatalog.com",
            text: "teamdatalog.com"
          },
          legend: {
            enabled: false
          },
          pane: {
            startAngle: 0,
            endAngle: 360
          },
          xAxis: {
            type: "category"
          },
          yAxis: {
            min: 0,
            max: $rootScope.TDLconf.ui.form.evaluationMax
          },
          series: [{
            type: 'area',
            name: scope.field.name,
            data: data
          }]
        }
        break;


      case 'Youtube Video':
        var newUrl = 'https://www.youtube.com/embed/' + scope.data
        scope.data = $sce.trustAsResourceUrl(newUrl);
        break;



      case 'Image URL':
        scope.data = $sce.trustAsResourceUrl(scope.data);
        break;

      case 'DateTime':
        scope.data = scope.data;
        break;

      case 'Select':
      case 'Radio':
        const d = scope.field.options.find(x => x.id === scope.data);
        scope.data = d ? d.name : ""
        break;
      case 'Checkbox':
        scope.data = Object.entries(scope.data).flatMap( kv => {
          if(kv[1]) {
            const d = scope.field.options.find(x => x.id === kv[0]);
            return d ? d.name : ""
          } else {
            return [];
          }
        })
        break;

      }


    }

    return {
      restrict: 'E',
      link: linker,
      template: '<div ng-include="getContentUrl()"></div>',
      scope: {
        field: '=',
        data: '='
      }
    }
}]);
