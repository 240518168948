'use strict';
angular.module('tdl.controllers').controller('FormGroupController', ['$scope','Menu','FormGroup','Form','AlertsManager','TDLAuth',
  function FormGroupController($scope,Menu,FormGroup,Form,AlertsManager,TDLAuth) {
    TDLAuth.checkAdmin();
    Menu.section = 'backend';
    $scope.menu = Menu;

    $scope.forms = Form.query();
    $scope.formGroups = FormGroup.query();
    $scope.formGroup = {};

    $scope.setCurrent = function(fg) {
      $scope.formGroup = fg;
    };

    $scope.newFormGroup = function() {
      $scope.formGroup = {};
    };

    $scope.saveFormGroup = function() {
      if ($scope.formGroup._id) {
        var fg = $.extend(true, {}, $scope.formGroup);

        FormGroup.update({}, fg,
          // success
          function (fgResult) {
            AlertsManager.addAlert({
              type: 'success',
              message: 'Form group has been changed successfully!'
            });
            $scope.formGroup = fgResult;
            $scope.formGroups = FormGroup.query();
          },
          function (err) {
            console.log(err);
          });
      } else {
        FormGroup.post({}, $scope.formGroup,
          // success
          function (fgResult) {
            AlertsManager.addAlert({
              type: 'success',
              message: 'User has been added successfully!'
            });
            $scope.formGroup = fgResult;
            $scope.formGroups = FormGroup.query();
          },
          function (err) {
            console.log(err);
          });
      }
    }

    $scope.deleteFormGroup = function() {
      var r = confirm("Delete form group: " +$scope.formGroup.name + "?");
      if(r) {
        FormGroup.remove({
          formGroupId: $scope.formGroup._id
        }, function () {
          AlertsManager.addAlert({
            type: 'success',
            message: 'Form group removed!'
          });
          $scope.formGroup = {};
          $scope.formGroups = FormGroup.query();
        });
      }
    }




  }
]);
