'use strict';

/**
 * Services that persists and retrieves TODOs from localStorage.
 */

angular.module('tdl.services.storage').factory('FormGroup', ['$resource', 'Conf', 'TDLAuth','StorageHelper',
 function ($resource, Conf, TDLAuth,StorageHelper) {

    var transRequestStack = StorageHelper.addTransRequest([StorageHelper.copyObjectTrans, StorageHelper.stripIdTrans]);

    return $resource(Conf.api + '/formGroup/:formGroupId', {}, {
      get: {
        method: 'GET',
        params: {
          formGroupId: ''
        },
        isArray: false,
        headers: TDLAuth.header(),
        cache: false
      },
      query: {
          method: 'GET',
          isArray: true,
          headers: TDLAuth.header()
      },
      common: {
        method: 'GET',
        params: {
          formGroupId: ''
        },
        isArray: true,
        headers: TDLAuth.header(),
        url: Conf.api + '/formGroup/:formGroupId/common'
      },
      post: {
        method: 'POST',
        isArray: false,
        headers: TDLAuth.header(),
        transformRequest: transRequestStack
      },
      update: {
        method: 'PUT',
        headers: TDLAuth.header(),
        transformRequest: transRequestStack
      },
      remove: {
        method: 'DELETE',
        headers: TDLAuth.header()
      }
    });
 }]);
