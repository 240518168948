'use strict';

angular.module('tdl.directives').directive('tdlDate', function () {
  return {
    restrict: 'A', // only activate on element attribute
    require: '?ngModel', // get a hold of NgModelController
    replace: false,
    link: function (scope, elem, attrs, ngModel) {
      if (!ngModel) return; // do nothing if no ng-model

      // watch own value and re-validate on change
      scope.$watch('formData', function () {
        validate();
      }, true);

      // observe the other value and re-validate on change
      attrs.$observe('tdlDate', function (val) {
        validate();
      });

      var validate = function () {
        if (scope.formData.start && scope.formData.end) {
          // values
          var valid = scope.formData.start.valueOf() <= scope.formData.end.valueOf();

          // set validity
          ngModel.$setValidity('tdlDate', valid);
        }
      };
    }
  }
});
