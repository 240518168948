'use strict';

angular.module('tdl.directives').directive('tdlInputTime', [
  function () {

    
    var directiveDefinitionObject = {
      restrict: 'E', // only activate on element attribute
      scope: {
        model: '=',
        change: '&',
        field: '='
      },
      link: link,
      templateUrl: 'views/forms/tdlInputTime.html'
    }

    return directiveDefinitionObject;

    function link(scope, elem) {

      
       scope.labelCols = function(c) {
        if(c >= 0) {
          if(c == 0) {
            return "hide";
          } else {
            return "col-xs-"+c;
          }
        } else {
          return "col-xs-4";
        }
      }
      
       scope.inputCols = function(c) {
        if(c >= 0) {
          var x = 12-c;
          return "col-xs-"+x;
        } else {
          return "col-xs-8";
        }
      }
       
      scope.messagesCols = function(c) {
        if(c >= 0) {
          var x = 12-c;
          return "col-xs-"+x + " col-xs-offset-"+c;
        } else {
          return "col-xs-8 col-xs-offset-4";
        }
      }
      
      if(scope.model) {
        scope.localTime = moment(scope.model).utc().format('H:mm:ss');
      }
      
      var regexpTime = /^(\d+):(\d\d):(\d\d)$/;
      
      scope.localChange = function() {
        var date = moment(scope.localTime,'H:mm:ss').utc().valueOf() - moment({hour: 0}).utc().valueOf()
        scope.model = date;
        if(scope.change) {
          scope.change()
        }
      }
      
      
    }
    
    
    
    
  }
]);