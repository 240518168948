'use strict';

import XLSX from 'xlsx-js-style'

angular.module('tdl.directives').directive('tdlAnalisisFieldSelector', ['Form', 'NgTableParams', '$q','Conf','TDLAuth','$http','$filter',
  function (Form, NgTableParams, $q, Conf,TDLAuth,$http,$filter) {

    var directiveDefinitionObject = {
      restrict: 'E', // only activate on element attribute
      scope: {
        data: '='
      },
      link: link,
      templateUrl: 'views/analisis/fieldSelector.html'
    }

    return directiveDefinitionObject;

    function link(scope, elem, attrs, $compile) {



      scope.$watch('data', function() {
        scope.tableParams = new NgTableParams(scope.data.params, {
          dataset: scope.data.dataset
        });
      })

      scope.downloadXls = () => {
        const woorkbook = XLSX.utils.book_new();
        const headers = scope.data.columns.filter(c => c.type != 'image').map(x => {
          return {
            "v": x.title,
            "t": "s",
            "s": {
              "font": {"bold": true},
              "alignment": {"horizontal": "center"}
            }
          }
        })
        const data = scope.data.dataset.map(d => {
          return scope.data.columns.filter(c => c.type != 'image').map(c => {
            switch(c.type) {
              case 'date': return new Date(d[c.field])
              default: return d[c.field]
            }
            
          })
        })
        console.log(data)
        const worksheet = XLSX.utils.aoa_to_sheet([headers].concat(data))
        XLSX.utils.book_append_sheet(woorkbook,worksheet,"export")
        XLSX.writeFile(woorkbook,"export.xlsx")
        console.log(scope.data.columns)
        console.log(scope.data.dataset)
      }



    }


  }
]);
