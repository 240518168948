'use strict';
angular.module('tdl.controllers').controller('UsersListController', ['$scope', 'TDLAuth', 'User', 'UserAdmin', 'Team', 'AlertsManager', 'StaticDataLoader', 'Menu','StorageHelper',
  function UsersListController($scope, TDLAuth, User, UserAdmin, Team, AlertsManager, StaticDataLoader, Menu,StorageHelper) {

    TDLAuth.checkAdmin();
    Menu.section = 'backend';
    $scope.menu = Menu;

    $scope.since = 0;

    $scope.fieldParams = {
      open: false,
      data: null
    };

    $scope.dateToMillis = function () {
      if ($scope.fieldParams.data) {
        $scope.since = StorageHelper.dateToMillis($scope.fieldParams.data);
      }
    }

    $scope.onChange = function() {
      $scope.users = User.list({since: $scope.since});
    }

    $scope.users = User.list({since: $scope.since});



    $scope.settings = {
      colHeaders: ['Username','Realname','Concat','City','Email',"#records", "last login"],
      stretchH: 'all',
      height: window.innerHeight - 200,
      fixedRowsTop: 0,
      columnSorting: true,
      columns: [
        {
          data: 'username',
          readOnly: true,
        },
        {
          data: 'realName',
          readOnly: true
        },
        {
          data: 'contact',
          readOnly: true
        },
        {
          data: 'email',
          readOnly: true
        },
        {
          data: 'city',
          readOnly: true
        },
        {
          data: 'count',
          readOnly: true
        },
        {
          data: 'lastLogin',
          readOnly: true
        }
      ]
    }

  }
]);
