'use strict';

/**
 * Services that persists and retrieves TODOs from localStorage.
 */

angular.module('tdl.services.storage').factory('Stats', ['$resource', 'Conf', 'TDLAuth',
 function ($resource, Conf, TDLAuth) {
    return $resource(Conf.api + '/stats/:statType/:param/:year', {}, {
      events: {
        method: 'GET',
        params: {
          statType: 'events'
        },
        headers: TDLAuth.header()
      },
      planning: {
        method: 'GET',
        params: {
          statType: 'planning'
        },
        headers: TDLAuth.header()
      },
      reports: {
        method: 'GET',
        params: {
          statType: 'report'
        },
        headers: TDLAuth.header()
      },
      meteo: {
        method: 'GET',
        params: {
          statType: 'meteo'
        },
        headers: TDLAuth.header()
      },
      reportMontly: {
        method: 'GET',
        params: {
          statType: 'report',
          param: 'montly'
        },
        isArray: true,
        headers: TDLAuth.header()
      },
      planningMontly: {
        method: 'GET',
        params: {
          statType: 'planning',
          param: 'montly'
        },
        isArray: true,
        headers: TDLAuth.header()
      },
      eventsMontly: {
        method: 'GET',
        params: {
          statType: 'events',
          param: 'montly'
        },
        isArray: true,
        headers: TDLAuth.header()
      },
      tags: {
        method: 'GET',
        params: {
          statType: 'tags',
          param: '@id'
        },
        isArray: true,
        headers: TDLAuth.header()
      },
      tagCloudReport: {
        method: 'GET',
        params: {
          statType: 'tagCloudReport'
        },
        isArray: true,
        headers: TDLAuth.header()
      },
      tagCloudPlanning: {
        method: 'GET',
        params: {
          statType: 'tagCloudPlanning'
        },
        isArray: true,
        headers: TDLAuth.header()
      },
      tagByTypePlanning: {
        method: 'GET',
        params: {
          statType: 'tagByTypePlanning'
        },
        isArray: true,
        headers: TDLAuth.header()
      },
      tagByTypeReport: {
        method: 'GET',
        params: {
          statType: 'tagByTypeReport'
        },
        isArray: true,
        headers: TDLAuth.header()
      },
      storyboard: {
        method: 'GET',
        params: {
          statType: 'storyboard'
        },
        isArray: true,
        headers: TDLAuth.header()
      }
    });
 }
]);