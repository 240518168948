/*global define*/

import $ from "jquery";

angular.module('tdl.services').factory('Starter', ['$rootScope', '$q', 'TDLAuth','ConfigService', 'Labels','StaticDataLoader', 'Conf','$notification','$ws',
  function ($rootScope, $q,TDLAuth, ConfigService, Labels,StaticDataLoader, Conf, $notification,$ws) {

  $rootScope.TDLconf = {
    ui: {},
    labels: {},
    api: Conf.api,
    small: window.window.innerWidth < 1200 || !('draggable' in document.createElement('span'))
  };

  $notification.requestPermission()

  var configPromise = ConfigService.get().$promise.then(
    function (config) {
      $rootScope.TDLconf.ui = config;
    },
    function (e) {
      console.log(e)
    }
  );


  if(TDLAuth.getUsername()) {

    var ws = $ws.init({
      url: Conf.api.replace("http","ws")+"/ws/notifications/" + TDLAuth.user._id,
      reconnect: false,
      reconnectInterval: 2000,
      lazyOpen: false,
      protocols: null,
      enqueue: false
    });

    ws.on('open', function () {
      console.log('Connection open...');
      ws.send(JSON.stringify(TDLAuth.header()))

    });

    ws.on('message',function(message) {
      var notification = JSON.parse(message.data);
      $notification(notification.title, {
        body: notification.body,
        icon: $rootScope.TDLconf.ui.logo
      });
    });

  }

  function staticData() {
    if(TDLAuth.getUsername()) {
      return StaticDataLoader.load();
    } else {
      var d = $q.defer();
      d.resolve("ok");
      return d.promise
    }
  }

  var labelPromise = Labels.get().$promise.then(
    function (config) {
      $rootScope.TDLconf.labels = config
    },
    function (e) {
      console.log(e)
    }
  );


    let result = $q.defer();



  var promiseList = [configPromise, labelPromise, staticData()];

  $q.all(promiseList).then(function (x) {
    setTimeout(function() { $("#preloading").fadeOut(200) }, 300);
    result.resolve(x)
  });

  return result.promise
}]);
