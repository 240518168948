'use strict';

angular.module('tdl.directives').directive('tdlPredefinedTimeSelections', ['$rootScope',
  function ($rootScope) {

    var directiveDefinitionObject = {
      restrict: 'E', // only activate on element attribute
      scope: {
        startDate: '=',
        endDate: '=',
        onChange: '='
      },
      link: link,
      templateUrl: 'views/directives/tdlPredefinedTimeSelections.html'
    }

    return directiveDefinitionObject;

    function link(scope, elem, attrs, $compile) {

      scope.intervals = $rootScope.TDLconf.ui.predefinedIntervals;


      scope.selectInterval = function(interval) {

        if(interval.start > interval.end) {//crossyear selection
          if(moment().month() > interval.start) {
            scope.startDate = moment().startOf('year').add(interval.start,'months').add(interval.yearOffset, "years")
            scope.endDate = moment().startOf('year').add(1,'year').add(interval.end,'months').endOf('month').add(interval.yearOffset, "years")
          } else if(moment().month() < interval.start) {
            scope.startDate = moment().startOf('year').add(-1,'year').add(interval.start,'months').add(interval.yearOffset, "years")
            scope.endDate = moment().startOf('year').add(interval.end,'months').endOf('month').add(interval.yearOffset, "years")
          }
        } else {
          scope.startDate = moment().startOf('year').add(interval.start,'months').add(interval.yearOffset, "years").toDate()
          scope.endDate = moment().startOf('year').add(interval.end,'months').endOf('month').add(interval.yearOffset, "years").toDate()
        }
        $("input[type=daterange]").data('daterangepicker').setStartDate(scope.startDate)
        $("input[type=daterange]").data('daterangepicker').setEndDate(scope.endDate)
        scope.onChange(scope.startDate,scope.endDate);
      }


    }
  }]);
