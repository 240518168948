/*global define*/
'use strict';

import Moment from 'moment';
import { extendMoment } from 'moment-range';


angular.module('tdl.directives').directive('tdlCalendarYear', ['FormDataHelper', function (FormDataHelper) {
  var defaultColor = "#333333";

  const moment = extendMoment(Moment);

  return {
    restrict: 'E',
    link: link,
    scope: {
      calendarOpt: '='
    },
    templateUrl: 'views/elements/calendarYear.html'
  };

  function link(scope, element, attrs) {
    scope.days = _.range(1, 32);

    scope.$watch('calendarOpt.eventSource', function () {

      var period = scope.calendarOpt.period;

      var monthRows = emptyMonthRows(period.startDate, period.endDate);

      monthRows = fillMonthRows(monthRows);
      monthRows = finalizeMonthRows(monthRows);

      scope.monthRows = monthRows;

      scope.eventColor = eventColor;
    });


    function emptyMonthRows(start, end) {

      var monthsSpan = moment().range(moment(start).startOf('month'), moment(end).endOf('month')).diff('months')+1;

      var result = [];

      //Months
      var currentMonthDate = moment(start);

      for (var i = 0; i < monthsSpan; i++) {


        // TODO: to improve, check wheter exists some events for the team in current month
        var teamsInCurrentMonth = scope.calendarOpt.eventSource.length;

        //Teams
        for (var j = 0; j < teamsInCurrentMonth; j++) {

          var monthObj = {
            key: currentMonthDate.year() + "-" + currentMonthDate.month(),
            month: currentMonthDate.month(),
            year: currentMonthDate.year(),
            monthLabel: currentMonthDate.format('MMM'),
            team: scope.calendarOpt.eventSource[j].team,
            firstMonthRow: j === 0,
            firstTeamRow: true,
            days: []
          }

          result.push(monthObj);

        }

        currentMonthDate = moment(currentMonthDate).add(1, 'month');

      }
      return result;

    }

    function fillMonthRows(monthRows) {
      // Teams
      for (var i = 0; i < scope.calendarOpt.eventSource.length; i++) {
        var team = scope.calendarOpt.eventSource[i].team;
        var events = scope.calendarOpt.eventSource[i].events;

        // Events
        for (var j = 0; j < events.length; j++) {
          var event = preformatEvent(events[j]);
          var monthRow = _.find(monthRows, function (row) {
            return row.team && team && row.team._id === team._id && row.month === event.start.month() && row.year === event.start.year()
          });
          if (monthRow) {
            monthRow.days.push(event);
          }
        }

      }

      return monthRows;

    }

    function finalizeMonthRows(monthRows) {
      monthRows = _.flatten(_.map(monthRows, removeOverlaps));

      // Add right team rowSpan
      _.each(_.groupBy(monthRows, function (r) {
        return r.month + "," + r.year
      }), function (monthList, month) {

        var firstMonthRow = _.find(monthList, function (row) {
          return row.firstMonthRow
        });

        _.each(_.groupBy(monthList, function (t) {
          if (t.team) {
            return t.team._id
          } else {
            return "noid"
          }
        }), function (teamList) {
          var firstTeamRow = _.find(teamList, function (row) {
            return row.firstTeamRow
          });
          firstTeamRow.rowSpan = teamList.length;
        });

        var sum = _.reduce(_.filter(monthList, function (m) {
          return m.firstTeamRow
        }), function (memo, emonth) {
          return memo + emonth.rowSpan;
        }, 0);

        firstMonthRow.rowSpanMonth = sum;

      });



      // Place event in right day
      _.each(monthRows, function (monthRow) {
        var days = [];
        var events = monthRow.days;

        _.each(events, function (event) {
          days[event.start.date()] = event;
        });



        var year = monthRow.year;
        var month =  monthRow.month + 1;
        if(monthRow.month == 11) {
          year++;
          month = 0;
        }

        var lastDayOfMonth = moment({
          month: month,
          day: 1,
          year: year
        }).add(-1, "days").date();

        if (days.length < lastDayOfMonth) {
          days[lastDayOfMonth] = null;
        }

        // Add class for days without event
        for (var i = 1; i < days.length; i++) {
          var day = days[i];

          if (!day) {
            days[i] = {
              rowSpan: 1,
              class: moment({
                month: monthRow.month,
                day: i,
                year: monthRow.year
              }).format('ddd').toLowerCase()
            };
          }

        }

        // Iterator start from 0, days starts from 1: remove first element
        days.shift();

        // Remove daySpanOffset
        for (var i = 0; i < days.length; i++) {
          if (days[i]) {
            days.splice(i + 1, days[i].daySpan - 1);
          }
        };

        monthRow.days = days;

      });



      return monthRows;
    }

    function removeOverlaps(monthRow) {
      var events = monthRow.days;
      monthRow.days = null;

      var notOverlappedEvents = [];
      var overlappedEvents = [];

      for (var i = 0; i < events.length; i++) {
        var event = events[i];

        var someOverlap = _.find(notOverlappedEvents, function (otherEvent) {
          return event.dateRange.overlaps(otherEvent.dateRange);
        });

        if (someOverlap) {
          overlappedEvents.push(event);
        } else {
          notOverlappedEvents.push(event);
        }
      }

      if (overlappedEvents.length > 0) {
        var newMonthRow = angular.copy(monthRow);

        monthRow.days = notOverlappedEvents;

        newMonthRow.days = overlappedEvents;
        newMonthRow.firstMonthRow = false;
        newMonthRow.firstTeamRow = false;

        return [monthRow, removeOverlaps(newMonthRow)];
      } else {
        monthRow.days = notOverlappedEvents;

        return monthRow;
      }
    }

    function preformatEvent(event) {
      if (event) {
        event.start = moment(event.start);
        event.end = moment(event.end);
        event.dateRange = moment().range(event.start, event.end);
        //event.daySpan = event.end.diff(event.start, 'days') + 1;
        event.daySpan = Math.round(event.dateRange.valueOf() / 24 / 60 / 60 / 1000);

        //event.className = _getClassEvent(event.eventTypeId);
      }
      return event;
    }

    function eventColor(event) {
      if (event.form) {
        return FormDataHelper.ui.background(event.form);
      } else {
        return {};
      }
    }


  }
}]);
