'use strict';

import $ from "jquery";
import _ from "underscore";

var locDebug = null;

angular.module('tdl.controllers').controller('PlanningController', ['$scope', '$rootScope', 'AlertsManager', 'Menu', 'Calendar', 'StaticDataLoader', 'CalendarHelper', 'FormDataHelper', 'Conf', 'ModalManager','uiCalendarConfig',
  function PlanningController($scope, $rootScope, AlertsManager, Menu, Calendar, StaticDataLoader, CalendarHelper, FormDataHelper, Conf, ModalManager,uiCalendarConfig) {
    Menu.section = "planning";
    $scope.menu = Menu;



    $scope.formUi = FormDataHelper.ui;

    $scope.offset = "";
    $scope.newLeft = "";
    $scope.noEvent = [];

    $scope.forms = [];
    $scope.formVisibles = {};
    $scope.innerForms = [];

    $scope.presences = {};

    $scope.newRecurring = ModalManager.newRecurringEvent;

    $scope.$watch("menu.team",function() {
      $scope.presences = {};
      if($scope.menu.team) {
        _.each($scope.menu.team.athletes, function (a) {
          $scope.presences[a._id] = true;
        });
      }
    });

    $scope.getPresences = function() {
      return _.map(_.filter(_.pairs($scope.presences),function(atl) {return atl[1]}),function(atl) {return atl[0]});
    };

    $scope.dropdown = false;

    $scope.toggleAll = (formId,e) => {
      if(e.ctrlKey || e.altKey) {
        if(Object.values($scope.formVisibles).filter(x => x).length > 0) { // select single
          for (const [key, value] of Object.entries($scope.formVisibles)) {
            $scope.formVisibles[key] = key == formId
          }
        } else {
          for (const [key, value] of Object.entries($scope.formVisibles)) {
            $scope.formVisibles[key] = true
          }
        }
      } else {
        $scope.formVisibles[formId] = e.target.checked
      }
      $scope.calendar.reloadWithNewTeam()
    }


    $scope.icalendar = {
      link: "",
      timezone: -(new Date().getTimezoneOffset() / 60)
    }

    $scope.formRecurring = function(weekOrMonth) {
      return $scope.innerForms.filter(function(f) {return $scope.formUi.visible(f,'planning',weekOrMonth,$scope.menu.teamsOrAthletes)})
    }



    StaticDataLoader.form($scope.formVisibles, function (forms, innerForms) {
      $scope.forms = forms;
      $scope.innerForms = innerForms;
    });

    $scope.calendar = Calendar.load({
      promises: {
        loadWeek: [
          Calendar.loadForms
        ],
        loadMonth: [
          Calendar.loadForms
        ]
      },
      callbacks: {
        loadMonth: function (firstLoad) {
          reloadCalendarLink();

          if(firstLoad) {
            if (!$rootScope.TDLconf.ui.showBothWeekMonth) {
              _.each($scope.innerForms, function (f) {
                if (f.properties.datespan) {
                  $scope.formVisibles[f._id] = true;
                } else {
                  $scope.formVisibles[f._id] = false;
                }
              });
            }
          }

        },
        loadWeek: function (firstLoad) {
          if(firstLoad) {
            _.each($scope.innerForms, function (f) {
              if (f.properties.timespan) {
                $scope.formVisibles[f._id] = true;
              }
            });
          }
        },
        loadTeam: reloadCalendarLink
      },
      tdlCalendar: function () {
        return uiCalendarConfig.calendars.planningMain
      },
      forms: function () {
        return $scope.forms;
      },
      formVisibles: function () {
        return $scope.formVisibles;
      },
      dropdown: $scope.dropdown
    });

    $scope.toggleDropdown = function () {
      $scope.dropdown = !$scope.dropdown;
    }
    $scope.openFormDataPopup = $scope.calendar.openFormDataPopup(function() { $scope.dropdown = false });


    var loading = false;

    $scope.reloadWithNewTeam = $scope.calendar.reloadWithNewTeam;
    $scope.alertEventOnClick = $scope.calendar.dayClick;
    $scope.toggle = CalendarHelper.toggle;


    $scope.$watch('icalendar.timezone', reloadCalendarLink, true);


    var views = [];
    if(!$rootScope.TDLconf.ui.planning.hideMonth) {
      views.push("month")
    }

    views.push("agendaWeek")
    if($rootScope.TDLconf.ui.planning.day) {
      views.push("agendaDay")
      views.push("basicDay")
    }



    /* calendar config object */
    $scope.uiConfig = {
      calendar: {


        aspectRatio: (($(window).width() - 245 - 48) / ($(window).height() - 85 - 85)),
        firstDay: 1,
        allDayText: $rootScope.TDLconf.labels.planning.allDayText,

        selectable: true,

        defaultView: $rootScope.TDLconf.ui.planning.defaultView,
        header: {
          left: 'title',
          center: '',
          right:  views.join(",") + ' prev,today,next',
        },
        timezone: 'UTC',
        weekNumberCalculation:  'ISO',

        views: {
          month: { // name of view
            titleFormat: "MMMM YYYY",
            columnFormat: 'dddd',
            // other view-specific options here
          },
          week: {
            //titleFormat: 'MMMM yyyy',
            columnFormat: 'dddd, D'
          }
        },


        weekNumbers: true,


        axisFormat: 'H:mm',
        timeFormat: 'H:mm',
        minTime: $rootScope.TDLconf.ui.minTime,
        maxTime: $rootScope.TDLconf.ui.maxTime,
        slotDuration: $rootScope.TDLconf.labels.fullcalendar.slotDuration || '00:30:00',
        snapDuration: '00:15:00',
        slotEventOverlap: false,

        editable: true,
        droppable: true, // this allows things to be dropped onto the calendar !!!
        defaultTimedEventDuration: '01:00:00',

        viewRender: $scope.calendar.viewRender,
        eventRender: $scope.calendar.eventRender,
        drop: $scope.calendar.drop($scope.getPresences),
        eventClick: $scope.calendar.eventClick,
        eventResize: $scope.calendar.eventResize,
        dayClick: $scope.calendar.dayClick,
        eventDrop: $scope.calendar.eventDrop,
        buttonText: $rootScope.TDLconf.labels.fullcalendar.buttonText,
        monthNames: $rootScope.TDLconf.labels.fullcalendar.monthNames,
        monthNamesShort: $rootScope.TDLconf.labels.fullcalendar.monthNamesShort,
        dayNames: $rootScope.TDLconf.labels.fullcalendar.dayNames,
        dayNamesShort: $rootScope.TDLconf.labels.fullcalendar.dayNamesShort,
      }
    };


    function reloadCalendarLink() {
      if (Menu.team) {
        $scope.icalendar.link = Conf.api + "/icalendar/" + Menu.team._id + "?timezone=" + (-$scope.icalendar.timezone);
      }
    }

}]);
