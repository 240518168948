'use strict';

angular.module('tdl.directives').directive('variableCreator', ['Form','FormGroup','$q',
  function (Form,FormGroup,$q) {


    var directiveDefinitionObject = {
      restrict: 'E', // only activate on element attribute
      scope: {
        model: '='
      },
      link: link,
      templateUrl: 'views/directives/variableCreator.html'
    }

    return directiveDefinitionObject;

    function link(scope, elem) {

      scope.show = false;

      scope.forms = [];
      scope.fields = [];

      $q.all([Form.query().$promise,FormGroup.query().$promise]).then(function(f) {
        scope.forms =  f[0].concat(f[1]);
      });

      scope.variable = {
        form:{},
        field:{},
        subfield:{},
        operator:"array",
        def:"",
        withDefault: false,
      };

      scope.operators = function() {
        var typ = "";
        if(scope.variable.field.typ) typ = scope.variable.field.typ;
        switch (typ)  {
          case "Number": return ["array", "avg", "var", "median", "sum", "last", "first", "count", "min", "max"];
          case "Time Span": return ["array", "avg", "var", "median", "sum", "last", "first", "count", "min", "max"];
          case "Array": return ["array", "last", "first", "count", "flatten"];
          default: return ["array", "last", "first", "count"];
        }
      };

      scope.$watch('variable.form',function(){
        fieldsWithDefault();
      }, true);

      scope.$watch('variable',function() {
        var base = "$"+scope.variable.form._id+"."+scope.variable.field.id;
        if(scope.variable.subfield.id) {
          base = base + "." + scope.variable.subfield.id;
        }
        if(scope.variable.operator !== "array") {
          base = base + "~>" + scope.variable.operator;
        }
        base = base + "$";
        if(scope.variable.withDefault) {
          scope.model = "withDefault("+base+","+scope.variable.def+")";
        } else {
          scope.model = base;
        }
      }, true);

      function resetFields() {
        scope.fields = [];
        scope.variable.field = {};
        scope.variable.subfield={};
        scope.variable.operator="array";
      }

      function fieldsWithDefault() {
        resetFields();

        var form = scope.variable.form;



        if(form && form._id) {
          scope.fields = Form.fields({form: form._id});
        }
      }



    }




  }
]);
