'use strict';


angular.module('tdl.directives').directive('recurringTab', ['$http','Conf','TDLAuth',
  function ($http,Conf,TDLAuth) {

    var directiveDefinitionObject = {
      restrict: 'E', // only activate on element attribute
      scope: {
        recurringId: '='
      },
      link: link,
      templateUrl: 'views/directives/recurringTab.html'
    }

    return directiveDefinitionObject;

    function link(scope, elem, attrs, $compile) {

        $http.get(Conf.api + '/formData/recurring/' + scope.recurringId, {
            headers: TDLAuth.header()
        }).then(function (response, status, headers, config) {
              scope.recurringEvents = response.data.map(x => { 
                  x.start = moment.utc(x.start)
                  return x                  
              })
              scope.first = scope.recurringEvents[0]
              scope.last = scope.recurringEvents.length > 0 ? scope.recurringEvents[scope.recurringEvents.length - 1] : null;
          }, function (err) {
            console.log(err);
          });
      
    }

  }]);
