/*global define*/
'use strict';

/**
 * Services that persists and retrieves TODOs from localStorage.
 */

function getDomain() {
  //return "https://api-gerdev.teamdatalog.com";
  var hostname = window.location.hostname;
  if(window.location.hostname === "tdl") hostname = "local.teamdatalog.com:9001"; //DEV config
  return window.location.protocol + '//api-'+ hostname;
}

const confModule = angular.module('tdl.config', []).constant('Conf', {
  api: getDomain()
});

export default confModule;
