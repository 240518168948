'use strict';

angular.module('tdl.controllers').controller('AnalisisCreatorController', ['$scope',
  '$rootScope', '$location', 'FormAnalisisStructure', 'Analisis', 'Menu','TDLAuth','ExportsManager','User',
  function AnalisisCreatorController($scope, $rootScope, $location, FormAnalisisStructure, Analisis,Menu,TDLAuth,ExportsManager,User) {

    TDLAuth.checkAdmin();

    Menu.section = 'form';
    $scope.menu = Menu;

    $scope.types = ['text','tags','number'];


    var setListener = false,
      eventLoaded = false;

    $scope.debug = false;

    $scope.toggleDebug = function() {
      $scope.debug = !$scope.debug;
    };

    $scope.list = {
      'formAnalisis':[],
      'analisis': [],
      'exports': []
    };

    $scope.go = function ( path ) {
      $location.path( path );
    };

    $scope.analisis = {}

    $scope.newAnalisisPage = function() {
      $scope.analisis = {};
    };

    $scope.addFormAnalisis = function() {
      if($scope.analisis.elements) {
        $scope.analisis.elements.push({});
      } else {
        $scope.analisis.elements = [{}];
      }
    };

    $scope.removeElement = function (dataset) {
      $scope.analisis.elements.splice($scope.analisis.elements.indexOf(dataset), 1);
    }


    $scope.addFilter = function() {
      if($scope.analisis.filters) {
        $scope.analisis.filters.push({});
      } else {
        $scope.analisis.filters = [{}];
      }
    };

    $scope.removeFilter = function (dataset) {
      $scope.analisis.filters.splice($scope.analisis.filters.indexOf(dataset), 1);
    };

    $scope.addFilterField = function(filter) {
      if(filter.fields) {
        filter.fields.push({});
      } else {
        filter.fields = [{}];
      }
    };

    $scope.removeFilterField = function (filter,dataset) {
      filter.fields.splice(filter.fields.indexOf(dataset), 1);
    };

    $scope.addFilterFieldOption = function(field) {
      if(field.values) {
        field.values.push({});
      } else {
        field.values = [{}];
      }
    };

    $scope.removeFilterFieldOption = function (field,dataset) {
      field.values.splice(field.values.indexOf(dataset), 1);
    };


    $scope.loadAnalisis = function (a) {

      var analisis = angular.copy(a);

      if(analisis.filters) {
        analisis.filters = _.map(analisis.filters, function(filter) {
          if(filter.fields) {
            filter.fields = _.map(filter.fields, function(field) {
              if(field.values) {
                field.values = _.map(field.values, function(value) {
                  return {value: value};
                });
              }
              return field;
            });
          }
          return filter;
        });
      }

      $scope.analisis = analisis;

    }

    $scope.saveAnalisis = function () {
      var analisis = angular.copy($scope.analisis);


      //remove object from values
      if(analisis.filters) {
        analisis.filters = _.map(analisis.filters, function(filter) {
          if(filter.fields) {
            filter.fields = _.map(filter.fields, function(field) {
              if(field.values) {
                field.values = _.map(field.values, function(value) {
                  return value.value;
                });
              }
              return field;
            });
          }
          return filter;
        });
      }


      if (analisis._id) {
        analisis.$update().then(function (v) {
          $scope.analisis = v;
          $scope.list.analisis = $scope.list.analisis.map(a => {
            if(a._id === v._id) return v
            else return a
          })
        });
      } else {
        Analisis.post(analisis).$promise.then(function (v) {
          $scope.analisis = v;
          $scope.list.analisis.push(v);
        });
      }

    };

    $scope.deleteAnalisis = function() {
      var sure = confirm("Are you sure to delete?");
      if(sure) {
        Analisis.remove({analisisId: $scope.analisis._id}).$promise.then(function (v) {
          Analisis.query().$promise.then(function (v) {
            $scope.list.analisis = _.sortBy(v,function(a) { return a.order});
            $scope.newAnalisisPage();
          });
        });
      }
    }

    $scope.users = []

      function onStart() {
      //loadForms();
      $scope.users = User.query();
      Analisis.query().$promise.then(function (v) {
        $scope.list.analisis = _.sortBy(v,function(a) { return a.order});
      });
      $scope.list.formAnalisis = FormAnalisisStructure.query();
      $scope.list.exports = ExportsManager.query();

    }

    $scope.swap = function (theArray, indexA, indexB) {
      var temp = theArray[indexA];
      theArray[indexA] = theArray[indexB];
      theArray[indexB] = temp;
    }




    onStart();
  }
]);
