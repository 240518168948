'use strict';

angular.module('tdl.directives').directive('tdlMiniCalendars', [
  function () {

    var directiveDefinitionObject = {
      restrict: 'E', // only activate on element attribute
      link: link,
      templateUrl: 'views/elements/miniCalendars.html'
    }

    return directiveDefinitionObject;

    function link(scope, elem, attrs) {
      scope.miniCalendars = [];

      scope.noEvents = [];

      onStart();

      function onStart() {

        for (var i = 1; i <= 12; i++) {
          var miniCalendar = getCalendarObj();
          var monthDiff = i - 3;
          var date = moment().add(monthDiff, 'month');
          miniCalendar.month = date.month();
          miniCalendar.year = date.year();
          miniCalendar.name = 'tdlMiniCalendar' + i;
          scope.miniCalendars.push(miniCalendar);
        }

      }

      function getCalendarObj() {
        return {
          header: {
            left: 'title',
            center: '',
            right: ''
          },
          editable: false,
          titleFormat: {
            month: "MMMM yyyy"
          },
          dayClick: scope.alertEventOnClick,
          firstDay: 1,
          year: 2014,
          month: 1,
          name: null
        };
      }
    }

  }
]);
