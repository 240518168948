/*global define*/
'use strict';

const userConstants = angular.module('tdl.services').constant('UserConstants', function () {
  var accessLevels = {
    'public': "*",
    'anon': ['public'],
    'user': ['user', 'admin'],
    'admin': ['admin']
  };

  var roles = [
      'public',
      'user',
      'admin'
    ];

  var constantObj = {
    accessLevels: accessLevels,
    roles: roles,
    userRoles: buildRoles(roles)
  };

  return constantObj;

  function buildRoles(roles) {
    var userRoles = {};

    for (var role in roles) {
      userRoles[roles[role]] = {
        title: roles[role]
      };
    }

    return userRoles;
  }

}());

export default userConstants;
