/*global define*/
'use strict';


angular.module('tdl.directives').directive('tdlTabs', function () {

    return {
      restrict: 'E',
      transclude: true,
      controller: ['$scope', '$rootScope', function ($scope, $rootScope) {
        var indexUnordered = 0;

        $scope.panes = {
          list: []
        };

        $scope.select = function (pane) {
          if (!pane.selected) {
            angular.forEach($scope.panes.list, function (pane) {
              pane.selected = false;
            });
            pane.selected = true;
          }
        };

        $scope.closePopup = function () {
          if ($scope.$parent.closePopup) {
            $scope.$parent.closePopup();
          }

          if ($scope.modal) {
            $scope.modal.close();
          }
        }

        $scope.deleteEvent = function () {
          if ($scope.$parent.deleteEvent) {
            $scope.$parent.deleteEvent();
          }

          if ($scope.modal) {
            $scope.modal.close();
          }
        }

        this.addPane = function (pane) {

          var order = assignPanelOrder(pane);

          $scope.panes.list.push(pane);
          $scope.panes.list = _.sortBy($scope.panes.list, 'order');

          if ($scope.panes.list.length > 0 && $scope.panes.list[0].order == 0) {
            $scope.select($scope.panes.list[0]);
          }

        };

        function assignPanelOrder(pane) {
          var order;
          if (_.isNumber(pane.order)) {
            order = pane.order;
          } else {
            order = indexUnordered++;
          }

          pane.order = order;

          return order;

        }
      }],
      templateUrl: 'views/elements/tabs.html'
    };
  })
  .directive('tdlPane', function () {
    return {
      require: '^tdlTabs',
      restrict: 'E',
      transclude: true,
      scope: {
        title: '@',
        isVisible: '=',
        warning: '=?',
        order: '=?'
      },
      link: function (scope, element, attrs, tabsCtrl) {
        tabsCtrl.addPane(scope);
      },
      templateUrl: 'views/elements/panel.html'
    };
  }).directive('tdlSidebarPane', function () {
    return {
      require: '^tdlSidebarTabs',
      restrict: 'E',
      transclude: true,
      scope: {
        icon: '@'
      },
      link: function (scope, element, attrs, tabsCtrl) {
        tabsCtrl.addPane(scope);
      },
      templateUrl: 'views/elements/panel.html'
    };
  });
