'use strict';

angular.module('tdl.controllers').controller('UserConfirmController',   ['$scope','$rootScope', 'TDLAuth', 'Conf', 'AlertsManager', 'Menu','$routeParams','$http',
  function ($scope, $rootScope, TDLAuth, Conf, AlertsManager, Menu, $routeParams, $http) {
    Menu.section = 'backend';
    $scope.menu = Menu;

    $scope.currentUser = {};

    $scope.userRights = {};

    $scope.usernameBusy = false;

    $scope.checkUsername = _.debounce(checkUsername,1000);
    $scope.checkPassword = checkPassword;
    $scope.matchPassword = matchPassword;
    $scope.createUser = createUser;
    $scope.valid = valid;

    $scope.validToken = true;

    $http.get(Conf.api + "/admin/user/invited/" + $routeParams['token']).then(function (data) {
      $scope.userRights = data.data;
      $scope.loaded = true;
    }, function (err) {
      console.log("invalid token");
      $scope.validToken = false;
    });

    function checkUsername() {
      $http.get(Conf.api + "/admin/user/check/username/" + $scope.currentUser.username).then(function (data) {
        if(!data.data) { $scope.usernameBusy = true } else { $scope.usernameBusy = false }
      }, function (err) { console.log(err)})
    }


    $scope.passwordValidate = {
      lower: true,
      upper: true,
      numbers: true,
      length: true,
      confirmed: true
    };

    $scope.allValid = true;

    function checkPassword() {
      if($scope.currentUser.password.length > 0) {
        $scope.passwordValidate.lower = /[a-z]+/.test($scope.currentUser.password);
        $scope.passwordValidate.upper = /[A-Z]+/.test($scope.currentUser.password);
        $scope.passwordValidate.numbers = /[0-9]+/.test($scope.currentUser.password);
        $scope.passwordValidate.length = $scope.currentUser.password.length > 7;
        matchPassword();
      }

    }

    function matchPassword() {
      $scope.passwordValidate.confirmed = $scope.currentUser.password === $scope.validationPassword;
      $scope.allValid = $scope.passwordValidate.lower && $scope.passwordValidate.upper && $scope.passwordValidate.numbers && $scope.passwordValidate.length && $scope.passwordValidate.confirmed;
    }




    function valid() {
      return !$scope.formUser.$invalid  && $scope.allValid && $scope.currentUser.acceptPrivacy && !$scope.usernameBusy;
    }

    function createUser() {
      if(valid()) {
        var user = $.extend(true, {}, $scope.currentUser);
        $http.post(Conf.api + "/admin/user/confirm/" + $routeParams['token'], user).then(function (data) {
          AlertsManager.addAlert({
            type: 'success',
            message: 'User has been created successfully!'
          });
          TDLAuth.loginWithToken(data.data.token).then(function() {
            var dest = "/planning";
            if($rootScope.TDLconf.ui.sections.dashboard) {
              dest = "/dashboard";
            }
            window.location = window.location.origin  + window.location.pathname + "?r=" +(+new Date()) + "#" + dest;
          });
        }, function (err) {
          console.log(err)
        });
      }
    }


  }
]);
