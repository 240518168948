'use strict';

angular.module('tdl.controllers').controller('ExportsController', ['$scope',
  '$rootScope', '$location', 'FormAnalisisStructure', 'Analisis', 'Menu','TDLAuth','ExportsManager','Athlete','Team','ExportService',
  function ExportsController($scope, $rootScope, $location, FormAnalisisStructure, Analisis,Menu,TDLAuth,ExportsManager,Athlete,Team,ExportService ) {

    TDLAuth.checkAdmin();

    Menu.section = 'form';
    $scope.menu = Menu;

    $scope.debug = true;

    $scope.exports = [];
    $scope.export = {};

    $scope.teams = [];
    $scope.athletes = [];

    $scope.parameterTypes = ['number','string','null'];
    $scope.collections = ['forms_data','users','athletes','forms','teams']

    $scope.preview = {
      resource: 'team',
      team: null,
      athlete: null,
      menu: null,
      data: '',
      dateRange: ExportService.initialRange()
    };

    $scope.loadPreview = function () {

      if($scope.export._id && (($scope.preview.resource === 'team' && $scope.preview.team) || ($scope.preview.resource === 'athlete' && $scope.preview.athlete))) {

        if($scope.preview.resource === 'team') {
          $scope.preview.menu = {team: $scope.preview.team, teamScope: true};
        } else {
          $scope.preview.menu = {athlete: $scope.preview.athlete, teamScope: false, athleteScope:true};
        }

        ExportService.load($scope.export._id,$scope.preview.dateRange,$scope.preview.menu).then(function (fa) {
          $scope.preview.data = JSON.stringify(fa.data, null, '\t')
        }).catch(function(err) {
          console.log(err)
          $scope.preview.data = err.data.error;
        })
      }

    }

    function onStart() {
      $scope.newExport();
      $scope.exports = ExportsManager.query();
      $scope.teams = Team.query();
      $scope.athletes = Athlete.query();
    }

    $scope.saveExport = function() {
      if ($scope.export._id) {
        $scope.export.$update().then(function() {
          $scope.loadPreview()
        });
      } else {
        ExportsManager.post($scope.export).$promise.then(function (v) {
          $scope.export = v;
          $scope.exports.push(v);
          $scope.loadPreview();
        });
      }

    }

    $scope.duplicateExport = function() {
      let exportDuplicate = angular.copy($scope.export)
      delete exportDuplicate._id
      exportDuplicate.name += " Duplicated";
      $scope.export = exportDuplicate;
      $scope.saveExport()
    }

    $scope.deleteExport = function() {
      if(confirm('Are you sure?')) {
        ExportsManager.remove({exportId: $scope.export._id},function (v) {
          $scope.exports = ExportsManager.query();
          $scope.newExport()
        });
      }
    }

    $scope.newExport = function() {
      $scope.export = {
        name: 'export name',
        parameters: [],
        filter: [],
        eval: 'function run(js,params) {\n    return js;\n}',
        evalParameters: 'function run(params) {\n    return params;\n}'
      }
    }

    $scope.loadExport = function(exp) {
      $scope.export = exp;
      $scope.loadPreview();
    }

    $scope.addParameter = function() {
      $scope.export.parameters.push({
        id: 'id',
        description: 'parameter description',
        required: false,
        typ: 'text',
        default: {typ: 'null', value: ''}
      })
    }

    $scope.removeParameter = function(id) {
      $scope.export.parameters = $scope.export.parameters.filter(x => x.id !== id)
    }

    $scope.addFilter = function() {
      $scope.export.filter.push({
        id: 'id',
        collection: 'forms_data',
        filter: '{}',
        project: [],
        sort: [],
        lookups: [],
        showEval: false,
        eval: 'function run(js) {\n    return js;\n}'
      })
    }

    $scope.removeFilter = function(id) {
      $scope.export.filter = $scope.export.filter.filter(x => x.id !== id)
    }

    $scope.addProjection = function(id) {
      $scope.export.filter.filter(x => x.id === id && !x.project.some(y => y.value === '')).forEach(x => x.project.push({value: ''}))
    }

    $scope.removeProjection = function(id,projection) {
      $scope.export.filter.filter(x => x.id === id).forEach(x => x.project = x.project.filter(x => x.value !== projection.value))
    }

    $scope.addSorting = function(id) {
      $scope.export.filter.filter(x => x.id === id && !x.sort.some(y => y.field === '')).forEach(x => x.sort.push({field: '', asc: true}))
    }

    $scope.removeSorting = function(id,sort) {
      $scope.export.filter.filter(x => x.id === id).forEach(x => x.sort = x.sort.filter(x => x.field !== sort.field))
    }

    $scope.addLookup = function(id) {
      $scope.export.filter.filter(x => x.id === id && !x.lookups.some(y => y.as === '')).forEach(x => x.lookups.push({
        from: '',
        localField: '',
        foreignField: '',
        as: ''
      }))
    }

    $scope.removeLookup = function(id,lookup) {
      $scope.export.filter.filter(x => x.id === id).forEach(x => x.lookups = x.lookups.filter(x => x.as !== lookup.as))
    }

    $scope.enableGroup = function(id) {
      $scope.export.filter.filter(x => x.id === id).forEach(x => x.group = {id:'', fields: []})
    }

    $scope.disableGroup = function(id) {
      $scope.export.filter.filter(x => x.id === id).forEach(x => x.group = null)
    }

    $scope.addGroupField = function(id) {
      $scope.export.filter.filter(x => x.id === id).forEach(x => x.group.fields.push({field: '', value: {typ: 'string', value: ''}, function: ''}))
    }

    $scope.removeGroupField = function(id,groupField) {
      $scope.export.filter.filter(x => x.id === id).forEach(x => x.group.fields = x.group.fields.filter(x => x.field !== groupField.field))
    }



    onStart();
  }
]);
