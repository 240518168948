'use strict';

angular.module('tdl.controllers').controller('UserEditController',   ['$scope', 'TDLAuth', 'UserProfile', 'AlertsManager', 'Menu',
  function ($scope, TDLAuth, UserProfile, AlertsManager, Menu) {
    Menu.section = 'backend';
    $scope.menu = Menu;

    $scope.user = {};
    $scope.updateUser = updateUser;

    onStart();


    function onStart() {
      UserProfile.get({
        userId: TDLAuth.user._id
      }).$promise.then((user) => {
        user.notifications = user.notifications ? user.notifications : {app: true, email: true}
        $scope.user = user
      });
    }

    function updateUser() {
      var user = $scope.user;

      // Empty password is not valid
      if (typeof user.password !== 'undefined' && user.password === '') {
        delete user.password;
      }

      UserProfile.update(
        user,
        function (response) {
          AlertsManager.addAlert({
            type: 'success',
            message: 'User is saved correctly.'
          });

          var newLogin = {
            username: TDLAuth.user.username,
            password: user.password || TDLAuth.user.password
          };

          TDLAuth.login(newLogin);
        },
        function (error) {
          AlertsManager.addAlert({
            type: 'danger',
            message: 'I can\'t update the user...'
          });
        });
    }
  }
]);
