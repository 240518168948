'use strict';

angular.module('tdl.controllers').controller('AdminFormListController', ['$scope', 'Menu','TDLAuth','$http','Conf','NgTableParams','$location',
  function AdminFormListController($scope, Menu,TDLAuth,$http,Conf,NgTableParams,$location) {
    TDLAuth.checkAdmin();
    Menu.section = 'form';
    $scope.menu = Menu;

    $scope.tableParams = null;

    function loadList() {
      $http.get(Conf.api + '/form/count', {
        headers: TDLAuth.header()
      }).then(function (data, status, headers, config) {
        $scope.tableParams = new NgTableParams({
          sorting: {order: "asc"},
          count: 100
        }, {
          dataset: data.data
        });
      }, function (err) {
        console.log(err);
      });
    }

    loadList();

    $scope.editForm = function(id) {
      $location.path("/admin/formCreator/" + id);
    }

    $scope.deleteForm = function(id, name) {
      if(confirm('Are you sure to delete the form ' + name +'?')) {
        console.log('delete')
        $http.delete(Conf.api + '/form/'+id, {
          headers: TDLAuth.header()
        }).then(function (data, status, headers, config) {
          loadList();
        });
      }
    }

    $scope.addForm = function() {
      $location.path("/admin/formCreator/new");
    }



  }
]);
