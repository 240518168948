'use strict';

angular.module('tdl.controllers').controller('CalendarYearController', ['$scope', '$rootScope', '$http', 'Team', 'FormData', 'TDLAuth', 'Conf', 'Menu', 'StaticDataLoader',
  function ($scope, $rootScope, $http, Team, FormData, TDLAuth, Conf, Menu, StaticDataLoader) {
    Menu.section = 'planning';
    $scope.menu = Menu;

    $scope.print = {
      title: "",
      team: ""
    }

    $scope.formVisibles = {};
    $scope.forms = [];
    $scope.innerForms = [];

    $scope.print = print;

    var setListener = false;
    var formLoaded = false;

    $scope.changeRange = function(start,end) {
      $scope.myDateRange.startDate = start;
      $scope.myDateRange.endDate = end;
    }

    $scope.myDateRange = {
      startDate: moment({
        day: 1
      }).subtract(3, 'months'),
      endDate: moment({
        day: 1
      }).add(9, 'months')
    }

    $scope.calendarOpt = {
      period: {
        startDate: moment({
          month: 10,
          day: 1,
          year: 2014
        }),
        endDate: moment({
          month: 0,
          day: 1,
          year: 2016
        }),
      },
      eventSource: []
    };

    onStart();

    function onStart() {
      StaticDataLoader.form($scope.formVisibles, function (forms, innerForms) {
        $scope.forms = forms;
        $scope.innerForms = innerForms;
      });

      //Menu.load();

      $scope.$watch('myDateRange', function () {

        $scope.calendarOpt.period = {
          startDate: moment($scope.myDateRange.startDate).toDate(),
          endDate: moment($scope.myDateRange.endDate).toDate()
        }

      });

      $scope.$watch('calendarOpt.period', loadEvents);
    }

    $scope.changeRange = function(start,end) {
      $scope.myDateRange.startDate = start;
      $scope.myDateRange.endDate = end;
      $scope.calendarOpt.period = {
        startDate: moment($scope.myDateRange.startDate).toDate(),
        endDate: moment($scope.myDateRange.endDate).toDate()
      }
      loadEvents();
    }

    //Takes a list of events and returns the same list with no events crossing the months
    function splitLongEvents(events) {


      //filter the events over 2 or more months
      var longEvents = _.filter(events, function (event) {
        return moment(event.start).month() != moment(event.end).month() || moment(event.start).year() != moment(event.end).year()
      }).filter(e => e.start && e.end);


      //filter the events not over 2 or more months
      var shortEvents = _.filter(events, function (event) {
        return moment(event.start).month() == moment(event.end).month() && moment(event.start).year() == moment(event.end).year()
      });

      //split events so that they never be over 2 months
      for (var i = 0; i < longEvents.length; i++) {
        var event = angular.copy(longEvents[i]);

        longEvents[i] = splitEvent([],event);
      }

      return _.union(_.flatten(longEvents), shortEvents);

    }

    let recCount = 0

    //recursive function, for each event that crosses the end of the month generate two (or more) objects that not cross the months
    function splitEvent(acc,event) {

      var tail = angular.copy(event);
      var head = angular.copy(event);

      const start = moment(event.start)
      const nextMonth = ((start.month() + 1) % 12)
      const nextYear = nextMonth === 0 ? start.year() + 1 : start.year()
      const next = [nextYear,nextMonth,1,0,0,0,0]
      const endOfMonth = moment.utc(next)

      if(endOfMonth.isBefore(event.end)) {
        head.end = endOfMonth;
        tail.start = endOfMonth;

        acc.push(head)

        return splitEvent(acc, tail);

      } else {
        acc.push(event)
        return acc
      }

    }


    function loadEvents() {
      if (Menu.team && !formLoaded) {
        formLoaded = true;

        var query = getQuery($scope.calendarOpt.period.startDate.valueOf(), $scope.calendarOpt.period.endDate.valueOf() - 1);
        query.formsId = _.keys($scope.formVisibles).join(',');
        query['calendar'] = true;

        FormData.query(query).$promise.then(
          //success
          function (value) {
            var value = _.filter(value, function (fd) {
              return fd.form.properties.datespan;
            });


            var teams = _.groupBy(value, 'teamId');


            var events = _.map(teams, function (v, k) {
              var team = null;
              if (k != "noid") {

                team = _.find(Menu.teams, function (team) {
                  return team._id == k
                });
              } else {
                team = {
                  "_id": "noid",
                  "name": "All",
                  "athletesIds": [],
                  "athletes": []
                }
              }
              return createCalendarModel(v, team);
            });


            $scope.calendarOpt.eventSource = events;

            formLoaded = false;

          },
          //error
          function (error) {
            showError(error);
            console.log(error)
          }
        )


        setTeamListener();

      } else {
        setTeamListener();
      }
    }

    function setTeamListener() {
      if (!setListener) {
        $scope.$watch('[menu.team, menu.selectedTeams, menu.athlete, menu.selectedAthletes]', reloadWithNewTeam, true);
        setListener = true;
      }
    }


    function reloadWithNewTeam() {
      if (Menu.team) {
        loadEvents();
      }
    }

    function getQuery(start, end) {

      if (Menu.team && Menu.teamScope()) {
        var teamIds;

        if (Menu.team._id === 'all') {
          teamIds = Menu.selectedTeams.toString();
        } else {
          teamIds = Menu.team._id;
        }


        return {
          teamId: teamIds,
          start: start,
          end: end
        }
      } else if (Menu.athleteScope() && Menu.athlete) {
        var athletesIds;
        if (Menu.athlete._id === 'all') {
          athletesIds = Menu.selectedAthletes.toString();
        } else {
          athletesIds = Menu.athlete._id;
        }

        return {
          athleteId: athletesIds,
          start: start,
          end: end
        }
      } else {
        return {
          teamId: "noteamtofind"
        }
      }
    }

    function print() {
      var title = ($scope.print.title && $scope.print.title.length > 0) ? $scope.print.title : 'Title';
      var team = ($scope.print.team && $scope.print.team.length > 0) ? $scope.print.team : 'Team';


      var headers = angular.copy(TDLAuth.header())
      headers['Content-type'] = 'text/plain';

      $http({
        url: Conf.api + '/pdf/' + title + '/' + team,
        method: "POST",
        data: angular.element("tdl-calendar-year").html(),
        headers: headers,
        responseType: 'arraybuffer'
      }).then(function (data, status, headers, config) {

        var file = new Blob([ data.data ], {
            type : 'application/pdf'
        });
        //trick to download store a file having its URL
        var fileURL = URL.createObjectURL(file);
        var a         = document.createElement('a');
        a.href        = fileURL;
        a.target      = '_blank';
        a.download    = 'yourfilename.pdf';
        document.body.appendChild(a);
        a.click();
      },function (data, status, headers, config) {
        //upload failed
      });

    }


    function createCalendarModel(events, team) {
      var result = {
        team: team,
        events: splitLongEvents(events.filter(x => x.start > 0 && x.end > 0 && x.start < x.end))
      };

      return result;
    }

  }]);
