'use strict';

angular.module('tdl.controllers').controller('GarminCallbackController',   ['$scope', 'TDLAuth', 'AlertsManager', 'Menu', '$http', 'Conf','$routeParams',
  function ($scope, TDLAuth, AlertsManager, Menu, $http, Conf, $routeParams) {
    Menu.section = 'backend';
    $scope.menu = Menu;

    var token = $routeParams['token'];
    var verifier = $routeParams['verifier'];


    $scope.user = {};

    onStart();

    $scope.authUrl = "";


    $scope.loading = true;
    $scope.success = false;
    $scope.failure = false;

    function onStart() {
      $http.get(Conf.api + '/garmin/auth/callback/'+token+ "/" + verifier, {
        headers: TDLAuth.header()
      }).then(function (data, status, headers, config) {
        $scope.loading = false;
        $scope.success = true;
      }, function (err) {
        console.log(err);
        $scope.loading = false;
        $scope.failure = true;
      });
    }


  }
]);
