'use strict';

angular.module('tdl.directives').directive('tdlFormDataAlerts', ['FormDataHelper',
  function (FormDataHelper) {

    var directiveDefinitionObject = {
      restrict: 'E', // only activate on element attribute
      scope: {
        form: '=',
        formData: '=',
      },
      link: link,
      templateUrl: 'views/forms/formDataAlerts.html'
    }

    return directiveDefinitionObject;

    function link(scope, elem, attrs) {
      scope.alerts = [];

      onStart();

      function onStart() {
        scope.$watch('formData.alerts', function () {
          if (scope.form && scope.formData) {
            scope.alerts = FormDataHelper.fetchAlerts(scope.form, scope.formData);
          }
        });

      }
    }

  }
]);