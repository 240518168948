'use strict';
angular.module('tdl.services').factory('EventLoader', [

function () {

    var serviceObj = {
      reload: reload
    }

    var queue = [];

    var serviceOpts = {
      functions: []
    }

    return {
      load: function (opts) {
        serviceOpts = opts;
        return serviceObj;
      }
    }


    function reload() {

      queue.length = 0;

      for (var i = 0; i < serviceOpts.functions.length; i++) {
        enqueue(serviceOpts.functions[i]);
      }

      return dequeue();
    }

    var deQueueRunning = false;

    function _dequeue() {
      if (queue.length > 0) {
        var f = queue.shift();

        return f().then(function () {
          _dequeue();
        });

      } else {
        deQueueRunning = false;
        return new Promise(function(resolve, reject) {
          resolve();
        });
      }
    }

    function dequeue() {
      if (!deQueueRunning) {
        deQueueRunning = true;
        return _dequeue();
      } else {
        return new Promise(function(resolve, reject) {
          resolve();
        });
      }
    }

    function enqueue(f) {
      queue.push(f);
    }


}]);
