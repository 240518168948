'use strict';

angular.module('tdl.controllers').controller('ReportController', ['$scope', '$rootScope', 'Menu', 'Calendar', 'StaticDataLoader', 'CalendarHelper', 'FormDataHelper', 'Conf','TDLAuth','uiCalendarConfig','$http',
  function ReportController($scope, $rootScope, Menu, Calendar, StaticDataLoader, CalendarHelper, FormDataHelper, Conf,TDLAuth,uiCalendarConfig,$http) {
    Menu.section = "report";
    $scope.menu = Menu;



    var name = "Report";
    var allowedViews = "agendaWeek";
    var defaultView = "agendaWeek";

    $scope.teamSelector = {
      ids: []
    };

    

      $scope.formUi = FormDataHelper.ui;

    $scope.offset = "";
    $scope.newLeft = "";

    $scope.noEvent = []; ////VERY IMPORTANT, NEEDED FOR MINICALENDAR, or you will get tons of errors

    $scope.forms = [];
    $scope.formVisibles = {};
    $scope.innerForms = [];

    $scope.menu = Menu;

    $scope.toggleAll = (formId,e) => {
      if(e.ctrlKey || e.altKey) {
        if(Object.values($scope.formVisibles).filter(x => x).length > 0) { // select single
          for (const [key, value] of Object.entries($scope.formVisibles)) {
            $scope.formVisibles[key] = key == formId
          }
        } else {
          for (const [key, value] of Object.entries($scope.formVisibles)) {
            $scope.formVisibles[key] = true
          }
        }
      } else {
        $scope.formVisibles[formId] = e.target.checked
      }
      $scope.calendar.reloadWithNewTeam()
    }


    $scope.presences = {};

    $scope.$watch("menu.team",function() {
      if($scope.menu.team) {
        $scope.presences = {};
        var isAthlete = _.some($scope.menu.team.athletes, function (a) { //check if the current has an athlete in that team
          return a.userId == TDLAuth.user._id;
        })

        _.each($scope.menu.team.athletes, function (a) {
          if(isAthlete) {
            $scope.presences[a._id] = a.userId ==  TDLAuth.user._id;  //if is the case only the athlete releated to the current user is selected by default
          } else {
            $scope.presences[a._id] = true;
          }
        });
      }
    });



    $scope.getPresences = function() {
      return _.map(_.filter(_.pairs($scope.presences),function(atl) {return atl[1]}),function(atl) {return atl[0]});
    };

    $scope.weekTss = 0

    function loadWeekData() {
      $scope.weekTss = {}
      if($scope.menu.athlete) {
        const start = $scope.calendar.tableView.start.valueOf();
        const end = $scope.calendar.tableView.end.valueOf();
        $http.get(Conf.api + `/exports/627e2beb4a0100c9014c6aa4?end=${end}&start=${start}&athleteId=${$scope.menu.athlete._id}`, {
          headers: TDLAuth.header()
        }).then(function (response, status, headers, config) {
          $scope.weekTss = response.data

          $scope.testFitnessOptions = null;
          if(response.data.tss && response.data.tssWeeklyGoal ) {
            const doneExtra = Math.round((response.data.tss / response.data.tssWeeklyGoal * 100) *100) / 100;
            const done = Math.min(doneExtra, 100);
            const todo = 100 - done;
        
            $scope.weekTss.done = doneExtra;

            $scope.testFitnessOptions = {
              chart: {
                plotBackgroundColor: null,
                plotBorderWidth: 0,
                plotShadow: false,
                height: 200,
                backgroundColor: "rgba(0,0,0,0)"
              },
              exporting: {enabled: false},
              credits: {enabled: false},
              tooltip: {
                pointFormat: '<b>{point.percentage:.1f}%</b>'
              },
              title:{
                text:null
              },
              plotOptions: {
                pie: {
                  dataLabels: {
                    enabled: false,
                  },
                  center: ['50%', '50%'],
                  size: '100%',
                  colors: ['#7D84B2','#cccccc']
                }
              },
              series: [{
                name: 'TSS',
                type: 'pie',
                innerSize: '70%',
                data: [
                  done,
                  todo
                ]
              }]
            }
          }


        }, function (err) {
          console.err(err);
        });
      }
    }


    StaticDataLoader.formReport($scope.formVisibles, function (forms, innerForms) {
      $scope.forms = forms;
      $scope.innerForms = innerForms;
    });

    $scope.dropdown = false;

    $scope.calendar = Calendar.load({
      promises: {
        loadWeek: [
          Calendar.loadForms
        ]
      },
      callbacks: {
        loadWeek: function () {
          loadWeekData()
        }
      },
      tdlCalendar: function () {
        return uiCalendarConfig.calendars.reportMain
      },
      forms: function () {
        return $scope.forms;
      },
      formVisibles: function () {
        return $scope.formVisibles;
      }
    });

    $scope.toggleDropdown = function () {
      $scope.dropdown = !$scope.dropdown;
    }
    $scope.openFormDataPopup = $scope.calendar.openFormDataPopup(function() { $scope.dropdown = false });

    $scope.reloadWithNewTeam = $scope.calendar.reloadWithNewTeam;
    $scope.alertEventOnClick = $scope.calendar.dayClick;
    $scope.toggle = CalendarHelper.toggle


    /* calendar config object */
    $scope.uiConfig = {
      calendar: {
        header: {
          left: 'title',
          right: allowedViews + ' prev,today,next'
        },

        selectable: true,

        aspectRatio: ($(window).width() / $(window).height() + 0.1),
        firstDay: 1,
        allDayText: 'Events',

        defaultView: defaultView,

        timezone: 'UTC',
        weekNumberCalculation:  'ISO',

        weekNumbers: true,

        views: {
          month: { // name of view
            titleFormat: "MMMM YYYY",
            columnFormat: 'dddd',
            // other view-specific options here
          },
          week: {
            //titleFormat: 'MMMM yyyy',
            columnFormat: 'dddd, D'
          }
        },

        axisFormat: 'H:mm',
        timeFormat: 'H:mm',
        minTime: $rootScope.TDLconf.ui.minTime,
        maxTime: $rootScope.TDLconf.ui.maxTime,
        slotDuration: '00:30:00',
        snapDuration: '00:15:00',
        slotEventOverlap: false,

        editable: true,
        droppable: true, // this allows things to be dropped onto the calendar !!!
        defaultTimedEventDuration: '01:00:00',

        viewRender: $scope.calendar.viewRender,
        drop: $scope.calendar.drop($scope.getPresences),
        eventClick: $scope.calendar.eventClick,
        eventResize: $scope.calendar.eventResize,
        eventDrop: $scope.calendar.eventDrop,
        buttonText: $rootScope.TDLconf.labels.fullcalendar.buttonText,
        monthNames: $rootScope.TDLconf.labels.fullcalendar.monthNames,
        monthNamesShort: $rootScope.TDLconf.labels.fullcalendar.monthNamesShort,
        dayNames: $rootScope.TDLconf.labels.fullcalendar.dayNames,
        dayNamesShort: $rootScope.TDLconf.labels.fullcalendar.dayNamesShort
      }
    };



  }
]);
