'use strict';

angular.module('tdl.directives').directive('tdlAnalisisNumber', ['FormAnalisis',
  function (FormAnalisis) {

    var directiveDefinitionObject = {
      restrict: 'E', // only activate on element attribute
      scope: {
        data: '=',
        ui: '=',
        name: '=',
      },
      link: link,
      templateUrl: 'views/analisis/number.html'
    }

    return directiveDefinitionObject;

    function link(scope, elem, attrs, $compile) {




    }




  }
]);
