'use strict';



angular.module('tdl.services.storage').factory('Workflows', ['$resource', 'Conf', 'TDLAuth',
  function ($resource, Conf, TDLAuth) {
    return $resource(Conf.api + '/workflows/:userId', {}, {
      get: {
        method: 'GET',
        isArray: true,
        headers: TDLAuth.header(),
        cache: false
      }
    });
  }]);
