'use strict';

angular.module('tdl.directives').directive('tdlActivityReportHandler', ['AlertsManager', 'Menu', 'Stats',
  function (AlertsManager, Menu, Stats) {
    var throttleTime = 400;

    var directiveDefinitionObject = {
      restrict: 'E', // only activate on element attribute
      scope: {
        activity: '=',
        onUpdate: '&',
        onDelete: '&',
        onClose: '&'
      },
      link: link,
      templateUrl: 'views/activityReportHandler.html'
    }

    return directiveDefinitionObject;

    function link(scope, elem, attrs) {

      scope.menu = Menu;


      scope.tags = [];
      scope.popupActivity = null;
      scope.popupData = {
        type: "activity"
      };

      scope.activity.model.get({
        activityReportId: scope.activity._id
      }).$promise.then( //can't use $get here.. $watch get crazy...
        function (event) {

          setTags(event);
          scope.popupActivity = event;
        },
        function (error) {
          AlertsManager.addAlert({
            type: 'danger',
            message: error
          });
        }
      )

      scope.close = function () {
        scope.onClose()();
      }

      scope.delete = function () {
        var event = angular.copy(scope.popupActivity);
        scope.onDelete()(event);
      }

      scope.$watch('popupActivity', function (newNames, oldNames) {
        if (scope.popupActivity && scope.popupActivity.model && Menu.teamScope()) {
          var event = angular.copy(scope.popupActivity);
          scope.onUpdate()(event);

        }
      },true);


      scope.modifyTag = function () {
        scope.popupActivity.tags = _.map(scope.tags, function (v) {
          return v.text
        }).join(',')
      }

      scope.loadTags = function (query) {
        return Stats.tags({
          param: query
        }).$promise
      }


      function setTags(event) {
        scope.tags.length = 0;
        if (event.tags) {
          _.each(event.tags.split(","), function (element) {
            scope.tags.push({
              text: element.trim()
            });
          });
        }

      }

    }




  }
]);