/*global define*/
'use strict';

angular.module('tdl.services').factory('DataProcessor', ['$http', 'TDLAuth', 'Conf','StorageHelper',
  function ($http, TDLAuth,Conf,StorageHelper) {
    var objectFactory = {
      eval: _eval
    };


    return objectFactory;



    function _eval(data,dataProcessing) {

      var rawData = angular.copy(data);
      try {

        var dataProcessor = new Function("data","log", angular.copy(dataProcessing));

        var dataProcessed = dataProcessor(rawData,console.log);

        return dataProcessed
      } catch(err) {
        console.error(err)
        return rawData
      }

    }




  }
]);
