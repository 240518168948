'use strict';

const { isArray } = require("angular");

angular.module('tdl.directives').directive('tdlAnalisisChart', ['FormAnalisis',
  function (FormAnalisis) {

    var directiveDefinitionObject = {
      restrict: 'E', // only activate on element attribute
      scope: {
        data: '='
      },
      link: link,
      templateUrl: 'views/analisis/chart.html'
    }

    return directiveDefinitionObject;

    function link(scope, elem, attrs, $compile) {

      scope.singleChart = function() {
        if(!isArray(scope.data)) return true
        else return false
      }

      scope.multiChart = function() {
        if(isArray(scope.data)) return scope.data
        else return []
      }

    }

  }
]);
