'use strict';

/**
 * Services that persists and retrieves TODOs from localStorage.
 */

angular.module('tdl.services.storage').factory('FormPublic', ['$resource', 'Conf', 'TDLAuth', '$http', 'StorageHelper',
  function ($resource, Conf, TDLAuth, $http, StorageHelper) {


    var resource = prepareResource();



    return resource;

    function prepareResource() {
      return $resource(Conf.api + '/public/form/:formId', {
        formId: '@_id'
      }, {
        get: {
          method: 'GET',
          isArray: false
        }
      });
    }




  }]);
