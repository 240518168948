'use strict';
angular.module('tdl.controllers').controller('UsersController', ['$scope', '$rootScope', 'TDLAuth', 'User', 'UserAdmin', 'Team', 'AlertsManager', 'StaticDataLoader', 'Menu','$http','Conf','$window',
  function UsersController($scope, $rootScope, TDLAuth, User, UserAdmin, Team, AlertsManager, StaticDataLoader, Menu,$http,Conf,$window) {

    TDLAuth.checkAdmin();

    Menu.section = 'backend';
    $scope.menu = Menu;

    $scope.teams = [];
    $scope.users = [];
    $scope.currentUser = {};

    $scope.saveUser = saveUser;
    $scope.setCurrentUser = setCurrentUser;
    $scope.deleteUser = deleteUser;

    $scope.forms = [];



    onStart();




    function onStart() {
      $scope.teams = Team.query();
      reloadUserList();
      StaticDataLoader.simpleForm(function (forms) {
        $scope.forms = _.filter(forms, function (f) {
          return f.properties && (f.properties.showInCalendar || f.properties.showInReport);
        })

        $scope.allforms = forms;



      });
    }


    function reloadUserList() {
      $scope.users = User.query();
    }

    function saveUser() {
        var user = $.extend(true, {}, $scope.currentUser);

        UserAdmin.update({}, user,
          // success
          function () {
            AlertsManager.addAlert({
              type: 'success',
              message: 'User has been changed successfully!'
            });
            reloadUserList();
          },
          function (err) {
            console.log(err);
          });

    }

    function setCurrentUser(user) {
      $scope.currentUser = UserAdmin.get({
        userId: user._id
      }, function (user) {

      });



    }




    function deleteUser(user) {
      var r = confirm("Delete user: " +user.username + "?");
      if(r) {
        UserAdmin.remove({
          userId: user._id
        }, function () {
          AlertsManager.addAlert({
            type: 'success',
            message: 'User removed!'
          });
          reloadUserList();
          $scope.newCurrentUser();
        });
      }
    }



    $scope.renderFormName = function(id) {
      var form = _.find($scope.forms,function(f) { return f._id === id})
      if(form) {
        return form.name;
      } else {
        return "";
      }
    };




    $scope.actAs = function() {
      TDLAuth.actAs($scope.currentUser.username);
    }

  }


]);
