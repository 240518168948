'use strict';

angular.module('tdl.controllers').controller('FormAnalisisController', ['$scope',
  '$rootScope', 'Form', 'FormHelper', 'FormGroup', '$location', 'AlertsManager', 'User', 'Team', 'Athlete','FormAnalisisStructure','ExportsManager','Menu','FormAnalisis','StorageHelper','$routeParams','TDLAuth','ExportService','DataProcessor',
  function FormsController($scope, $rootScope, Form, FormHelper, FormGroup, $location, AlertsManager, User, Team, Athlete, FormAnalisisStructure,ExportsManager, Menu,FormAnalisis,StorageHelper,$routeParams,TDLAuth,ExportService,DataProcessor) {

    TDLAuth.checkAdmin();

    Menu.section = 'form';
    $scope.menu = Menu;







    var setListener = false,
      eventLoaded = false;



    $scope.preview = {
      dataProcessed: '',
      rawData: {},
      rawProcessedData: {},
      show: true,
      teamAthlete: 'team',
      team: null,
      athlete: null,
      menu: null,
      analisis: null,
      teamOnly: true,
      dateRange: ExportService.initialRange()
    };

    function loadPreview() {

      if($scope.preview.export) {

        if($scope.preview.teamAthlete === 'team') {
          $scope.preview.menu = {team: $scope.preview.team, teamScope: true};
        } else {
          $scope.preview.menu = {athlete: $scope.preview.athlete, teamScope: false, athleteScope:true};
        }

        ExportService.load($scope.preview.export._id,$scope.preview.dateRange,$scope.preview.menu).then(function (fa) {
          $scope.preview.data = JSON.stringify(fa.data, null, '\t')
          $scope.preview.rawData = fa.data;
          processData($scope.analisis.dataProcessing);

        })
      }

    }



    var processData = function (dataProcessing) {

        $scope.preview.rawProcessedData = DataProcessor.eval($scope.preview.rawData,dataProcessing)
        $scope.preview.dataProcessed = JSON.stringify($scope.preview.rawProcessedData, null, '\t');

    }

    var processDataThrotteled = _.debounce(processData, 300);


    $scope.$watch("analisis.dataProcessing",function() {
      processData($scope.analisis.dataProcessing);
      loadPreview()
    }, true);


    $scope.$watch("[preview.teamAthlete,preview.athlete,preview.team,preview.dateRange,preview.export]",loadPreview,true);





    $scope.list = {
      'forms': [],
      'formGroups': [],
      'teams': [],
      'users': [],
      'analisis': [],
      'exports': [],
      'analisisTypes': ["chart", "number", "fieldSelector", "compare"]
    };

    $scope.go = function ( path ) {
      $location.path( path );
    };

    $scope.analisis = {
      name: "analisis name",
      analisis: "chart",
      dataProcessing: ''
    }

    $scope.toggleDebug = function () {
      $scope.debug = !$scope.debug;
    }


    $scope.swap = function (theArray, indexA, indexB) {
      var temp = theArray[indexA];
      theArray[indexA] = theArray[indexB];
      theArray[indexB] = temp;
    }



    $scope.loadAnalisis = function (a) {
      $scope.analisis = FormAnalisisStructure.get({formAnalisisId: a._id}).$promise.then(function(fas) {
        loadAnalisisData(fas);
        loadPreview();
      })
    }

    if($routeParams['_id']) $scope.loadAnalisis({_id: $routeParams['_id']});

    function loadAnalisisData(analisis) {
      $scope.analisis = analisis;
    }

    $scope.newAnalisis = function() {
      $scope.analisis = {
        name: "analisis name",
        analisis: "chart",
        dataProcessing: ''
      };
    }

    $scope.deleteAnalisis = function() {


      var r = confirm("Delete analisis: " +$scope.analisis.name + "?");
      if(r) {
        FormAnalisisStructure.remove({formAnalisisId: $scope.analisis._id}, function () {
          AlertsManager.addAlert({
            type: 'success',
            message: 'Form group removed!'
          });
          $scope.analisis = {};
          $scope.list.analisis = FormAnalisisStructure.query();
        });
      }
    }


    $scope.noEmptyString = function(model) {
      if(model === "") model = null;
    };

    $scope.saveAnalisis = function () {
      var analisis = angular.copy($scope.analisis);




      if (analisis._id) {
        analisis.$update().then(function() {
          loadPreview();
        });
      } else {
        FormAnalisisStructure.post(analisis).$promise.then(function (v) {
          $scope.list.analisis.push(v);
          $location.path( "/admin/formAnalisis").search({_id:v._id});
        });
      }

    }

    function onStart() {
      //loadForms();
      $scope.list.analisis = FormAnalisisStructure.query();
      $scope.list.formGroups = FormGroup.query();
      $scope.list.forms = Form.query();
      $scope.list.teams = Team.query();
      $scope.list.athletes = Athlete.query();
      $scope.list.users = User.query();
      $scope.list.exports = ExportsManager.query();

    }



    onStart();
  }
]);
