'use strict';

angular.module('tdl.controllers').controller('TeamsController', ['$scope', '$location', '$http', 'Athlete', 'User', 'Team', 'Form', 'TDLAuth', 'Conf', 'Menu',
  function TeamsController($scope, $location, $http, Athlete, User, Team, Form, TDLAuth, Conf, Menu) {

    TDLAuth.checkAdmin();

    Menu.section = 'backend';
    $scope.menu = Menu;

    $scope.athletes = [];
    $scope.users = [];

    $scope.searchTeam = '';

    $scope.newTeam = getDefaultTeam();

    $scope.team = null;
    $scope.menu = Menu;
    $scope.user = {};
    $scope.enableCollector = false;

    $scope.multi = {
        athlete: [],
        usersRO: [],
        usersRW: []
    };

    $scope.apiRoot = Conf.api;


    $scope.loadTeam = loadTeam;
    $scope.addTeam = addTeam;
    $scope.removeTeam = removeTeam;
    $scope.saveTeam = saveTeam;
    $scope.toggleCollector = toggleCollector;

    onStart();

    function onStart() {
      $scope.athletes = Athlete.query();
      $scope.users = User.query();
      $scope.forms = Form.query();
    }

    function loadTeam(team) {
      $scope.team = team;
      $scope.enableCollector = false;
      if($scope.team.collectTeam) {
        $scope.enableCollector = true;
      }

      $scope.multi.athlete = _.filter($scope.athletes, function (atl) {
        return _.contains($scope.team.athletesIds, atl._id)
      });

      $http.get(Conf.api + '/team/authorizedRO/' + team._id, {
        headers: TDLAuth.header()
      }).
      then(function (response) {
        var usersIds = _.pluck(response.data, '_id');

        $scope.multi.usersRO = _.filter($scope.users, function (user) {
          return _.contains(usersIds, user._id)
        });

      },function (data, status, headers, config) {
      });

      $http.get(Conf.api + '/team/authorizedRW/' + team._id, {
        headers: TDLAuth.header()
      }).
      then(function (response) {
        var usersIds = _.pluck(response.data, '_id');

        $scope.multi.usersRW = _.filter($scope.users, function (user) {
          return _.contains(usersIds, user._id)
        });
      },function (data, status, headers, config) {
      });

    }

    function addTeam() {
      $scope.newTeam.athletesIds = _.pluck($scope.newTeam.athletes, '_id');
      delete $scope.newTeam.athletes;

      Team.post($scope.newTeam).$promise.then(
        function (team) {
          Menu.teams.unshift(team);
          $scope.newTeam = getDefaultTeam();
          $scope.loadTeam(team);
        },
        function (e) {
          console.log(e);
        }
      )
    }


    function removeTeam(id) {
      var confirmDelete = confirm('Are you sure? This operation is not reversible');
      if (confirmDelete) {
        Team.remove({
          teamId: id
        }).$promise.then(
          function (ok) {
            Menu.teams = _.reject(Menu.teams, function (e) {
              return e._id == id;
            })
          },
          function (e) {
            console.log(e);
          }
        )
      }

    }

    function saveTeam() {
      $scope.team.athletesIds = _.map($scope.multi.athlete, function (a) {
          return a._id;
      });


      var usersIdsRW  = _.map($scope.multi.usersRW, function (u) {
          return u._id
        }
      );

      var usersIdsRO  = _.map($scope.multi.usersRO, function (u) {
          return u._id
        }
      );

      $http.post(Conf.api + '/team/authorizeRO/' + $scope.team._id, usersIdsRO, {
        headers: TDLAuth.header()
      }).
      then(function (data, status, headers, config) {
        $scope.user.readonly = null;
      },function (data, status, headers, config) {
      });

      $http.post(Conf.api + '/team/authorizeRW/' + $scope.team._id, usersIdsRW, {
        headers: TDLAuth.header()
      }).
      then(function (data, status, headers, config) {
        $scope.user.readwrite = null;
      },function (data, status, headers, config) {
      });

      var obj = angular.copy($scope.team);

      Team.update(obj).$promise.then(
        function (v) {
          $scope.team = null
        },
        function (e) {
          console.log(e);
        }
      )
    }

    function getDefaultTeam() {
      return {
        name: '',
        athletesIds: []
      };
    }

    function toggleCollector() {
      if(!$scope.enableCollector && $scope.team.collectTeam) {
        delete $scope.team['collectTeam'];
      }
    }



  }
]);
