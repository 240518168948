'use strict';

/**
 * Services that persists and retrieves TODOs from localStorage.
 */

angular.module('tdl.services.storage').factory('Mail', ['$resource', 'Conf', 'TDLAuth',
 function ($resource, Conf, TDLAuth) {
    return $resource(Conf.api + '/mail/contact/:userId', {}, {
      send: {
        method: 'POST',
        params: {
          userId: ''
        },
        headers: TDLAuth.header()
      }
    });
}]);