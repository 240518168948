'use strict';

import _ from "underscore";

angular.module('tdl.services').factory('StaticDataLoader', ['$q', 'AlertsManager', 'Menu', 'Form', '$rootScope',

function ($q, AlertsManager, Menu, Form, $rootScope) {
    //StaticDataLoader:updated -> broadcast

    var serviceObj = {
      form: form,
      formReport: formReport,
      simpleForm: simpleForm,
      load: load,
      // Properties
      loaded: false
    }

    var objectCollection = {}

    function resetCollection() {
      objectCollection = {
        form: [],
        formReport: [],
        simpleForm: []
      }
    }

    return serviceObj;

    function load() {
      resetCollection();


      var promiseList = [_form(), _formReport(), _simpleForm(), Menu.load()];
      return $q.all(promiseList).then(notifyUpdate)
    }


    function form(formVisibles, callback) {
      var forms = objectCollection.form
      _.each(objectCollection.form, function (f) {
        _.each(f.forms, function (form) {
          formVisibles[form._id] = true
        });
      });
      var innerForms = _getInnerForms(objectCollection.form);

      callback(forms, innerForms);
    }

    function _form() {
      var deferred = $q.defer();

      Form.calendar().$promise.then(
        //success
        function (value) {
          objectCollection.form = value;
          deferred.resolve("ok");
        },
        //error
        function (error) {
          AlertsManager.showError(error);
          console.log("error Calendar loading");
          deferred.reject("nok")
        }
      );
      return deferred.promise;
    }

    function formReport(formVisibles, callback) {
      var forms = objectCollection.formReport;
      _.each(objectCollection.formReport, function (f) {
        _.each(f.forms, function (form) {
          formVisibles[form._id] = true
        });
      });
      var innerForms = _getInnerForms(objectCollection.formReport);

      callback(forms, innerForms);

    }

    function _formReport() {
      var deferred = $q.defer();
      Form.calendarReport().$promise.then(
        //success
        function (value) {
          objectCollection.formReport = value;
          deferred.resolve("ok");
        },
        //error
        function (error) {
          AlertsManager.showError(error);
          deferred.reject("nok")
        }
      )
      return deferred.promise;
    }

    function _getInnerForms(formGroups) {
      return _.sortBy(_.uniq(_.flatten(_.map(formGroups, function (formGroup) {
        return formGroup.forms;
      })), function(f) {
        return f._id
      }), function (f) {
        return f.order
      });
    }


    function simpleForm(callback) {
      callback(objectCollection.simpleForm);
    }

    function _simpleForm() {
      var deferred = $q.defer();
      Form.query().$promise.then(
        //success
        function (value) {
          objectCollection.simpleForm = value;
          deferred.resolve("ok");
        },
        //error
        function (error) {
          AlertsManager.showError(error);
          deferred.reject("nok")
        }
      )
      return deferred.promise;
    }

    function notifyUpdate(promises) {
      serviceObj.loaded = true;
      $rootScope.$broadcast('StaticDataLoader:updated');
      return promises;
    }



    //endregion

}]);
