'use strict';

import $ from "jquery";
import _ from "underscore";


angular.module('tdl.services').factory('Calendar', ['$rootScope', '$q', 'AlertsManager', 'Menu', 'ModalManager', 'FormData', 'FormHelper', 'TDLAuth', 'CalendarHelper', 'EventLoader', 'FormDataHelper',

function ($rootScope, $q, AlertsManager, Menu, ModalManager, FormData, FormHelper, TDLAuth, CalendarHelper, EventLoader, FormDataHelper) {

    var endDay = 24*60*60*1000;

    var calendarScope = $rootScope.$new();

    calendarScope.menu = Menu

    var serviceObj = {
      viewRender: viewRender,
      eventRender: eventRender,
      drop: drop,
      eventClick: eventClick,
      eventResize: eventResize,
      dayClick: dayClick,
      eventDrop: eventDrop,
      reloadWithNewTeam: reloadWithNewTeam,
      openFormDataPopup: openFormDataPopup,
      sidebarChange: sidebarChange,
      loadForms: loadForms,
      /// Properties
      week: false,
      month: false,
      eventSources: [{events: []}], //placeholder for directive, not used
      events: []
    };

    var serviceOptions = {
      forms: [],
      tdlCalendar: null,
      formVisibles: function () {},
      callbacks: {
        loadWeek: function () {},
        loadMonth: function () {},
        loadTeam: function() {}
      },
      promises: {
        loadWeek: [], //array of promises
        loadMonth: []
      }
    }


    var privateProperties = {
      currentDate: new Date(),
      loader: null,
      loaderOpts: {
        functions: []
      }
    }

    return {
      loadForms: loadForms,
      load: function (options) {


        _resetSources();


        serviceOptions.forms = options.forms;
        serviceOptions.tdlCalendar = options.tdlCalendar;
        serviceOptions.formVisibles = options.formVisibles;

        if (options.callbacks.loadWeek)
          serviceOptions.callbacks.loadWeek = options.callbacks.loadWeek

        if (options.callbacks.loadMonth)
          serviceOptions.callbacks.loadMonth = options.callbacks.loadMonth

        if (options.callbacks.loadTeam)
          serviceOptions.callbacks.loadTeam = options.callbacks.loadTeam

        if (options.promises.loadWeek) {
          serviceOptions.promises.loadWeek = options.promises.loadWeek
          serviceOptions.promises.loadWeek.unshift(_resetSources)
        }


        if (options.promises.loadMonth) {
          serviceOptions.promises.loadMonth = options.promises.loadMonth
          serviceOptions.promises.loadMonth.unshift(_resetSources)
        }



        if (options.promises.loadMonth) {
          privateProperties.loaderOpts = {
            functions: serviceOptions.promises.loadMonth
          };
        } else {
          privateProperties.loaderOpts = {
            functions: serviceOptions.promises.loadWeek
          };
        }



        privateProperties.loader = EventLoader.load(privateProperties.loaderOpts);
        if($rootScope.TDLconf.ui.planning.showMiniCalendar) {
          CalendarHelper.loadMiniCalendar(serviceOptions.formVisibles());
        }


        return serviceObj;
      }
    };


    function eventRender(event, element) {

      //if (serviceObj.tableView.name == "month" && !event.allDay) return false;



    }

    function buildList() {
      if(serviceObj.tableView.name  == "basicDay") {



        var fcEvents = serviceObj.events;

        var events = _.sortBy(fcEvents.filter(function (d) { return moment(serviceObj.tableView.start).isBefore(d.start) && moment(serviceObj.tableView.end).isAfter(d.start) }),function(e) {return e.start})

        const basicDayView = '.fc-basicDay-view'

        $(basicDayView).html('');

        var titleFields = _.max(events.map(function(e) { return e.titleTokens ? e.titleTokens.length : 0 }));

        var table = document.createElement("table");
        table.className = "day-list-table";
        events.forEach(function(event) {
          var row = document.createElement("tr");
          row.style = "border-bottom: 1px solid #aaa;"
          row.addEventListener('click', function(e) { serviceObj.eventClick(event,e)})

          var color = document.createElement("td");
          color.style = "width: 50px; background-color: " + event.color + "; border-bottom: 1px solid "+event.color+";"
          color.innerHTML = "&nbsp;";
          row.appendChild(color);

          var start = document.createElement("td")
          start.innerHTML = moment(event.start).format('h:mm');
          start.style = "font-weight: bold; width: 100px"
          row.appendChild(start);

          for(var i = 0; i < titleFields; i++) {
            var title = document.createElement("td");
            title.style = "width: 250px"
            if(event.titleTokens && event.titleTokens[i]) {
              title.innerHTML = event.titleTokens[i];
            }
            row.appendChild(title);
          }

          var form = document.createElement("td")
          form.innerHTML = event.form.name;
          row.appendChild(form);

          table.appendChild(row)
        })

        $(basicDayView)[0].appendChild(table);
      }
    }

    function viewRender(view, element) {


      if (view) {



        if (serviceObj.tableView && view.name == serviceObj.tableView.name) {
          privateProperties.currentDate = view.start;
        }

        //serviceOptions.tdlCalendar().fullCalendar('gotoDate', privateProperties.currentDate);

        serviceObj.tableView = view;

        if (view.name == "agendaWeek" || view.name=="agendaDay") {

          serviceObj.week = true;
          serviceObj.month = false;

          privateProperties.loaderOpts.functions = serviceOptions.promises.loadWeek;
          privateProperties.loader.reload();

          serviceOptions.callbacks.loadWeek(true);

          //view.setHeight($(window).height() - $("#main-panel").offset())

        } else if (view.name == "month") {

          serviceObj.week = false;
          serviceObj.month = true;

          privateProperties.loaderOpts.functions = serviceOptions.promises.loadMonth;
          privateProperties.loader.reload();

          serviceOptions.callbacks.loadMonth(true);

        } else if(view.name == "basicDay") {



          serviceObj.week = true;
          serviceObj.month = false;

          privateProperties.loaderOpts.functions = serviceOptions.promises.loadWeek;
          privateProperties.loader.reload();

          serviceOptions.callbacks.loadWeek();


          buildList()


        }

        _selectDayUI();

      }


    }

    function dayClick(date, allDay, jsEvent, view) {
      serviceOptions.tdlCalendar().fullCalendar('gotoDate', date);
      privateProperties.currentDate = date;
      _selectDayUI();
    }

    function eventDrop(event, dayDelta, revertFunc) {

      if ($rootScope.keyCode == 18) {
        _copyEvent(event, revertFunc)
      } else {
        _moveEvent(event, revertFunc)
      }
    }

    function _copyEvent(event, revertFunc) {

      var newEvent = angular.copy(event);


      revertFunc();

      var start = newEvent.start
      var end = newEvent.end

      console.log(newEvent)
      newEvent.$get().then( //load the last version before moving, needed for slim events too.
        function (value) {

          console.log(value)

          value.start = start;
          value.end = end;

          delete value._id;

          value.$post().then(
            //success
            function (result) {
              serviceObj.events.push(result);
              serviceOptions.tdlCalendar().fullCalendar('renderEvent',result);
            },
            //error
            function (error) {
              AlertsManager.showError(error);
              console.log(error);
            }
          );

        },
        //error
        function (error) {
          console.log(error);
          AlertsManager.showError("Non autorizzato");
          revertFunc();
        }
      );

    }

    function _moveEvent(event, revertFunc) {



      var start = event.start
      var end = event.end

      event.$get().then( //load the last version before moving, needed for slim events too.
        function (value) {

          value.start = start;
          value.end = end;

          value.$update().then(
            //success
            function (value2) {},
            //error
            function (error) {
              console.log(error);
              AlertsManager.showError("Non autorizzato");
              revertFunc();
            }
          );

        },
        //error
        function (error) {
          console.log(error);
          AlertsManager.showError("Non autorizzato");
          revertFunc();
        }
      );


    }

    function drop(defaultAttendees) {
      return function(date, jsev, ui, resourceId) {

        switch ($(this).data("model")) {
          case "formData":
            _saveForm(date, $(this), defaultAttendees)
            break;
          default:
            console.log("no model found:" + $(el).data("model"))
        }
      }
    }


    function _saveForm(date, el,defaultAttendees) {


      var form = _.find(_.flatten(_.map(serviceOptions.forms(), function (f) {
        return f.forms
      })), function (form) {
        return $(el).data("formid") == form._id
      })

      var defaultOffset = endDay;
      if (form.properties.timespan) {
        defaultOffset = 60 * 60 * 1000;
      }

      var startDate = moment(date);
      if (form.properties.datespan && !form.properties.timespan) {
        startDate = moment(date).startOf('day') //since the calendar is in local time transform to local
      }

      var defaultValues = {
        start: startDate,
        end: new Date(startDate.valueOf() + defaultOffset)
      };


      if(form.properties.withAttendees && !form.properties.withInvitations) {
        defaultValues['attendees'] = defaultAttendees();
      }

      if(form.properties.withInvitations) {
        defaultValues['attendees'] = []
        defaultValues['invited'] = defaultAttendees();
      }

      if (FormHelper.hasRequiredFields(form)) {


        ModalManager.newFormData($(el).data("formid"), {
          'live': true,
          'inCalendar': true,
          'defaultValues': defaultValues,
          'onCreate': function (formData) {
            serviceObj.events.push(value);
            serviceOptions.tdlCalendar().fullCalendar('renderEvent',value);
          }
        });
      } else {

        var permissions = angular.copy(form.defaultPermissions);
        permissions['read']['teams'] = Menu.team && Menu.team._id ? [Menu.team._id] : [];
        permissions['read']['users'] = [TDLAuth.user._id];
        permissions['write']['users'] = [TDLAuth.user._id];
        permissions['own']['users'] = [TDLAuth.user._id];

        var dataToSend = angular.extend({},defaultValues,{
          formId: $(el).data("formid"),
          submitted: (new Date()).getTime(),
          changes: [],
          userId: TDLAuth.user._id,
          title: form.name,
          data: {},
          permissions: permissions
        });


        if (Menu.teamScope()) {
          dataToSend['teamId'] = Menu.team._id;
        } else {
          dataToSend['athleteId'] = Menu.athlete._id;
        }


        FormData.post({}, dataToSend,
          function (value) {
            value.model = FormData;
            serviceObj.events.push(value);
            serviceOptions.tdlCalendar().fullCalendar('renderEvent',value);
          },
          function () {
            AlertsManager.addAlert({
              type: 'danger',
              message: 'There an issue with submission.'
            });
          }
        );
      }



    }


    function eventResize(event, dayDelta, minuteDelta, revertFunc) {
      if (Menu.teamScope()) {
        event.$updateCalendar().then(
          //success
          function (value) {},
          //error
          function (error) {
            AlertsManager.showError("Non autorizzato");
            revertFunc();
          }
        );
      }
    }

    function eventClick(event, jsEvent, view) {

      function _onUpdate(formData,reload) {



        if (event.formId) { //id a formData object
          event.color = formData.color;
        }


        if (formData.form.properties.draft && formData.data && (formData.data[formData.form.properties.draft.id] === formData.form.properties.draft.value || !formData.data[formData.form.properties.draft.id])) {
          event.className.push('striped');
        } else {
          event.className = event.className.filter(x => x !== 'striped')
        }


        formData.title = FormDataHelper.labelFromForm(formData, formData.form);
        event.title = formData.title;
        formData.titleTokens = FormDataHelper.labelTokenFromForm(formData, formData.form);

        event.start = formData.start;
        event.end = formData.end;
        serviceOptions.tdlCalendar().fullCalendar('updateEvent',event)
        buildList()

        if(reload) {
          console.log("delete many, reload")
          //Reload data
          loadForms()
        }

      }

      function _onDelete(formData,reload) {


        var id = "";

        if ($.type(formData) === "string") { //forms delete in formhandelr and here passes only the deleted id string
          id = formData;
        } else if (formData) {
          id = formData._id;
        }

        serviceOptions.tdlCalendar().fullCalendar('removeEvents', function (event) {
          return event._id == id;
        });

        if(reload) {
          console.log("delete many, reload")
          //Reload data
          loadForms()
        }

        ModalManager.closeModal();
      }

      if (event._id != "busy") {

        var modalOptions = {
          "onUpdate": _onUpdate,
          "onDelete": _onDelete
        }

        ModalManager.editFormData(event, modalOptions);


      }
    }

    function _selectDayUI() {
      $(".selected-day").removeClass("selected-day")
      $("td[data-date='" + moment(privateProperties.currentDate).format("YYYY-MM-DD") + "']").addClass("selected-day")
    }


    function _resetSources() {
      var deferred = $q.defer();

      serviceObj.events.length = 0;
      if(serviceOptions.tdlCalendar && serviceOptions.tdlCalendar())
        serviceOptions.tdlCalendar().fullCalendar('removeEvents');
      deferred.resolve("ok");

      return deferred.promise;

    }


    function loadForms() {
      var deferred = $q.defer();
      if ((Menu.team || Menu.athlete) && serviceObj.tableView) {

        var forms = [];
        _.each(serviceOptions.formVisibles(), function (v, k) {
          if (v) forms.push(k)
        });

        var query = CalendarHelper.getQuery(moment(serviceObj.tableView.start).valueOf(), moment(serviceObj.tableView.end).valueOf())

        if (Menu.section == 'report') {
          query['reportCalendar'] = true;
        } else {
          query['calendar'] = true;
        }


        query['formsId'] = forms.join(",")

        FormData.query(query).$promise.then(
          //success
          function (value) {

            serviceOptions.tdlCalendar().fullCalendar('removeEvents');


            serviceObj.events = value;
            serviceOptions.tdlCalendar().fullCalendar('renderEvents',serviceObj.events);
            buildList();

            deferred.resolve("ok");
          },
          //error
          function (error) {
            AlertsManager.showError(error);
            console.log(error)
            deferred.reject("nok");
          }
        )
      } else {
        deferred.resolve("ok");
      }

      Menu.onChange = function () {
        if(Menu.section === 'planning' || Menu.section === 'report') {
          reloadWithNewTeam();
        }
      }

      return deferred.promise;

    }






    function reloadWithNewTeam() {
      if ((Menu.team || Menu.athlete) && serviceOptions.tdlCalendar) {

        privateProperties.loader.reload().then(function() {
          if($rootScope.TDLconf.ui.planning.showMiniCalendar) {
            CalendarHelper.loadMiniCalendar(serviceOptions.formVisibles());
          }


          serviceOptions.callbacks.loadTeam();
          serviceOptions.callbacks.loadWeek();

        });




      }
    }

   function openFormDataPopup(closeDropdown) {
      return function(form) {
       closeDropdown();

       var popupData = {
         "onCreate": onCreateFormData(true),
         "onUpdate": onUpdateFormData(form)
       }

       if(form.properties.timespan) {
         var start = moment.utc($rootScope.currentDate).startOf('day').add(moment().hour(),'hours')
         popupData['defaultValues'] = {
           start: start,
           end: moment.utc(start).add(1, 'hours')
         }
       } else {
         popupData['defaultValues'] = {
           start: moment.utc($rootScope.currentDate).startOf('day'),
           end: moment.utc($rootScope.currentDate).startOf('day').add(1,'day')
         }
       }

       ModalManager.newFormData(form._id,popupData);
     }
   }





  function onUpdateFormData(form) {
    return function (formData) {
      formData.title = FormDataHelper.labelFromForm(formData, form);
    }
  }


  function onCreateFormData(closePopup) {
    return function (formData) {

      if (formData.formId) { //id a formData object
        serviceObj.events.push(formData);
        serviceOptions.tdlCalendar().fullCalendar('renderEvent',formData);
        if (closePopup) {
          buildList();
          ModalManager.closeModal();
        }
      }
    }
  }

  function sidebarChange() {
    setTimeout( function() {
      serviceOptions.tdlCalendar().fullCalendar('render');
    }, 500);
  }






}]);
