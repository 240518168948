'use strict';

angular.module('tdl.controllers').controller('HrController',   ['$scope', 'TDLAuth', 'AlertsManager', 'Menu', '$http', 'Conf','Athlete',
  function ($scope, TDLAuth, AlertsManager, Menu, $http, Conf,Athlete) {
    Menu.section = 'backend';
    $scope.menu = Menu;

    $scope.user = {};

    onStart();

    $scope.polarAuthUrl = "";
    $scope.garminAuthUrl = "";
    $scope.polar = null;
    $scope.garmin = false;
    $scope.params = {};

    $scope.enableZones = TDLAuth.user.athlete ? true : false;

    if($scope.enableZones) {
      const hasZones = TDLAuth.user.athlete.addon && TDLAuth.user.athlete.addon.sports
      $scope.params.ias = TDLAuth.user.athlete.addon?.ias || null;
      $scope.params.tssWeeklyGoal = TDLAuth.user.athlete.addon?.tssWeeklyGoal || null;
      const zones = (sport) => {
        if (TDLAuth.user.athlete.addon && TDLAuth.user.athlete.addon.sports) {
          const existing = TDLAuth.user.athlete.addon.sports.find(x => x.sport === sport)
          if(existing) return existing.zones
        }
        return [0, 0, 0, 0, 0]
      }
      $scope.zones = [
        {
          title: "Bike",
          sport: "bike",
          zones: zones("bike")
        },
        {
          title: "Rowing",
          sport: "rowing",
          zones: zones("rowing")
        },
        {
          title: "Running",
          sport: "running",
          zones: zones("running")
        },
        {
          title: "Sailing",
          sport: "sailing",
          zones: zones("sailing")
        }
      ]
    }


    $scope.saveZones = function() {

      const updatedAthlete = angular.extend({},TDLAuth.user.athlete,{addon: {sports: $scope.zones, ias: $scope.params.ias, tssWeeklyGoal: $scope.params.tssWeeklyGoal}})


      Athlete.update(updatedAthlete,
        // success
        function () {
          AlertsManager.addAlert({
            type: 'success',
            message: 'Athlete modified successfully!'
          });
          TDLAuth.reloadUser()
        },
        function (err) {
          console.log(err);
        });

    }


    function onStart() {
      $http.get(Conf.api + '/polar/auth/redirect', {
        headers: TDLAuth.header()
      }).then(function (data, status, headers, config) {
        $scope.polarAuthUrl = data.data;
      }, function (err) {
        console.err(err);
      });

      $http.get(Conf.api + '/polar/user', {
        headers: TDLAuth.header()
      }).then(function (data, status, headers, config) {
        $scope.polar = data.data;
      }, function (err) {
        console.err(err);
      });

      $http.get(Conf.api + '/garmin/auth/redirect', {
        headers: TDLAuth.header()
      }).then(function (data, status, headers, config) {
        $scope.garminAuthUrl = data.data;
      }, function (err) {
        console.err(err);
      });

      $http.get(Conf.api + '/garmin/connected', {
        headers: TDLAuth.header()
      }).then(function (data, status, headers, config) {
        $scope.garmin = data.data;
      }, function (err) {
        console.err(err);
      });

    }


  }
]);
