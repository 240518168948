'use strict';

angular.module('tdl.controllers').controller('DashboardController', ['$scope', '$rootScope', 'AlertsManager', 'Menu', 'Conf', 'FormData','TDLAuth','$q','User','Workflows','$location',
  function DashboardController($scope, $rootScope, AlertsManager, Menu, Conf, FormData,TDLAuth,$q,User,Workflows,$location) {
    Menu.section = "dashboard";
    $scope.menu = Menu;


    var dashboardConf = $rootScope.TDLconf.ui.dashboard;


    $scope.notices = [];

    $scope.workflows = [];


    function loadDashboard() {

      var collectedNotices = [];

      FormData.query(getQuery()).$promise.then(
        function (notices) {
          $scope.notices = [];
          var transformedNotices = _.map(notices, function (notice) {
            return {
                title: notice[dashboardConf.noticesTitleField],
                body: notice[dashboardConf.noticesBodyField],
                author: notice.user,
                date: notice.submitted,
                team: notice.team
              }
            });
          $scope.notices = _.sortBy(transformedNotices,function(n) { return -n.date });
        });



      $scope.openLast = function(f) {
        $location.path("/formDataView/"+f.id+"/"+f.lastId);
      };

      $scope.newForm = function(f) {
        $location.path("/formData/"+f);
      };



      $q.all(_.map(Menu.athletesInvolved(), function(a) {
        return Workflows.get({userId: a._id}).$promise
      })).then( function (workflows) {
        $scope.workflows = _.sortBy(_.flatten(workflows),'athlete');
      })




    }

    $scope.$watch('[menu.team, menu.selectedTeams, menu.athlete, menu.selectedAthletes]', loadDashboard, true);



    //TODO copy-paste from tdlFromDataHandler, can be refactored
    function fetchUser(userId) {
      if (userId === TDLAuth.user._id) {
        return $q.when(TDLAuth.user);
      } else {
        return User.get({
          userId: userId
        }).$promise;
      }
    }


    function getQuery() {


        return {
          teamId: _.map(Menu.teams, function(t) { return t._id}).join(),
          formsId: dashboardConf.noticesFormId
        }

    }






}]);
