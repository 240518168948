'use strict';

import TurndownService  from 'turndown';
import 'trumbowyg/dist/trumbowyg'
import 'trumbowyg/dist/plugins/cleanpaste/trumbowyg.cleanpaste'
import icons from "trumbowyg/dist/ui/icons.svg"
import { Remarkable } from 'remarkable';


angular.module('tdl.directives').directive('toastEditor', [
  function () {

    return {
      restrict: 'E',
      template: '<div><div></div></div>',
      scope: {
        ngModel: '=',
        ngChange: '&'
      },
      link: function (scope, element) {

        var md = new Remarkable();
        var turndownService = new TurndownService();

        $.trumbowyg.svgPath = icons;
        element[0].children[0].innerHTML = md.render(scope.ngModel)
        $(element[0].children[0])
          .trumbowyg({
            minimalLinks: true,
            btns: [['bold', 'italic', 'formatting'], ['link'],['unorderedList', 'orderedList']],
            resetCss: true,
            autogrow: true,
            height: 150
          })
          .on('tbwchange', function(){
            let html = $(this).trumbowyg('html')
            scope.ngModel = turndownService.turndown(html);
            scope.$apply();
            if(scope.ngChange) scope.ngChange();
          });



      }
    };
  }
]);
