'use strict';

angular.module('tdl.directives').directive('tdlContactMail', ['$rootScope', 'AlertsManager', 'Mail',
  function ($rootScope, AlertsManager, Mail) {

    var directiveDefinitionObject = {
      restrict: 'E', // only activate on element attribute
      scope: {
        formDataId: '@',
        formId: '@',
        userId: '@',
        closePopup: '&',
      },
      link: link,
      templateUrl: 'views/contactMail.html'
    }

    return directiveDefinitionObject;

    function link(scope, elem, attrs) {

      scope.sent = false;

      scope.TDLconf = $rootScope.TDLconf;

      scope.send = function () {

        Mail.send({
            userId: scope.userId,
            formId: scope.formId,
            formDataId: scope.formDataId
          },
          scope.mail
        ).$promise.then(
          function (ok) {
            scope.sent = true;
          },
          function (nok) {
            AlertsManager.addAlert({
              type: 'danger',
              msg: 'Mail not sent.'
            });
          }
        )
      }

    }
  }
]);