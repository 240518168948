'use strict';

angular.module('tdl.filters')
  .filter('formatDate', function () {
    return function (input) {
      return moment(input).format('DD.MM.YYYY');
    };
  });

angular.module('tdl.filters')
  .filter('formatDateEnd', function () {
    return function (input) {
      return moment(input).subtract(1, "days").format('DD.MM.YYYY');
    };
  });

angular.module('tdl.filters')
  .filter('formatDateTime', function () {
    return function (input) {
      return moment(input).format('DD.MM.YYYY HH:mm');
    };
  });
