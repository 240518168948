'use strict';

import streamSaver from 'streamsaver'

angular.module('tdl.directives').directive('tdlFile', ['Upload','Conf','TDLAuth',
  function (Upload,Conf,TDLAuth) {

    var directiveDefinitionObject = {
      restrict: 'E', // only activate on element attribute
      scope: {
        ngModel: '=',
        ngChange: '&',
        readOnly: '='
      },
      link: link,
      templateUrl: 'views/directives/tdlFile.html'
    }

    return directiveDefinitionObject;

    function link(scope, elem, attrs, $compile) {



      scope.readOnlyMode = scope.readOnly || false;

      if(scope.ngModel && scope.ngModel.length > 0) {
        scope.file = Conf.api + '/media/' + scope.ngModel;
        scope.filename = scope.file.replace(/^.*[\\\/]/, '')
      }



      scope.download = function() {
        const fileStream = streamSaver.createWriteStream(scope.filename)
        fetch(scope.file,{headers: TDLAuth.header()}).then(res => {
          const readableStream = res.body

          // more optimized
          if (window.WritableStream && readableStream.pipeTo) {
            return readableStream.pipeTo(fileStream)
              .then(() => console.log('done writing'))
          }

          window.writer = fileStream.getWriter()

          const reader = res.body.getReader()
          const pump = () => reader.read()
            .then(res => res.done
              ? writer.close()
              : writer.write(res.value).then(pump))

          pump()
        })
      }

      scope.import = function(file) {
        Upload.upload({
          url: Conf.api + '/media',
          data: {file: file},
          headers: TDLAuth.header()
        }).then(function (resp) {
          var response = resp.data
          if(resp.data.errors) {
            response = _.map(resp.data.errors,function (error) {
              return "Error: " + error.errorMessage + "\nin line: " + error.errorLine + "\n\n";
            }).join("\n");
          }
          scope.ngModel = response;
          if(scope.ngChange) {
            scope.ngChange();
          }
          scope.file = Conf.api + '/media/' + response;
        }, function (resp) {
          scope.message = 'Error status: ' + resp.status;
        }, function (evt) {
          var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
          scope.message = 'progress: ' + progressPercentage + '% ' + evt.config.data.file.name;
        });
      }

    }
  }]);
