// Used to fix bug https://github.com/angular-ui/bootstrap/issues/2659
angular.module('tdl.directives').directive('datepickerPopup', function () {
  return {
    restrict: 'EA',
    require: 'ngModel',
    link: function (scope, element, attr, controller) {
      //remove the default formatter from the input directive to prevent conflict
      controller.$formatters.shift();
    }
  }
})