'use strict';

import _ from "underscore";


/**
 * Services that persists and retrieves FormData from backend.
 */

angular.module('tdl.services.storage').factory('FormData', ['$resource', 'Conf', 'TDLAuth', '$http', 'Form', 'StorageHelper', 'StaticDataLoader', '$rootScope', 'FormDataHelper',
  function ($resource, Conf, TDLAuth, $http, Form, StorageHelper, StaticDataLoader, $rootScope, FormDataHelper) {
    var forms = null,
      transResponseStack = StorageHelper.addTransResponse(transResponse),
      transRequestStack = StorageHelper.addTransRequest(transRequest);

    onStart();

    var resource = $resource(Conf.api + '/formData/:modifier/:formDataId', {
      formDataId: '@_id'
    }, {
      get: {
        method: 'GET',
        isArray: false,
        headers: TDLAuth.header(),
        transformResponse: transResponseStack
      },
      query: {
        url: Conf.api + '/formData/post',
        method: 'POST',
        isArray: true,
        headers: TDLAuth.header(),
        transformResponse: transResponseStack
      },
      list: {
        method: 'GET',
        params: {
          modifier: 'list'
        },
        isArray: true,
        headers: TDLAuth.header(),
        transformResponse: transResponseStack
      },
      post: {
        method: 'POST',
        params: {
          onlyCheck: null
        },
        headers: TDLAuth.header(),
        transformResponse: transResponseStack,
        transformRequest: transRequestStack
      },
      update: {
        method: 'PUT',
        params: {
          onlyCheck: null
        },
        headers: TDLAuth.header(),
        transformResponse: transResponseStack,
        transformRequest: transRequestStack
      },
      updateCalendar: {
        method: 'PUT',
        params: {
          modifier: 'calendar'
        },
        headers: TDLAuth.header(),
        transformRequest: transRequestStack,
        transformResponse: transResponseStack
      },
      remove: {
        method: 'DELETE',
        headers: TDLAuth.header()
      }
    });


    return resource;

    function onStart() {
      $rootScope.$on('StaticDataLoader:updated', fetchForms);
      fetchForms();
    }



    function transResponse(data) {

      if (data.start !== undefined) {
        data.start = moment.utc(data.start) //StorageHelper.millisToDate(data.start);

        if(data.start.hours() == 0) {
          data.allDay = true
          if(data.start.seconds() == 59) { // fix for all day
            moment.utc(data.start.valueOf() + 1)
          }
        }
      }

      if (data.end !== undefined) {
        data.end = moment.utc(data.end)//StorageHelper.millisToDate(data.end);
      }

      var form = _.find(forms, function (f) {
        return f._id == data.formId;
      });


      if (form !== undefined) {
        data.color = form.properties.color;
        data.borderColor = form.properties.color;
        data.textColor = form.properties.textColor;
        data.form = form;
        data.className = form.properties.customClass;

        if(form.properties.datespan && !form.properties.timespan) {
          data.allDay = true
        }


        if (form.properties.draft && data.data && (data.data[form.properties.draft.id] === form.properties.draft.value || !data.data[form.properties.draft.id])) {
          data.className = data.className + " striped";
        }

        if (!data.title) {
          data.title = FormDataHelper.labelFromForm(data, form);
        }

        if (form.properties.timespan) {
          data.allDay = false;
        }

      }

      data.model = resource;

      return data;
    }


    function transRequest(eventObject) {
      eventObject = angular.copy(eventObject);

      eventObject.submitted = new Date();
      eventObject.changes = [];
      eventObject.userId = TDLAuth.user._id;

      if (eventObject.start !== undefined) {
        eventObject.start = eventObject.start.valueOf() //StorageHelper.dateToMillis(eventObject.start);
      }

      if (eventObject.end !== undefined) {
        eventObject.end = eventObject.end.valueOf() //StorageHelper.dateToMillis(eventObject.end);
      };

      if (eventObject.submitted !== undefined) {
        eventObject.submitted = eventObject.submitted.valueOf() //StorageHelper.dateToMillis(eventObject.submitted);
      };

      delete eventObject._id;
      delete eventObject.source;
      delete eventObject.form;

      return eventObject;
    }

    function fetchForms() {
      StaticDataLoader.simpleForm(function (f) {
        forms = f;
      });
    }
  }
]);

angular.module('tdl.services.storage').factory('FormReport', ['$resource', 'Conf', 'TDLAuth',
  function ($resource, Conf, TDLAuth) {
    return $resource(Conf.api + '/formData/list/:formId', {
      formDataId: '@_id'
    }, {
      get: {
        method: 'GET',
        isArray: false,
        headers: TDLAuth.header()
      }
    });
  }
]);

angular.module('tdl.services.storage').factory('FormMerged', ['$resource', 'Conf', 'TDLAuth',
  function ($resource, Conf, TDLAuth) {
    return $resource(Conf.api + '/formMerged/:formDataId', {
      formDataId: '@_id'
    }, {
      get: {
        method: 'GET',
        isArray: false,
        headers: TDLAuth.header()
      }
    });
  }
]);

angular.module('tdl.services.storage').factory('FormPersonalInfo', ['$resource', 'Conf', 'TDLAuth',
  function ($resource, Conf, TDLAuth) {
    return $resource(Conf.api + '/personalInformationForm', {
      formDataId: '@_id'
    }, {
      get: {
        method: 'GET',
        isArray: false,
        headers: TDLAuth.header()
      }
    });
  }
]);
