'use strict';

/**
 * Services that persists and retrieves TODOs from localStorage.
 */

angular.module('tdl.services.storage').factory('Feedback', ['$resource', 'Conf', 'TDLAuth',
 function ($resource, Conf, TDLAuth) {
    return $resource(Conf.api + '/feedback', {}, {
      post: {
        method: 'POST',
        headers: TDLAuth.header()
      }
    });
}]);