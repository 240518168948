'use strict';

/**
 * Services that persists and retrieves FormData from backend.
 */

angular.module('tdl.services.storage').factory('FormDataPublic', ['$resource', 'Conf', 'TDLAuth', '$http', 'Form', 'StorageHelper', 'StaticDataLoader', '$rootScope', 'FormDataHelper',
  function ($resource, Conf, TDLAuth, $http, Form, StorageHelper, StaticDataLoader, $rootScope, FormDataHelper) {
    var forms = null,
      transResponseStack = StorageHelper.addTransResponse(transResponse),
      transRequestStack = StorageHelper.addTransRequest(transRequest);


    var resource = $resource(Conf.api + '/public/formData/:formDataId', {
      formDataId: '@_id'
    }, {
      post: {
        method: 'POST',
        params: {
          onlyCheck: null
        },
        transformResponse: transResponseStack,
        transformRequest: transRequestStack
      }
    });


    return resource;


    function transRequest(eventObject) {
      eventObject = angular.copy(eventObject);

      eventObject.submitted = new Date();
      eventObject.changes = [];
      eventObject.userId = "guest";

      if (eventObject.start !== undefined) {
        eventObject.start = StorageHelper.dateToMillis(eventObject.start);
      }

      if (eventObject.end !== undefined) {
        eventObject.end = StorageHelper.dateToMillis(eventObject.end);
      };

      if (eventObject.submitted !== undefined) {
        eventObject.submitted = StorageHelper.dateToMillis(eventObject.submitted);
      };

      delete eventObject._id;
      delete eventObject.source;
      delete eventObject.form;

      return eventObject;
    }


    function transResponse(data) {

      if (data.start !== undefined) {
        data.start = StorageHelper.millisToDate(data.start);
      }

      if (data.end !== undefined) {
        data.end = StorageHelper.millisToDate(data.end);
      }

      var form = _.find(forms, function (f) {
        return f._id == data.formId;
      });


      if (form !== undefined) {
        data.color = form.properties.color;
        data.textColor = form.properties.textColor;
        data.form = form;
        data.className = form.properties.customClass;


        if (data.draft) {
          data.className = data.className + " striped";
        }

        if (form.properties.draft && data.data && data.data[form.properties.draft.id] == form.properties.draft.value) {
          data.className = data.className + " striped";
        }

        if (!data.title) {
          data.title = FormDataHelper.labelFromForm(data, form);
        }

        if (form.properties.timespan) {
          data.allDay = false;
        }

      }

      data.model = resource;

      return data;
    }


  }
]);

