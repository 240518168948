/*global define*/
'use strict';

angular.module('tdl.services.helpers').factory('CalendarHelper', ['AlertsManager', 'FormData', 'Menu', function (AlertsManager, FormData, Menu) {
  return {
    toggle: toggle,
    loadMiniCalendar: loadMiniCalendar,
    getQuery: getQuery
  };

  function toggle(className) {
    $("#" + className).toggleClass("open")
    if ($("#" + className).parent().find(".hidden").length > 0) {

      $.each($("#" + className).parent().find(".hidden"), function () {
        $(this).addClass("visible")
        $(this).removeClass("hidden")
      })

    } else {

      $.each($("#" + className).parent().find(".draggable.visible"), function () {
        $(this).removeClass("visible")
        $(this).addClass("hidden")
      })

    }
  }

  function getQuery(start, end) {

    if (Menu.teamScope() && Menu.team) {
      var teamIds;

      if (Menu.team._id === 'all') {
        teamIds = Menu.selectedTeams.toString();
      } else {
        teamIds = Menu.team._id;
      }

      return {
        teamId: teamIds,
        start: start,
        end: end
      }
    } else if (Menu.athleteScope() && Menu.athlete) {
      var athletesIds;
      if (Menu.athlete._id === 'all') {
        athletesIds = Menu.selectedAthletes.toString();
      } else {
        athletesIds = Menu.athlete._id;
      }

      return {
        athleteId: athletesIds,
        athleteOnly: Menu.athleteOnly,
        start: start,
        end: end
      }
    } else {
      return {
        teamId: "noteamtofind"
      }
    }
  }


  function _loadMiniCalendar(value) {

    $(".mini-calendars").each(function (k, el) {
      $(el).fullCalendar('render')
    });

    var yearEvents = [];

    angular.forEach(value, function (value, key) {
      yearEvents.push(value);
    })

    $("#main-sidebar td[data-date]").removeClass("event").removeClass("event-training").removeClass("event-peak")

    _.each(yearEvents, function (event, k) {
      var day = moment(event.start)
      var className = ""

      if ($.isArray(event.className)) {
        className = event.className[0]
      } else {
        className = event.className
      }

      do {
        $("#main-sidebar td[data-date=" + day.format("YYYY-MM-DD") + "]").addClass(className)
        if (event.color) {
          $("#main-sidebar td[data-date=" + day.format("YYYY-MM-DD") + "]").css("backgroundColor", event.color)
        }
        day = day.add('days', 1)
      } while (day.isBefore(event.end) || day.isSame(event.end));

    })

  }

  /**
   * Load Minicalendars
   * @param {Object} formVisibles {[formId]: [visible: Boolean]}
   */
  function loadMiniCalendar(formVisibles) {
    var query = getQuery(moment().add(-2, 'month').valueOf(), moment().add(9, 'month').valueOf());
    query['calendar'] = true;

    query.formsId = _.reduce(formVisibles, function (memo, visible, formId) {
      if (visible) {
        memo.push(formId);
      }
      return memo;
    }, []).join(',');

    FormData.query(query).$promise.then(
      function (forms) {
        _loadMiniCalendar(forms);
      },
      AlertsManager.showError
    );

    $("#main-sidebar .fc-other-month").css("background-color", "rgba(0, 0, 0, 0)").css("color", "rgba(0, 0, 0, 0)");

  }


}]);
