'use strict';

angular.module('tdl.controllers').controller('FormListController', ['$scope', '$http', '$compile', 'FormData', '$location', 'Team', 'Form', 'Menu', 'NgTableParams', 'Conf','TDLAuth',
  function FormsController($scope, $http, $compile, FormData, $location, Team, Form, Menu,NgTableParams, Conf, TDLAuth) {
    Menu.section = "form";
    $scope.menu = Menu;

    $scope.formTypes = [];
    $scope.reportTeam = "";



    $scope.viewFormData = viewFormData;
    $scope.editFormData = editFormData;
    $scope.addFormData = addFormData;
    $scope.report = report;
    $scope.importData = importData;
    $scope.deleteFormData = deleteFormData;
    $scope.selectForm = selectForm;
    $scope.exportData = exportData;
    $scope.titles = {};

    $scope.tableParams = null;

    onStart();

    function onStart() {


      if (Menu.team || Menu.athlete) {
        loadList();
      }

      $scope.$watch('[menu.team, menu.selectedTeams, menu.athlete, menu.selectedAthletes, menu.athleteOnly]', function () {
        if (Menu.team || Menu.athlete) {
          loadList();
        }
      }, true);
    }

    function viewFormData(formData) {
      $location.path("/formDataView/" + formData.formId + '/' + formData._id);
    }

    function editFormData(form) {
      $location.path("/formData/" + form.formId + "/" + form._id);
    }

    function addFormData(formId) {
      $location.path("/formData/" + formId);
    }

    function deleteFormData(form) {
      var confirmDelete = confirm("Are you sure? This operation is not reversible");
      if (confirmDelete) {
        FormData.remove({
          formDataId: form._id
        });

        $scope.formTypes[form.formId].splice($scope.formTypes[form.formId].indexOf(form), 1)

      }
    }

    function report(formId) {
      $location.path("/formReport/" + formId);
    }

    function importData(formId) {
      $location.path("/import/" + formId);
    }

    function exportData(formId) {
      $http({
        url: Conf.api + "/form/export/" + formId + "/" + Menu.selectedTeams.join(",") + Menu.selectedAthletes.join(",") + (Menu.team ? Menu.team._id : "") + (Menu.athelte ? Menu.athlete._id : "") ,
        method: "GET",
        headers: TDLAuth.header(),
        responseType: 'arraybuffer'
      }).then(function (data, status, headers, config) {

        var file = new Blob([ data.data ], {
          type : 'text/csv'
        });
        //trick to download store a file having its URL
        var fileURL = URL.createObjectURL(file);
        var a         = document.createElement('a');
        a.href        = fileURL;
        a.target      = '_blank';
        a.download    = $scope.menu.form.name + '.csv';
        document.body.appendChild(a);
        a.click();
      },function (data, status, headers, config) {
        //upload failed
      });
    }


    function selectForm(form) {
      Menu.form = form;
      loadFormData();
    }

    function loadList() {
      $scope.formTypes.length = 0;

      Form.query().$promise.then(
        function (formList) {
          console.log(formList)
          var onlyShowInForm = _.filter(formList,function(f) { return f.properties.showInForm});
          $scope.formList = _.sortBy(onlyShowInForm, function (f) {
            return f.order
          });

          if (!Menu.form && $scope.formList) {
            Menu.form = $scope.formList[0];
          }

          loadFormData();
        },
        function (e) {
          console.log(e);
        }
      )


    }

    function loadFormData() {
      FormData.query(getQuery()).$promise.then(
        function (forms) {
          var sortedForms = _.sortBy(forms, 'submitted');
          $scope.formTypes = _.groupBy(sortedForms, 'formId');
          $scope.tableParams = new NgTableParams({
            sorting: { submitted: "desc" }
          }, {
            dataset: $scope.formTypes[$scope.menu.form._id]
          });

          forms.forEach(fd => {
            title(fd)
          });

        },
        function (e) {
          console.log(e);
        }
      );
    }

    function getQuery() {

      if (Menu.teamScope()) {
        var teamIds;

        if (Menu.multipleMode) {
          teamIds = Menu.selectedTeams.toString();
        } else {
          teamIds = Menu.team._id;
        }

        return {
          teamId: teamIds,
          formsId: Menu.form._id
        }
      } else if (Menu.athleteScope()) {
        var athletesIds;
        if (Menu.multipleMode) {
          athletesIds = Menu.selectedAthletes.toString();
        } else {
          athletesIds = Menu.athlete._id;
        }

        return {
          athleteId: athletesIds,
          formsId: Menu.form._id
        }
      }
    }

    async function title(fd) {
      if(fd.form.properties.labels) {
        $scope.titles[fd._id] = (await Promise.all(fd.form.properties.labels.map(async x => {
          const val = fd[x]
          if(val == undefined || val == null) return []

          const field = fd.form.fields.filter(f => f.id === x)

          switch(field.map(x => x.typ)[0]) {
            case "Date": return [moment(val).format('DD.MM.YYYY')]
            case "Radio": return val.value ? [val.value] : []
            case "Select": {
              if(field[0].externalOptions) {
                const ext = field[0].externalOptions
                const opts = await (await fetch(ext.uri)).json()
                const result =  opts.filter(o => o[ext.fieldId] == val.value).map(o => o[ext.fieldValue])
                return result;
              } else {
                return val.value ? [val.value] : []
              }
            }
            default: return [val]
          }
          
        }))).flat().join(" - ")
        $scope.$apply()
      } else {
        $scope.titles[fd._id] = f.name
      }
    }

  }
]);
