/*global define*/
'use strict';

angular.module('tdl.directives').directive('tdlInputPermissions', function () {
  return {
    restrict: 'E',
    scope: {
      permissions: '='
    },
    templateUrl: 'views/elements/inputPermissions.html'
  };
});