/*global define*/
'use strict';
/**
 * Directive that places focus on the element it is applied to when the expression it binds to evaluates to true.
 */

angular.module('tdl.directives').directive('tdlForm', ['$compile', '$http', 'Athlete', 'Stats', '$filter', 'StorageHelper','$rootScope','FormData','Menu','Conf','TDLAuth',
  function ($compile, $http, Athlete, Stats, $filter, StorageHelper,$rootScope,FormData,Menu,Conf,TDLAuth) {

    var linker = function (scope, element, attrs) {

      scope.postData = scope.formData.data;

      scope.menu = Menu;

      scope.TDLconf = $rootScope.TDLconf;

      scope.showField = true;

      scope.$watch('postData',function() {

        if (scope.field.showIf) {
          scope.showField = _.some(scope.field.showIf, function (k) {
            return scope.postData[k.fieldId] == k.fieldValue;
          })
        }
      },true);

      scope.test = function() {
        console.log("on change");
      }


      scope.safeName = function(input) {
         if (input) {
           var result = input.toString().toLowerCase()
             .replace(/\s+/g, 'jjsp') // Replace spaces with -
             .replace(/[^\w\-]+/g, 'jjw') // Remove all non-word chars
             .replace(/-/g, 'jjd') // Trim - from start of text
             .replace(/0/g, 'jjz')
             .replace(/1/g, 'jjo')
             .replace(/2/g, 'jjtw')
             .replace(/3/g, 'jjth')
             .replace(/4/g, 'jjfo')
             .replace(/5/g, 'jjfi')
             .replace(/6/g, 'jjsi')
             .replace(/7/g, 'jjse')
             .replace(/8/g, 'jje')
             .replace(/9/g, 'jjn');
           return result;
         } else {
           return 'undefined';
         }
      }

      scope.getFormName = function(fieldId) {
        return scope.safeName(fieldId) + 'Form';
      }


      scope.getForm = function(fieldId) {
        var formName = scope.getFormName(fieldId);
        return scope[formName];
      }

      scope.cols = function(c) {
        switch (c.typ) {
          case "Long Text":
          case "Tags":
          case "Section":
          case "Image":
          case "Multiselect":
          case "Date":
            return "col-xs-12";
          default:
            return "col-xs-4";
        }

      }

      scope.labelCols = function(c) {
        if(c >= 0) {
          if(c == 0) {
            return "hide";
          } else {
            return "col-xs-"+c;
          }
        } else {
          return "col-xs-4";
        }
      }

       scope.inputCols = function(c) {
        if(c >= 0) {
          var x = 12-c;
          return "col-xs-"+x;
        } else {
          return "col-xs-8";
        }
      }

      scope.messagesCols = function(c) {
        if(c >= 0) {
          var x = 12-c;
          return "col-xs-"+x + " col-xs-offset-"+c;
        } else {
          return "col-xs-8 col-xs-offset-4";
        }
      }

      if(scope.field.externalOptions) {
        $http.get(scope.field.externalOptions.uri).then(
          function (ext) {
            scope.field.options = ext.data.map(x => {
              return {
                "id": x[scope.field.externalOptions.fieldId],
                "name": x[scope.field.externalOptions.fieldValue],
              }
            })
          },
          function (e) {
            console.log(e)
          }
        )
      }



      // Additional logic for complex data


      switch (scope.field.typ) {
      case 'external':

        scope.$watch('[menu.team, menu.selectedTeams, menu.athlete, menu.selectedAthletes, menu.athleteOnly]',function() {
          var get = {};

          //missing filter / order / limit, creating a dedicated API?

          if (Menu.teamScope()) {
            if (Menu.multipleMode) {
              get['teamId'] = Menu.selectedTeams.join(',');
            } else {
              get['teamId'] = Menu.team._id;
            }
          } else {
            get['athleteOnly'] = false;
            if (Menu.multipleMode) {
              get['athleteId'] = Menu.selectedAthletes.join(',');
            } else {
              get['athleteId'] = Menu.athlete._id;
            }

          }


          $http({
            method: 'GET',
            url: Conf.api+'/form/external/'+scope.form._id+'/'+scope.formData._id+'/'+scope.field.id+"/",
            params: get,
            headers: TDLAuth.header()
          }).then(
            function (form) {
              scope.externalData = form.data;
            },
            function (e) {
              console.log(e)
            }
          );
        },true);
        break;
      case 'Athlete':
        Athlete.query().$promise.then(
          function (athletes) {
            var filteredAthletes = athletes
            if(Menu.teamScope() && !Menu.multipleMode) {
              filteredAthletes = _.filter(athletes, function(atl) {
                  return Menu.team.athletesIds.includes(atl._id);
                });

            }
            scope.athletes = _.sortBy(filteredAthletes, function (a) {
              return a.lastName
            });
          },
          function (e) {
            console.log(e)
          }
        );
        break;


      case 'Date':

        scope.temp = {
          date: moment().startOf('day')
        }

        if(scope.postData[scope.field.id]) {
          scope.temp.date = moment(scope.postData[scope.field.id])
        } else {
          scope.postData[scope.field.id] = moment(scope.temp.date).valueOf();
        }

        scope.updateDate = function() {
          scope.postData[scope.field.id] = moment(scope.temp.date).valueOf();
        }


        break;

        case 'Checkbox':
          if(scope.postData[scope.field.id] && Array.isArray(scope.postData[scope.field.id])) {
            var array = scope.postData[scope.field.id];
            scope.postData[scope.field.id] = {}
            _.each(array, function(a) {
              scope.postData[scope.field.id][a.id] = true;
            });
          }
          break;


        case 'Evaluation table':

          if(scope.postData[scope.field.id] && scope.postData[scope.field.id]['valuesById']) {
            scope.postData[scope.field.id] = scope.postData[scope.field.id]['valuesById'];
            _.each(scope.postData[scope.field.id], function(v,k) {
              scope.postData[scope.field.id][k] = "" + v;
            })
          }
          break;

        case 'Select':
          if(scope.postData[scope.field.id] && scope.postData[scope.field.id]['id']) {
            scope.postData[scope.field.id] = scope.postData[scope.field.id]['id'];

          }
          break;

        case 'Tags':
        scope.tags = [];

        scope.loadTags = function (query) {
          return []
        }

        scope.fromTagsToData = function () {
          scope.postData[scope.field.id] = _.uniq(_.map(scope.tags, function (v) {
            return v
          })).join(',')
        }

        scope.addTag = function(tag) {
          if(scope.postData[scope.field.id] && scope.postData[scope.field.id].length > 0) {
            scope.postData[scope.field.id] = scope.postData[scope.field.id] + "," + tag;
          } else {
            scope.postData[scope.field.id] = tag;
          }
          scope.fromDataToTags(scope.postData[scope.field.id]);
          scope.fromTagsToData();
          scope.onChange();
        }

        scope.fromDataToTags = function (tags) {
          scope.tags.length = 0;
          if (tags) {
            _.each(_.uniq(tags.split(",")), function (element) {
              scope.tags.push(element.trim());
            });
          }
        }

        var unbindWatch = scope.$watch('postData[field.id]', function (newValue) {
          // Only the first time update it
          if (scope.postData && scope.postData[scope.field.id] && scope.tags.length <= 0) {
            unbindWatch();
            scope.fromDataToTags(newValue);
          }
        });

        break;


      }


      scope.getContentUrl = function () {
        return 'views/forms/' + $filter('slugify')(scope.field.typ) + '.html';
      }

    }

    return {
      restrict: "E",
      link: linker,
      template: '<div ng-show="showField" ng-class="cols(field)" ng-include="getContentUrl()"></div>',
      scope: {
        onChange: '&',
        form: '=',
        field: '=content',
        formData: '='
      }
    }
  }
]);
