/*global define*/
'use strict';

import $ from "jquery";

/**
 * Directive that places focus on the element it is applied to when the expression it binds to evaluates to true.
 */

angular.module('tdl.directives').directive('tdlDateRange',['$timeout', function ($timeout) {
  return {
    template: '<input id="test" class="range" type="daterange" readonly>',
    scope: {
      ngModel: '=',
      control: '=',
      onChange: '='
    },
    restrict: 'E',
    link: function (scope, elm, attrs) {

      $(elm[0].children[0]).daterangepicker({
        startDate: angular.copy(scope.ngModel.startDate),
        endDate: angular.copy(scope.ngModel.endDate),
        locale: {
          format: 'D. MMMM YYYY'
        }
      }, function(start, end, label) {
        scope.ngModel.startDate = moment.utc([start.year(),start.month(),start.date(),0,0,0,0])
        scope.ngModel.endDate = moment.utc([end.year(),end.month(),end.date(),0,0,0,0])
        if(scope.onChange) {
          scope.onChange(scope.ngModel.startDate,scope.ngModel.endDate);
        }
        scope.$apply();
      });

      scope.control = {
        open: function() {
          $(elm[0].children[0]).click();
        }
      }
    }
  };
}]);
