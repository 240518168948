'use strict';

angular.module('tdl.filters')
  .filter('slugify', function () {

    return function (input) {
      if (input) {
        var result = input.toString().toLowerCase()
          .replace(/\s+/g, '-') // Replace spaces with -
          .replace(/[^\w\-]+/g, '') // Remove all non-word chars
          .replace(/\-\-+/g, '-') // Replace multiple - with single -
          .replace(/^-+/, '') // Trim - from start of text
          .replace(/-+$/, ''); // Trim - from end of text
        return result;
      } else {
        return 'undefined';
      }
    };
  })