'use strict';

angular.module('tdl.controllers').controller('WelcomeController', ['$scope','$rootScope', '$location','$routeParams', 'TDLAuth', '$cookieStore', 'AlertsManager', 'Conf', 'Notice', '$http', 'Menu','StaticDataLoader',
  function WelcomeController($scope,$rootScope, $location,$routeParams, TDLAuth, $cookieStore, AlertsManager, Conf, Notice, $http, Menu,StaticDataLoader) {
    $scope.notices = [];
    $scope.recover = false;
    $scope.recoverPassword = recoverPassword;
    $scope.recoveruser = '';
    $scope.login = login;

    Menu.section = "welcome";


    Array.prototype.getRandomVal = function(){
      return this[Math.floor(Math.random()*this.length)];
    };

    if($rootScope.TDLconf.ui.homeBackgrounds) {
      var bg = $rootScope.TDLconf.ui.homeBackgrounds.getRandomVal();
      Menu.style = {"background-image": "url(" + bg + ") "};
    }
    $rootScope.menu = Menu;


    onStart();



    $scope.showSignup = $routeParams['teamIds'] && $routeParams['formIds'];

    $scope.signup = function() {
      //console.log("/signup?teamIds="+$routeParams['teamIds']+"&formIds="+$routeParams['formIds'])
      $location.path("/signup");
    }

    function onStart() {
      Menu.unload();
      Notice.last().$promise.then(
        function (notices) {
          $scope.notices = notices;
        },
        function (error) {
          console.log(error)
        }
      );
    }

    function recoverPassword() {
      $http.get(Conf.api + '/user/recoverPassword/' + $scope.recoveruser).then(function (data, status, headers, config) {
        // this callback will be called asynchronously
        // when the response is available
        AlertsManager.addAlert({
          type: 'info',
          message: 'Check your mail to reset the password'
        });
        $scope.recover = false;
      },function (data, status, headers, config) {
        // called asynchronously if an error occurs
        // or server returns response with an error status.
        AlertsManager.addAlert({
          type: 'danger',
          message: 'User not found'
        });
      });
    }

    function login() {
      TDLAuth.login($scope.user).then(
        function (user) {

              var path = $cookieStore.get('path');

              //Menu.load();
              // BAD HACK, realoding the page to workaround the $resource limitations !!! when fixing remeber to fix in userConfirm.js as well
              var dest = "";
              if (path && path !== "/") {

                dest = path;
                $cookieStore.remove('path');
              } else {
                if($rootScope.TDLconf.ui.sections.dashboard) {
                  dest = "/dashboard";
                } else {
                  dest = "/planning";
                }
              }
              window.location = window.location.origin  + window.location.pathname + "?r=" +(+new Date()) + "#" + dest;
        },
        function () {
          AlertsManager.addAlert({
            type: 'danger',
            message: 'Username or password is incorrect, please try again.'
          });
          console.log("not authorized");
        }
      );
    };

  }
 ]);
